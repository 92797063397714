import React from 'react';
import { Grid, Typography, CardContent, TextField } from '@mui/material'

import ForgotPassword from '../LoginPage/ForgotPassword';

export default function AccountInformation({user}) {
    return (
        <CardContent>
            <Grid container direction="row" justifyContent="space-between" spacing={1}>
                <Grid item xs={12}>
                    <Typography component="div" variant="subtitle2" color="text.secondary" gutterBottom>Username</Typography>
                    <TextField 
                        style={{backgroundColor:"white", borderRadius:5}}  
                        disabled
                        value={user.username} 
                        size="small" 
                        fullWidth 
                        id="username" 
                        name="username"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ForgotPassword/>
                </Grid>
            </Grid>
        </CardContent>
)}
    