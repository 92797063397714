import React, { useContext, useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { themes } from '../../../core/theme'
import { statusFormatter, verificationStatusFormatter } from '../../../core/functions'
import { RaiContext } from '../../../contexts/RaiContext';
import { CustomToolbar } from '../../../components/customToolbar';
import * as fn from '../../../core/functions'

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        '& .super-app-theme--HighlightNewReport': {
            bgcolor: themes.colors.secondary,
        },
        '& .super-app-theme--Emergency': {
            color: themes.colors.tertiary,
        },
        '& .super-app-theme--HighlightNewEmergency': {
            bgcolor: themes.colors.secondary,
            color: themes.colors.tertiary,
        }
    },
}

export default function SummaryTable() {
    const { 
        reportsShow, 
        newReports, 
    } = useContext(RaiContext);
    const [rowsAll, setRowsAll] = useState(null)

    const rows = [];
    const columns = [
        {
            field: 'reference_no',
            headerName: 'ID No.',
            width: 100,
        },

        {
            field: 'category',
            headerName: 'Category',
            width: 250,
        },
        {
            field: 'issue',
            headerName: 'Issue',
            sortable: true,
            width: 160,
        },
        {
            field: 'date',
            headerName: 'Date',
            width: 120,
            valueGetter: (params) =>
                params.row.date?
                params.row.date[0]?`${fn.djangoToJsDate(params.row.date)[0]}`:'':''

        },
        {
            field: 'time',
            headerName: 'Time',
            width: 100,
            valueGetter: (params) =>
                params.row.date?
                params.row.date[1]?`${fn.djangoToJsDate(params.row.date)[1]}`:'':''
        },
        {
            field: 'latitude',
            headerName: 'Lat',
            sortable: true,
            width: 80,
            valueGetter: (params) =>
            params.row.coords?
            params.row.coords[1]?`${params.row.coords[1]}`:"":'',
        },
        {
            field: 'longitude',
            headerName: 'Lon',
            sortable: true,
            width: 100,
            valueGetter: (params) =>
                params.row.coords?
                params.row.coords[0]?`${params.row.coords[0]}`:"":'',
        },
        {
            field: 'district',
            headerName: 'District',
            sortable: true,
            width: 200,
            renderCell: (params) => {
                return params.row.district | params.row.district != "null" ? params.row.district:"";
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            sortable: true,
            width: 160,
            renderCell: (params) => {
                return statusFormatter(params.row.status);
            },
        },
        {
            field: 'is_verified',
            headerName: 'Verification Status  ',
            sortable: true,
            width: 160,
            renderCell: (params) => {
                return verificationStatusFormatter(params.row.is_verified);
            },
        },
        {
            field: 'desc',
            headerName: 'Description',
            sortable: true,
            width: 250,
            renderCell: (params) => {
                return params.row.desc | params.row.desc != "null" ?params.row.desc:"";
            },
        },
        {
            field: 'remarks',
            headerName: 'Remarks',
            sortable: true,
            width: 250,
        },
    ];

    useEffect(()=> {
        if (reportsShow) {
          
          if (reportsShow.count>0) {
            // repeating din every 3 secs, as a consequence
            var rows = (reportsShow.values)
    
            setRowsAll(rows.map((x)=>{ 
              x['id'] = x.id || x.id
              return x 
            }));
          } else {
            setRowsAll([])
          }
        } 
    }, [reportsShow])
   
  return (
    <Grid container sx={styles.root}>
        <DataGrid
            rows={rowsAll?rowsAll:rows}
            loading={!rowsAll}
            columns={columns}
            rowsPerPageOptions={[20, 50, 100]}
            checkboxSelection={false}
            // source: https://stackoverflow.com/questions/66424752/get-row-item-on-checkbox-selection-in-react-mui-datagrid
            onSelectionModelChange={(ids) => {
                const selectedID2 = new Set(ids);
                const selectedRow2 = rowsAll.filter((row) =>
                    selectedID2.has(row.id),
                );
            }}
            components={{
                Toolbar: CustomToolbar,
            }}
            getRowClassName={(params) => {
                if (newReports.count>0) {
                    if (newReports.values.some((val) => val.id === params.row.id)) {
                        return `super-app-theme--HighlightNewReport`
                    }
                }
            }}
        />
    </Grid>
  );
}
