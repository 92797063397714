import React, { useContext } from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { themes } from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';
import { djangoToJsDate } from '../../../core/functions';
import EmergencyIcon from '../../../assets/RaiSos/Emergency_icon.png'
import * as Constants from '../../../core'
import './index.css'
import axios from 'axios';

const styles = {
  cardContainer: {
    width: '100%', 
    height: '50vh',
    '&:hover': {
        backgroundColor: themes.colors.hoverColor,
        cursor: 'pointer'
    },
    backgroundColor: "red"
  },
  attractionLabel: {
    width: '12vh', 
    height: '12vh',
    backgroundColor: themes.colors.tertiary,
    alignItems: 'center', 
    justifyContent: 'center'
  },
  attractionLabelText: {
    color: 'white',
    fontFamily: 'OpenSans',
    textAlign: 'center',
    fontSize: '1.5vh',
  },
  attractionDescLabel: {
    color: themes.colors.primary,
    fontFamily: 'OpenSans',
    fontSize: '1.2vh',
    textAlign: 'right',
    fontWeight: 'bold'
  },
  attractionDescValue: {
    color: "#898A8D",
    fontFamily: 'OpenSans',
    fontSize: '1.3vh',
    fontWeight: 'bold'
  },
}


export default function RaiListCard(props) {
  var report = props.data[props.index] || "Value"
  var id = report.id || "Name"
  var username = report.username || "Username"
  var reference_no = report.reference_no || "001"
  var name = report.name || "Name"
  var number = report.contact_no || "-------"
  var address = `${report.address}` || "Name"
  var date = djangoToJsDate(report.date)[0] || "Date"
  var time = djangoToJsDate(report.date)[1] || "Time"
  
  const { 
    setSelectedRow, newReports, 
    setNewReports, 
  } = useContext(RaiContext)

  var parentStyle = props.style
  const classes = useStyles();

  const onClickReport = async () => {
    // start: fetch data on click
    const res = axios.get(`${Constants.BASE_URL}/api/emergency/get/id`, {
      params: {
        "id": id
      }
    })
    .then((res)=> {
      if (res.data.values) {
        setSelectedRow(res.data.values)
      }
    })
    .catch((err)=>{})

    const res2 = axios.get(`${Constants.BASE_URL}/api/reports/get/id`, {
      params: {
        "id": id
      }
    })
    .then((res2)=> {
      if (res2.data.rep) {
        setSelectedRow(res2.data.rep)
      }
    })
    .catch((err)=>{})
    // end: fetch data on click
    
  }

  const shortenText = (text, length) => {
    if (text.length > length) {
      return text.substring(0,length) + '...'
    } else {
      return text
    }
  }

  return (
    <div style={parentStyle}> 
    {/* note: pinalitan ko ng style :(((( )))), nagloloko scroll pag sx */}
      <Paper className={classes.cardContainer} elevation={3} onClick={onClickReport}> 
          <Grid container style={{height:"100%",
            backgroundColor: // yellow highlight when unread
            (newReports.count>0?newReports.values.some((val) => val.id === id)?themes.colors.secondary:null:null)
            }}>

              {/* REPORT LABEL IN RED */}
              <Grid container style={{flex: 4, alignItems: 'center', justifyContent: 'center'}}>
                  <Paper sx={{...styles.attractionLabel, 
                    backgroundColor: report.status === "Unresolved"? themes.colors.tertiary: themes.colors.primary 
                    
                  }} 
                    elevation={3}>
                      <Grid container direction={"row"} style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        <Grid item>
                          {report.hasOwnProperty('method')?<img src={EmergencyIcon} height="40vh" width="40vh"/>:null}                            
                        </Grid>
                        <Grid item>
                          <Typography sx={styles.attractionLabelText}>Case ID</Typography>
                          <Typography sx={{...styles.attractionLabelText, fontSize: '3vh'}}>{reference_no}</Typography>
                        </Grid>
                      </Grid>
                  </Paper>
              </Grid>

              {/* REPORT DESCRIPTIONS */}
              <Grid container style={{flex: 9, paddingTop: '1vh', paddingBottom: '1vh'}}>
                  {/* LABELS */}
                  <Grid container style={{flex: 2, flexDirection: 'column'}}>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescLabel}>Contact No:</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescLabel}>Location:</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescLabel}>Date:</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescLabel}>Time:</Typography>
                      </Grid>
                  </Grid>

                  {/* VALUES */}
                  <Grid container style={{flex: 4, flexDirection: 'column', paddingLeft: '0.8vw'}}>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescValue}>+63{number}</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescValue}>{address}</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescValue}>{date}</Typography>
                      </Grid>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescValue}>{time}</Typography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles({
  cardContainer: {
    width: '100%', 
    height: '18vh',
    '&:hover': {
        backgroundColor: themes.colors.hoverColor,
        cursor: 'pointer'
    },
  },
});