import React, { useContext } from 'react'
import { enGB } from 'date-fns/locale'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import { Grid, TextField }  from '@mui/material';
import { RaiContext } from '../../../../contexts/RaiContext'
import { themes } from '../../../../core/theme'
import * as fn from '../../../../core/functions'
import 'react-nice-dates/build/style.css'
import './dateRangePicker.css'

const styles = {
  textField: {
    width:'48%',
    "& .MuiOutlinedInput-input" : { // text style
        fontFamily: 'OpenSans',
        fontSize: '1.5vh', 
    },
    "& .MuiOutlinedInput-notchedOutline" : { // border of textField
        backgroundColor: "rgba(218,218,218,0.3)",
        boxShadow: 3, 
    },
    "& .MuiInputLabel-outlined, .MuiInputLabel-root": { // label style
        color: themes.colors.filterText,
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
    }
  },
}

export default function DateRangePickerRND() {
  const { startDateLocal, setStartDateLocal, endDateLocal, 
    setEndDateLocal, startDate, setStartDate, endDate, setEndDate } = React.useContext(RaiContext)

  const handleStartDateChange = (date) => {
    setStartDateLocal(date);
    setStartDate(fn.parseDate(date));
  };

  const handleEndDateChange = (date) => {
    setEndDateLocal(date);
    setEndDate(fn.parseDate(date));
  };

  return (
    <DateRangePicker
      startDate={startDateLocal}
      endDate={endDateLocal}
      onStartDateChange={handleStartDateChange}
      onEndDateChange={handleEndDateChange}
      minimumLength={1}
      format='dd MMM yyyy' 
      locale={enGB}
    >
      {({ startDateInputProps, endDateInputProps, focus }) => (
        <Grid container direction="row" justifyContent="space-between" style={{marginTop: 10}}>
          <TextField 
            variant="outlined" size ="small"
            className={'input' + (focus === START_DATE ? ' -focused' : '')}
            {...startDateInputProps}
            placeholder='Start date'
            sx={styles.textField}
            label="From" 
            autoComplete='off'
          />
          <span className='date-range_arrow' />
          <TextField 
            variant="outlined"  size ="small"
            className={'input' + (focus === END_DATE ? ' -focused' : '')}
            {...endDateInputProps}
            placeholder='End date'
            sx={styles.textField}
            style={{width:'48%'}}
            label="To"
          />
        </Grid>
      )}
    </DateRangePicker>
  )
}