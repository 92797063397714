import React, { useContext, useEffect } from 'react';
import { Paper } from '@mui/material';
import { themes } from '../../../core/theme'
import { districtNames } from '../../../core';
import SummaryCard from './SummaryCard';
import HorizontalScroll from 'react-scroll-horizontal'

export default function SummaryCardsList () {
    
    return (
        <Paper
            elevation={0}
            style={{
                height: "100%",
                width: "100%",
                marginBottom: 20
            }}
        >
            {/* Note: This will only work if the number of Summary Cards is more than 3 */}
            <HorizontalScroll style={{ overflowX: "scroll" }}>
                {districtNames.map((district) => {
                    var dummy = {district: district, this_month_count: Math.round(Math.random()*100), percentage: Math.round(Math.random()*100-50)}
                    return (<SummaryCard value={dummy} key={district}/>)
                })}
            </HorizontalScroll>
        </Paper>
    );
}

