import React, { useContext, useState } from 'react';
import { DataGrid, GridValueFormatterParams } from '@mui/x-data-grid';
import { TourismContext } from '../../../contexts/TourismContext';
import { Grid } from '@mui/material';
import { themes } from '../../../core/theme'
import { CustomToolbar } from '../../../components/customToolbar';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row'
    },
}

export default function SummaryTable() {
    const { attractionsShow, attractionsShow2 } = useContext(TourismContext)

    const rows = [];
    const columns = [
        {
            field: 'id',
            headerName: 'ID No.',
            width: 200,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 350,
        },
        {
            field: 'score',
            headerName: 'Score',
            sortable: true,
            type: 'number',
            width: 100,
        },
        {
            field: 'num_reviews',
            headerName: 'Reviews',
            type: 'number',
            width: 100,
        },
        {
            field: 'lat',
            headerName: 'Latitude',
            type: 'number',
            sortable: true,
            width: 160,
            valueGetter: (params) =>
                params.row.coords?
                params.row.coords[1]?`${params.row.coords[1]}`:"":''
        },
        {
            field: 'lon',
            headerName: 'Longitude',
            type: 'number',
            sortable: true,
            width: 160,
            valueGetter: (params) =>
                params.row.coords?
                params.row.coords[0]?`${params.row.coords[0]}`:"":''
        },
        {
            field: 'district',
            headerName: 'District',
            sortable: true,
            width: 160,
        },
        {
            field: 'desc',
            headerName: 'Description',
            width: 500,
        },
    ];
   
    return (
        <Grid container sx={styles.root}>
            <DataGrid
                rows={
                    attractionsShow2 && attractionsShow2.values ? attractionsShow2.values:
                    attractionsShow && attractionsShow.values ? attractionsShow.values:rows}
                loading={!attractionsShow} // pag walang data, nag-eerror yung 110
                columns={columns}
                rowsPerPageOptions={[20, 50, 100]}
                checkboxSelection={false}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </Grid>
    );
}
