import React, { useContext } from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { themes } from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';

const styles = {
    cardContainer: {
        width: '100%', 
        height: '18vh',
        '&:hover': {
            backgroundColor: themes.colors.hoverColor,
            cursor: 'pointer'
        },
    },
    attractionLabel: {
        width: '12vh', 
        height: '12vh',
        backgroundColor: themes.colors.tertiary,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'OpenSans',
        textAlign: 'center',
        fontSize: '1.5vh',
    },
    attractionDescLabel: {
        color: themes.colors.primary,
        fontFamily: 'OpenSans',
        fontSize: '1.3vh',
        textAlign: 'right',
        fontWeight: 'bold'
    },
    attractionDescValue: {
        color: "#898A8D",
        fontFamily: 'OpenSans',
        fontSize: '1.3vh',
        fontWeight: 'bold'
    },
}

const categoryListToString = (list) => {
  if (list) {
    var stringCategory = `${list[0]!=="nan"? list[0] : ''}${list[1]!=="nan"? ', ' + list[1] : ''}${list[2]!=="nan"? ', ' + list[2] : ''}`
    return stringCategory
  } else { return 'No Category'}
  
}

export default function AttractionCard(props) {
  var attraction = props.data[props.index] || "Value"
  
  var name = attraction.tour_name || "Name"
  var type = attraction.type || "Type"
  var category = categoryListToString(attraction.categories)
  var description = attraction.desc || "Short description about the issue"
  var parentStyle = props.style
  var img = attraction.image

  const { setSelectedTour } = useContext(TourismContext)

  const onClickAttraction = () => {
    setSelectedTour(attraction)
  }

  const shortenText = (text, length) => {
    if (text.length > length) {
      return text.substring(0,length) + '...'
    } else {
      return text
    }
  }

  return (
    <div style={parentStyle}>
      <Paper sx={styles.cardContainer} elevation={3} onClick={onClickAttraction}> 
          <Grid container style={{width: '100%', height: '100%', flex: 1}}>
              {/* ATTRACTION LABEL IN RED */}
              <Grid container style={{flex: 4, alignItems: 'center', justifyContent: 'center', overflow:"hidden"}}>
                    <Paper sx={styles.attractionLabel} elevation={3}>
                        <Grid container style={{height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                            {img? <img src={img} width="100%" alt={shortenText(name, 45)}/>
                            :<Typography sx={styles.attractionLabelText}>{shortenText(name, 45)}</Typography>}
                        </Grid>
                    </Paper>
              </Grid>

              {/* ATTRACTION DESCRIPTIONS */}
              <Grid container style={{flex: 9, paddingTop: '1vh', paddingBottom: '1vh'}}>
                  {/* LABELS */}
                  <Grid container style={{flex: 2, flexDirection: 'column'}}>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescLabel}>Tour Name:</Typography>
                      </Grid>            
                      <Grid item style={{flex: 3}}>
                          <Typography sx={styles.attractionDescLabel}>Description:</Typography>
                      </Grid>
                  </Grid>

                  {/* VALUES */}
                  <Grid container style={{flex: 4, flexDirection: 'column', paddingLeft: '0.8vw'}}>
                      <Grid item style={{flex: 1}}>
                          <Typography sx={styles.attractionDescValue}>{shortenText(name, 35)}</Typography>
                      </Grid>
                      <Grid item style={{flex: 3}}>
                          <Typography sx={{...styles.attractionDescValue}}>{shortenText(description, 150)}</Typography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </Paper>
    </div>
  );
}
