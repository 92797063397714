export const BASE_URL = "https://api.lungsod.tcagp.upd.edu.ph" //using our server
export const GIS_URL = "https://lungsod.tcagp.upd.edu.ph" //using our server
export const DEV_URL = "http://localhost:8000"


export const districtNames = [
    'Arevalo',
    'Iloilo City Proper',
    'Jaro',
    'La Paz',
    'Lapuz',
    'Mandurriao',
    'Molo'
];

// start: barangays list
export const CityProperList = ['Arsenal Aduana', 'Baybay Tanza', 'Bonifacio Tanza', 'Concepcion-Montes', 'Danao', 'Delgado-Jalandoni-Bagumbayan', 'Edganzon', 'Flores', 'General Hughes-Montes', 'Gloria', 'Hipodromo', 'Inday', 'Jalandoni-Wilson', 'Kahirupan', 'Kauswagan', 'Legaspi dela Rama', 'Liberation', 'Mabolo-Delgado', 'Magsaysay', 'Malipayon-Delgado', 'Maria Clara', 'Monica Blumentritt', 'Muelle Loney-Montes', 'Nonoy', 'Ortiz', 'Osmeña', 'President Roxas', 'Rima-Rizal', 'Rizal Estanzuela', 'Rizal Ibarra', 'Rizal Palapala I', 'Rizal Palapala II', 'Roxas Village', 'Sampaguita', 'San Agustin', 'San Felix', 'San Jose (City Proper)', 'Santo Rosario-Duran', 'Tanza-Esperanza', 'Timawa Tanza I', 'Timawa Tanza II', 'Veterans Village', 'Villa Anita', 'Yulo-Arroyo', 'Zamora-Melliza']

export const MoloList = ['Calumpang', 'Cochero', 'Compania', 'East Baluarte', 'East Timawa', 'Habog-habog Salvacion', 'Infante', 'Kasingkasing', 'Katilingban', 'Molo Boulevard', 'North Avanceña', 'North Baluarte', 'North Fundidor', 'North San Jose', 'Poblacion Molo', 'San Antonio', 'San Juan', 'San Pedro (Molo)', 'South Baluarte', 'South Fundidor', 'South San Jose', 'Taal', 'Tap-oc', 'West Habog-habog', 'West Timawa']

export const LaPazList = ['Aguinaldo', 'Baldoza', 'Bantud', 'Banuyao', 'Burgos-Mabini-Plaza', 'Caingin', 'Divinagracia', 'Gustilo', 'Hinactacan', 'Ingore', 'Jereos', 'Laguda', 'Lopez Jaena Norte', 'Lopez Jaena Sur', 'Luna (La Paz)', 'Macarthur', 'Magdalo', 'Magsaysay Village', 'Nabitasan', 'Railway', 'Rizal (La Paz)', 'San Isidro (La Paz)', 'San Nicolas', 'Tabuc Suba (La Paz)', 'Ticud (La Paz)']

export const LapuzList = ['Alalasan Lapuz', 'Don Esteban-Lapuz', 'Jalandoni Estate-Lapuz', 'Lapuz Norte', 'Lapuz Sur', 'Libertad-Lapuz', 'Loboc-Lapuz', 'Mansaya-Lapuz', 'Obrero-Lapuz', 'Progreso-Lapuz', 'Punong-Lapuz', 'Sinikway (Bangkerohan Lapuz)']

export const MandurriaoList = ['Abeto Mirasol Taft South (Quirino Abeto)', 'Airport (Tabucan Airport)', 'Bakhaw', 'Bolilao', 'Buhang Taft North', 'Calahunan', 'Dungon C', 'Guzman-Jesena', 'Hibao-an Norte', 'Hibao-an Sur', 'Navais', 'Oñate de Leon', 'PHHC Block 17', 'PHHC Block 22 NHA', 'Pale Benedicto Rizal (Mandurriao)', 'San Rafael', 'Santa Rosa', 'Tabucan']

export const JaroList = ['Arguelles', 'Balabago', 'Balantang', 'Benedicto (Jaro)', 'Bito-on', 'Buhang', 'Buntatala', 'Calubihan', 'Camalig', 'Cuartero', 'Cubay', 'Democracia', 'Desamparados', 'Dungon A', 'Dungon B', 'El 98 Castilla (Claudio Lopez)', 'Fajardo', 'Javellana', 'Lanit', 'Libertad-Santa Isabel', 'Lopez Jaena (Jaro)', 'Luna (Jaro)', 'M. V. Hechanova', 'Marcelo H. del Pilar', 'Maria Cristina', 'Montinola', 'Our Lady Of Fatima', 'Our Lady Of Lourdes', 'Quintin Salas', 'Sambag', 'San Isidro (Jaro)', 'San Jose (Jaro)', 'San Pedro (Jaro)', 'San Roque', 'San Vicente', 'Seminario (Burgos Jalandoni)', 'Simon Ledesma', 'Tabuc Suba (Jaro)', 'Tacas', 'Tagbac', 'Taytay Zone II', 'Ungka']

export const ArevaloList = ['Bonifacio (Arevalo)', 'Calaparan', 'Dulonan', 'Mohon', 'Quezon', 'San Jose (Arevalo)', 'Santa Cruz', 'Santa Filomena', 'Santo Domingo', 'Santo Niño Norte', 'Santo Niño Sur', 'So-oc', 'Yulo Drive']
// end: barangays list

export const userTypes = ['Admin', 'User']

export const emergencyCat = [
    {label: "Medical"},
    {label: "Accident"},
    {label: "Fire"},
    {label: "Flood"},
    {label: "Earthquake"},
    {label: "Peace & Order"},
    {label: "Sexual Harrassment"},
    {label: "Domestic Abuse"},
    {label: "Others"},
]

export const regExp = RegExp(
    /^.+@[a-zA-Z0-9]+\.[A-Za-z]+(?!.*?\.\.)[^@]+$/
)

export const regExp_phoneNum = RegExp(
    /^[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im
)