import React, { createContext, useState, useRef } from 'react';
import { getWindowDimensions } from '../core/functions'

export const WindowContext = createContext();

const WindowContextProvider = (props) => {
  const [ratio, setRatio] = useState(getWindowDimensions()) 

  return (
    <WindowContext.Provider value={{
        ratio, setRatio
    }}>
        {props.children}
    </WindowContext.Provider>
  );
}

export default WindowContextProvider;