import EmergencyUnresolved from '../assets/RaiSos/Emergency_unresolved.png'
import EmergencyUnresolvedSelected from '../assets/RaiSos/Emergency_unresolved_selected.png'
import EmergencyResolved from '../assets/RaiSos/Emergency_resolved.png'
import EmergencyResolvedSelected from '../assets/RaiSos/Emergency_resolved_selected.png'

import RAIUnresolved from '../assets/RaiSos/RAI_unresolved.png'
import RAIUnresolvedSelected from '../assets/RaiSos/RAI_unresolved_selected.png'
import RAIResolved from '../assets/RaiSos/RAI_resolved.png'
import RAIResolvedSelected from '../assets/RaiSos/RAI_resolved_selected.png'
import PendingIcon from '../assets/pendingIcon.png'
import L from 'leaflet';


export const CustomMarker = L.icon({
    iconUrl: PendingIcon,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const EmergencyPinUnresolved = L.icon({
    iconUrl: EmergencyUnresolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const EmergencyPinUnresolvedSelected = L.icon({
    iconUrl: EmergencyUnresolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});

export const EmergencyPinResolved = L.icon({
    iconUrl: EmergencyResolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const EmergencyPinResolvedSelected = L.icon({
    iconUrl: EmergencyResolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});

export const RAIPinUnresolved = L.icon({
    iconUrl: RAIUnresolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const RAIPinUnresolvedSelected = L.icon({
    iconUrl: RAIUnresolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});

export const RAIPinResolved = L.icon({
    iconUrl: RAIResolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const RAIPinResolvedSelected = L.icon({
    iconUrl: RAIResolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});

export const TourismPin = L.icon({
    iconUrl: RAIUnresolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

export const TourismPinSelected = L.icon({
    iconUrl: RAIUnresolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});