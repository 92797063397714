import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@mui/material';

import { themes } from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';

import Map from './DashboardMap'
import FilterDrawer from './filterDrawer';
import RaiList from './RaiList';
import ReportDetails from './ReportDetails';
import { WindowContext } from '../../../contexts/WindowContext';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: themes.colors.sub3,
        minHeight: "93vh",
    },
    menuButton: {
        color: themes.colors.poloBlue,
        height: "6vh",
        paddingInline: "2vw",
        fontSize: '2vh',
        fontFamily : "Mulish",
        textTransform: "capitalize",
        borderRadius: 0,
        borderRight: 2, 
        borderColor: "white",
        "&:hover" : {
            color: 'white'
        }
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    }
}

const Dashboard = () => {

    const { selectedRow } = useContext(RaiContext)

    return (
        <Grid container sx={styles.root}>
            
            {/* LEFT SIDE COMPONENT: FILTER MENU */}
            <Grid container style={{ flex: 1.5, backgroundColor: 'white', flexDirection: 'column', paddingInline: '0.5vw' }}>
                <FilterDrawer/>
            </Grid>

            {/* MIDDLE SIDE COMPONENT: LIST */}
            <Grid container style={{ flex: 2.5, flexDirection: 'column', paddingInline: '0.5vw', paddingTop: '1vh' }}>
                { selectedRow ? <ReportDetails/> : <RaiList/> }
                {/* <RaiList/> */}
            </Grid>  

            {/* RIGHT SIDE COMPONENT: MAP */}
            <Grid container style={{ flex: 6, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>
                <Map/>
            </Grid>    
        
        </Grid>
    );
}

const DashboardMobile = () => {
   
    return (
        <Grid container sx={{width:"100%", height:"93vh"}}>
            <Map/>
        </Grid>
    );
}

export default function RaiDashboard() {
    const {ratio, setRatio} = useContext(WindowContext)

    if (ratio < 1.1) {
        return(<DashboardMobile/>)
    } else {
        return(<Dashboard/>)
    }
}
