import React, { createContext, useState, useRef } from 'react';

export const TourismContext = createContext();

const TourismContextProvider = (props) => {
  const [attractionsAll, setAttractionsAll] = useState({ count: 0, values: [] })
  const [attractionsShow, setAttractionsShow] = useState(null)
  const [attractionsShow2, setAttractionsShow2] = useState(null)
  const [selectedAttraction, setSelectedAttraction] = useState(null)
  
  const [toursAll, setToursAll] = useState({ count: 0, values: [] })
  const [toursShow, setToursShow] = useState(null)
  const [toursShow2, setToursShow2] = useState(null)
  const [selectedTour, setSelectedTour] = useState(null)
  const [currentAttractionsUnderTour, setCurrentAttractionsUnderTour] = useState([])

  // FILTER DRAWER CONTEXTS
  const [districtName, setDistrictName] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false) // for checked All of Select by District dropdown
  const [barangayName, setBarangayName] = useState([]);
  const [checkedAllBrgys, setCheckedAllBrgys] = useState(false) // for checked All of Select by Barangay dropdown
  const [listOfBarangays, setListOfBarangays] = useState([]);
  const [attractionCategories, setAttractionCategories] = useState({})
        
  const mapRef = React.useRef(); // for map!
  const groupRef = React.createRef(); // for map!
  const [newAttraction, setNewAttraction] = useState(false) 
  const [newTour, setNewTour] = useState(false) 


  return (
    <TourismContext.Provider value={{
      attractionsAll, setAttractionsAll,
      attractionsShow, setAttractionsShow,
      attractionsShow2, setAttractionsShow2, // for search
      selectedAttraction, setSelectedAttraction,
      toursAll, setToursAll,
      toursShow, setToursShow,
      toursShow2, setToursShow2,
      selectedTour, setSelectedTour,
      currentAttractionsUnderTour, setCurrentAttractionsUnderTour,
      districtName, setDistrictName,
      barangayName, setBarangayName,
      listOfBarangays, setListOfBarangays,
      checkedAllBrgys, setCheckedAllBrgys,
      checkedAll, setCheckedAll,
      attractionCategories, setAttractionCategories,
      mapRef, groupRef,
      newAttraction, setNewAttraction,
      newTour, setNewTour
    }}>
        {props.children}
    </TourismContext.Provider>
  );
}

export default TourismContextProvider;