import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, Paper } from '@mui/material';
import { themes } from '../../../../core/theme';
import DateRangePickerRND from './dateRangePicker'
import CheckboxCategory from './checkboxCategory';
import MultipleSelectDistrict from './multipleSelectDistrict';
import axios from 'axios';
import * as Constants from '../../../../core'
import { SosContext } from '../../../../contexts/SosContext';

const styles = {
  text: {
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
    color: themes.colors.filterText
  }
}

export default function FilterDrawer(props) {

  const noZoom = false || props.noZoom;

  const {

    emergencyAll, setEmergencyAll,
    emergencyShow, setEmergencyShow, // shown report after filter
    checkEmergency, setCheckEmergency,
    newEmergency, setNewEmergency,

    checked, setChecked, 
    startDateLocal, setStartDateLocal, 
    endDateLocal, setEndDateLocal,
    startDate, setStartDate, 
    endDate, setEndDate, 
    districtName, setDistrictName, 
    checkedAll, setCheckedAll, 
    mapRef, groupRef,
    emergency, setEmergency,

    verificationStatus, setVerificationStatus,
    methodCreated, setMethodCreated, 
  } = useContext(SosContext)


  const status = [
    {
        Parent: 'Status', 
        Child: ['Resolved', 'Unresolved'],
        Context: [checked, setChecked]
    }, 
  ]

  const verificationCat = [
    {
        Parent: 'Verification Status', 
        Child: ['Verified', 'Unverified'],
        Context: [verificationStatus, setVerificationStatus]
    }, 
  ]

  const emergencyCat = [
    {
        Parent: 'Emergency', 
        Child: ['Medical', 'Accident', 'Fire', 'Flood', 'Earthquake', 'Peace & Order', 
      'Sexual Harrassment', 'Domestic Abuse', 'Others'],
        Context: [emergency, setEmergency]
    }, 
  ]

  const methodCreatedCat = [
    {
        Parent: 'Method', 
        Child: ['Chat', 'Call', 'Manual'],
        Context: [methodCreated, setMethodCreated]
    }, 
  ]

  const fetchData3 = async () => {
    const res = await axios.get(`${Constants.BASE_URL}/api/emergency/check/new`, { params: {
      "id": JSON.parse(localStorage.getItem('user')).user_id,
    }})
    .then((res)=> {
      if (res.data.count>0) {
        setCheckEmergency(res.data) 
      } else {
        setCheckEmergency({count:0, values: []})
      }
    })
  }

  const fetchData4 = async () => {
    const res = await axios.get(`${Constants.BASE_URL}/api/emergency/get/old`, { params: {
      "id": JSON.parse(localStorage.getItem('user')).user_id,
    }})
    .then((res)=> {
      if (res.data.count>0) {
        setEmergencyAll(res.data) // pagkarating
      } else {
        setEmergencyAll({count:0, values: []})
      }
    })
  }
 
  useEffect(() => { 
      fetchData3()
      fetchData4()
  }, [])

  useEffect(() => { // on succeeding loads, interval is every 5 seconds. prevents snackbar to notify repeatedly
    const interval = setInterval(() => {
      fetchData3()
    }, 3000)
    return()=>clearInterval(interval)
  }, [checkEmergency, newEmergency]) 

  React.useEffect(() => {
      if (emergencyAll.count) {
        var keys = Object.keys(checked);
        var filtered = keys.filter(function(key) { // returns all keys with value == true
            return checked[key]
        });
        var filteredEmergency = emergencyAll.values.filter(function(report) {
          for (var i = 0; i < filtered.length; i++){  // check in
            if (report.status === filtered[i].replace(/_/g,' ')) { // replaces underscore with space
              return true
            }
          }
        })

        var keysVerification = Object.keys(verificationStatus);
        var filteredVerification = keysVerification.filter(function(key) { // returns all keys with value == true
          return verificationStatus[key]
        });
        filteredEmergency = filteredEmergency.filter(function(report) {
          for (var i = 0; i < filteredVerification.length; i++){  // check in
            if (filteredVerification[i] == "Verified") {
              if (report.is_verified == "true") { // replaces underscore with space
                return true
              }
            } else if (filteredVerification[i] == "Unverified") {
              if (report.is_verified == "false") { // replaces underscore with space
                return true
              }
            }
            
          }
        })

        if (startDate != undefined && endDate != undefined) {
          filteredEmergency = filteredEmergency.filter(function(report) {
            var date = report.date.replace(/-/g,'');
            date = date.substring(0, date.indexOf('T'));
    
            if (startDate <= date && date <= endDate) { 
              return true
            }
          })
        }
    
        if (districtName.length>0) {
          filteredEmergency = filteredEmergency.filter(function(report) {
            if (report.district != null) {
              
    
              for (var i = 0; i < districtName.length; i++){  // check in
                if (report.district.includes(districtName[i].replace(/_/g,' '))) { // replaces underscore with space
                  return true
                }
              }
            }
          })
        }
    
        var keys2 = Object.keys(emergency);
        var filtered2 = keys2.filter(function(key) { // returns all keys with value == true
          return emergency[key]
        });
       
        filteredEmergency = filteredEmergency.filter(function(report) {
          for (var i = 0; i < filtered2.length; i++){  // check in
            if (report.category === filtered2[i].replace(/_/g,' ')) { // replaces underscore with space
              return true
            }
          }
        })
        
        var cmkeys = Object.keys(methodCreated);
        var filteredMc = cmkeys.filter(function(key) { // returns all keys with value == true
            return methodCreated[key]
        });
    
        filteredEmergency = filteredEmergency.filter(function(report) {
          for (var i = 0; i < filteredMc.length; i++){  // check in
            if (report.method === filteredMc[i].replace(/_/g,' ')) { // replaces underscore with space
              return true
            }
          }
        })

        filteredEmergency = filteredEmergency.sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        });
        
        setEmergencyShow({
          count: filteredEmergency.length,
          values: filteredEmergency}) // needed
          
      }
      
  }, [emergencyAll])

  const onClickReset = (event) => {
    for (const key in checked) {
      if (key === "Unresolved") {checked[key] = true}
      else {checked[key] = false}
    }
    for (const key in verificationStatus) {
      verificationStatus[key] = true
    }

    for (const key in emergency) {
      emergency[key] = true
    }
   
    var filtered1 = ["Unresolved"]

    var filteredEmergency = emergencyAll.values.filter(function(report) {
        if (report.status === filtered1[0].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
    })
    for (const key in methodCreated) {
      methodCreated[key] = true
    }
    setEmergencyShow({
      count: filteredEmergency.length,
      values: filteredEmergency})
    setDistrictName([])
    setStartDateLocal()
    setEndDateLocal()
    setStartDate()
    setEndDate()
  }

  const onClickFilter = () => {

    var keys1 = Object.keys(checked);
    var filtered1 = keys1.filter(function(key) { // returns all keys with value == true
      return checked[key]
    });

    var keysVerification = Object.keys(verificationStatus);
    var filteredVerification = keysVerification.filter(function(key) { // returns all keys with value == true
      return verificationStatus[key]
    });

    var filteredEmergency = emergencyAll.values.filter(function(report) {
      for (var i = 0; i < filtered1.length; i++){  // check in
        if (report.status === filtered1[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })
   


    filteredEmergency = filteredEmergency.filter(function(report) {
      for (var i = 0; i < filteredVerification.length; i++){  // check in
        if (filteredVerification[i] == "Verified") {
          if (report.is_verified === "true") { // replaces underscore with space
            return true
          }
        } else if (filteredVerification[i] == "Unverified") {
          if (report.is_verified === "false") { // replaces underscore with space
            return true
          }
        }
        
      }
    })

    if (startDate != undefined && endDate != undefined) {
      filteredEmergency = filteredEmergency.filter(function(report) {
        var date = report.date.replace(/-/g,'');
        date = date.substring(0, date.indexOf('T'));

        if (startDate <= date && date <= endDate) { 
          return true
        }
      })
    }

    if (districtName.length>0) {
      filteredEmergency = filteredEmergency.filter(function(report) {
        if (report.district != null) {
          

          for (var i = 0; i < districtName.length; i++){  // check in
            if (report.district.includes(districtName[i].replace(/_/g,' '))) { // replaces underscore with space
              return true
            }
          }
        }
      })
    }

    var emergencyKeys = Object.keys(emergency);
    var filteredEmer = emergencyKeys.filter(function(key) { // returns all keys with value == true
        return emergency[key]
    });

    filteredEmergency = filteredEmergency.filter(function(report) {
      for (var i = 0; i < filteredEmer.length; i++){  // check in
        if (report.category === filteredEmer[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })

    var cmkeys = Object.keys(methodCreated);
    var filteredMc = cmkeys.filter(function(key) { // returns all keys with value == true
        return methodCreated[key]
    });

    filteredEmergency = filteredEmergency.filter(function(report) {
      for (var i = 0; i < filteredMc.length; i++){  // check in
        if (report.method === filteredMc[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })

    filteredEmergency = filteredEmergency.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    });
    setEmergencyShow({
      count: filteredEmergency.length,
      values: filteredEmergency}) // needed

  }
  
  
  const zoomToSelected = () => {
    if (mapRef.current) {
      var map = mapRef.current
      var group = groupRef.current
      map.fitBounds(group.getBounds());
    }
  }

  return (

    <Grid container spacing={0}>
      <Paper
        square
        elevation={0}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          maxHeight: "90vh",
          marginBottom: 20
        }}
      >

        <Grid
          container 
          style={{
            flex: 4,
            height: '88%', 
            width: '100%',
            paddingTop: '1vh',
            overflowY: "auto",
            overflowX: "clip"
          }}
        >
          <Typography style={styles.text}>Filter by Date:</Typography>
          <Grid item xs={12} style={{marginBottom: 10, paddingInline: 4}}>
            <DateRangePickerRND/>
          </Grid>

          <Typography style={styles.text}>Filter by District:</Typography>
          <Grid item xs={12} style={{marginBottom: 10, paddingInline: 4}}>
            <MultipleSelectDistrict/>
          </Grid>

          <Typography style={styles.text}>Filter by Status:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {status?status.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>
          
          <Typography style={styles.text}>Filter by Verification Status:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {verificationCat?verificationCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>

          <Typography style={styles.text}>Filter by Emergency Category:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {emergencyCat?emergencyCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>

          <Typography style={styles.text}>Filter by Method Created:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {methodCreatedCat?methodCreatedCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>


        </Grid>

        <Grid container style={{ height: '12%', width: '100%', alignItems: 'center'}}>
          <Grid container direction="row" justifyContent="space-between">
            <Button style={{...styles.text, color:"white", width:"48%"}} variant="contained" onClick={onClickFilter}>Filter</Button>
            <Button style={{...styles.text, color:themes.colors.primary, width:"48%"}} variant="outlined" onClick={onClickReset}>Reset</Button>
          </Grid>
          {!noZoom ?
          <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
            <Button style={{...styles.text, color:themes.colors.primary, width:"100%"}} variant="outlined" onClick={zoomToSelected}>Zoom to bounds</Button>
          </Grid> : null }
        </Grid>
      </Paper>     
    </Grid>
  );
}
