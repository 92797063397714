import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Grid, Typography, Paper, TextField, Tooltip } from '@mui/material';
import { FixedSizeList } from 'react-window';

import axios from 'axios';

import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { SosContext } from '../../../contexts/SosContext';
import SosCardCopy from './SosListCard';
import SosCardCopy2 from './SosListCardCall';
import { PostAddOutlined as PostAddOutlinedIcon } from '@mui/icons-material';
import { Box, Tabs, Tab } from '@mui/material';
import { Message as MessageIcon, Phone as PhoneIcon, PhoneCallback as PhoneCallbackIcon} from '@mui/icons-material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import PropTypes from 'prop-types';
import NotificationSound from '../../../assets/notification-sound.wav'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{width:"100%"}}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    },
}


const Messaged = ({emergencyShow, emergencyShow2}) => {
    return(
        emergencyShow2 && emergencyShow2.values
            ?
            <FixedSizeList
                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                itemSize={200}
                width={"100%"}
                itemCount={ 
                    (emergencyShow2 && emergencyShow2.values)?
                    emergencyShow2.count 
                    :null
                }
                itemData={
                    (emergencyShow2 && emergencyShow2.values)?
                    emergencyShow2.values
                    :null
                }
            >
                {SosCardCopy}
                
            </FixedSizeList>
    
            :
             (emergencyShow && emergencyShow.values) 
             ?
            <FixedSizeList
                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                itemSize={200}
                width={"100%"}
                itemCount={ 
                    (emergencyShow && emergencyShow.values)?
                    emergencyShow.count 
                    :null
                }
                itemData={
                    (emergencyShow && emergencyShow.values)?
                    emergencyShow.values
                    :null
                }
            >
                {SosCardCopy}
                
            </FixedSizeList>
            :null
        
    )
}


const Tapped = ({tapped, tapped2}) => {
    return(
        tapped2 && tapped2.values
            ?
            <FixedSizeList
                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                itemSize={200}
                width={"100%"}
                itemCount={ 
                    (tapped2 && tapped2.values)?
                    tapped2.count 
                    :null
                }
                itemData={
                    (tapped2 && tapped2.values)?
                    tapped2.values
                    :null
                }
            >
                {SosCardCopy2}
                
            </FixedSizeList>
            :
            (tapped && tapped.values) 
            ?
            <FixedSizeList
                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                itemSize={200}
                width={"100%"}
                itemCount={ 
                    (tapped && tapped.values)?
                    tapped.count 
                    :null
                }
                itemData={
                    (tapped && tapped.values)?
                    tapped.values
                    :null
                }
            >
                {SosCardCopy2}
                
            </FixedSizeList>
            :"Refresh"
        )
}

export default function SosList () {

    var dateToday = new Date().toDateString().replace(/ /,', ') 
    const [searchVal, setSearchVal] = useState('')
    const { 
        checkEmergency, setCheckEmergency,
        newEmergency, setNewEmergency,
        emergencyShow, setEmergencyShow, 
        emergencyAll, setEmergencyAll, map,
        emergencyShow2, setEmergencyShow2, 
        setNewSos,

    } = useContext(SosContext)

    const handleNewEmergency= async () => {
        setCheckEmergency({count:0, values: []})
        fetchEmergencies()
    }

    const fetchEmergencies = async () => {
        var newArr 
        var newCount 
        var newArr2
        var newCount2 
        const res = await axios.get(`${Constants.BASE_URL}/api/emergency/get/new`, { params: {
            "id": JSON.parse(localStorage.getItem('user')).user_id,
        }})
        .then((res)=> {
        if (res.data.count>0) {
            newArr = res.data.values.concat(emergencyAll.values)
            newCount = res.data.count + emergencyAll.count
            setEmergencyAll({ // kapag may new report
                count: newCount,
                values: newArr
            })
            if (newEmergency.count>0) {
                newArr2 = res.data.values.concat(newEmergency.values)
                newCount2 = res.data.count + newEmergency.count
                setNewEmergency({
                    count: newCount2,
                    values: newArr2
                })
            } else {
                setNewEmergency(res.data)
            }
        } else {
            setNewEmergency({count:0, values: []})
        }
        })
    }

    const Summary = () => {
        return (
            <Grid>
                <Typography sx={styles.text}>
                {'Showing: '}{ 
                    emergencyShow2? emergencyShow2.count:
                    emergencyShow? emergencyShow.count:
                        " . . ."} results as of {dateToday}
                </Typography>
                
            </Grid>
        )
    }

    React.useEffect(()=>{
            if (emergencyShow && emergencyShow.values && searchVal !== "" ) {
                var filtered = emergencyShow.values.filter(item => item.reference_no.toLowerCase().includes(searchVal.toLowerCase()) || item.name.toLowerCase().includes(searchVal.toLowerCase()));
                setEmergencyShow2({
                    count: filtered.length,
                    values: filtered
                })
            } else {
                setEmergencyShow2()
            }
      
        
    }, [emergencyShow, searchVal])


    const searchFilter = (event) => {
        var text = event.target.value
        setSearchVal(text)
    }

    const audioPlayer = useRef(null);

    function playAudio() {
      audioPlayer.current.play().then()
      .catch(error=>{
        console.log("error in autoplay", error)
      })
    }

    useEffect(()=>{
        if (checkEmergency.count>0) {
            playAudio()
        }

    }, [checkEmergency.count])
    

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: themes.colors.sub3,
                    maxHeight: "90vh",
                    marginBottom: 20
                }}
            >
                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Summary/>
                    <Tooltip title="Add an emergency">
                        <PostAddOutlinedIcon 
                            onClick={()=>setNewSos(true)} 
                            sx={{
                                color: themes.colors.filterText,
                                '&:hover': {
                                    color: themes.colors.primary,
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid> 
                
                <TextField id="outlined-basic" label="Search by ID/ Name" variant="outlined" fullWidth style={{marginTop:"2vh"}}
                    onChange = {text => searchFilter(text)}/>

                <Grid container style={{ height: '90%', width: '100%', paddingTop: '1vh', overflowY: "clip" }}>
                    {checkEmergency.count>0?<Button style={{height: '5vh', marginBottom:10, backgroundColor:themes.colors.tertiary}} onClick={handleNewEmergency} fullWidth variant="contained">Show {checkEmergency.count} new {checkEmergency.count > 1? 'emergencies': 'emergency'}</Button>:null}
                    {checkEmergency.count>0?<audio ref={audioPlayer} src={NotificationSound}/>:null}
                    
                    <Messaged emergencyShow={emergencyShow} emergencyShow2={emergencyShow2}/>
                    
                </Grid>
            </Paper>
        </Grid>
    );
}
