import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'leaflet/dist/leaflet.css'
import { generalTheme } from './core/theme'
import { ThemeProvider } from '@mui/material/styles';
import RaiContextProvider from "./contexts/RaiContext";
import TourismContextProvider from './contexts/TourismContext'
import SosContextProvider from './contexts/SosContext';
import WindowContextProvider from './contexts/WindowContext';

ReactDOM.render(
  <React.StrictMode>
    <WindowContextProvider>
    <SosContextProvider>
    <RaiContextProvider>
      <TourismContextProvider>
        <ThemeProvider theme={generalTheme}>
          <App />
        </ThemeProvider>
      </TourismContextProvider>
    </RaiContextProvider>
    </SosContextProvider>
    </WindowContextProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
