import React, { createContext, useState, useRef } from 'react';

export const SosContext = createContext();

const SosContextProvider = (props) => {

  const [emergencyAll, setEmergencyAll] = useState({
    count: null,
    values: null
  })

  const [emergencyShow, setEmergencyShow] = useState(null)
  const [checkEmergency, setCheckEmergency] = useState({})
  const [newEmergency, setNewEmergency] = useState({})

  const [selectedRow, setSelectedRow] = useState(null) // for clicking a row in a table, all details associated with clicked item
  const [selectedImgArray, setSelectedImgArray] = useState(null) // for a row/ item, stores an array of all images associated with clicked item

  const map = React.useRef(null); // for map!
 
  const [isBackdropOpen, setIsBackdropOpen] = useState(false) // for loading screen

  const [checked, setChecked] = React.useState({
    Resolved: false,
    Unresolved: true
  });

  // https://stackoverflow.com/questions/16845614/zoom-to-fit-all-markers-in-mapbox-or-leaflet

  // for multiple select of districts
  const [districtName, setDistrictName] = React.useState([]);
  const [checkedAll, setCheckedAll] = React.useState(false) // for checked All of Select by District dropdown
  // for multiple select of districts

  // start: date range picker
  const [startDateLocal, setStartDateLocal] = useState()
  const [endDateLocal, setEndDateLocal] = useState()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  // end: date range picker

  const [emergency, setEmergency] = React.useState({})
  const [wasteManagement, setWasteManagement] = React.useState({})
  const [engineeringAndConstruction, setEngineeringAndConstruction] = React.useState({})
  const [traffic, setTraffic] = React.useState({})
  const [others, setOthers] = React.useState(true)

  const [verificationStatus, setVerificationStatus] = React.useState({})

  // FOR MAP CREATE
  const [showRaiPoints, setShowRaiPoints] = React.useState(true)
  const [buffer, setBuffer] = React.useState(false)
  const [concave, setConcave] = React.useState(false)
  const [convex, setConvex] = React.useState(false)
  const [voronoi, setVoronoi] = React.useState(false)

  const mapRef = React.useRef(); // for map!
  const groupRef = React.createRef(); // for map!

  const [emergencyShow2, setEmergencyShow2] = React.useState()
        
  const [newSos, setNewSos] = React.useState(false)
  const [value, setValue] = React.useState(0); // 0 for Messaged, 1 for Called

  const [tapped, setTapped] = React.useState({})
  const [tapped2, setTapped2] = React.useState({})

  const [methodCreated, setMethodCreated] = React.useState({})


  return (
    <SosContext.Provider value={{
      
      emergencyAll, setEmergencyAll,
      emergencyShow, setEmergencyShow, // shown report after filter
      checkEmergency, setCheckEmergency,
      newEmergency, setNewEmergency,
      
      selectedRow, setSelectedRow,
      selectedImgArray, setSelectedImgArray,
      map,
      
      checked, setChecked, // checkboxStatus states

      isBackdropOpen, setIsBackdropOpen, // for loading page

      districtName, setDistrictName,
      checkedAll, setCheckedAll,

      startDateLocal, setStartDateLocal,
      endDateLocal, setEndDateLocal,
      startDate, setStartDate,
      endDate, setEndDate,

      emergency, setEmergency,
      wasteManagement, setWasteManagement,
      engineeringAndConstruction, setEngineeringAndConstruction,
      traffic, setTraffic,
      others, setOthers,

      verificationStatus, setVerificationStatus,

      mapRef, groupRef,

      emergencyShow2, setEmergencyShow2, 
      // for search by Id
      tapped, setTapped,
      tapped2, setTapped2,

      newSos, setNewSos,
      value, setValue,

      methodCreated, setMethodCreated
    }}>
      {props.children}
    </SosContext.Provider>
  );
}

export default SosContextProvider;