import * as React from 'react';
import { TextField, DialogActions, DialogContent, Typography } from '@mui/material';
import { Button, LinearProgress } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../../components/dialog'
import axios from 'axios';
import * as Constants from '../../core'


function PasswordDialog({open, onClose}) {
    const [isValid, setIsValid] = React.useState(false) // for textinput of new password
    const [userId, setUserID] = React.useState()
    const [isEmailSent, setIsEmailSent] = React.useState(false)

    const [otp, setOTP] = React.useState()
    const [newPassword, setNewPassword] = React.useState()
    const [email, setEmail] = React.useState(JSON.parse(localStorage.getItem('user'))?JSON.parse(localStorage.getItem('user')).email:'')
    
    const [showLP, setShowLP] = React.useState(false)

    var emailRef = React.useRef(null)

    const searchEmail = (email) => {
        resendCode(email)
        emailRef.current = email
    };
    
    const resendCode = async (email) => {
        setShowLP(true)
        await axios.post(`${Constants.BASE_URL}/api/command_user/sendrecoveryemail`, {
            email: email
        })
        .then((res)=>{
            if (res.data.message === "Email sent"){
                alert(res.data.message)
                setIsEmailSent(true)
                setShowLP(false)
            } else {
                alert(res.data.message)
                setShowLP(false)
            }
        })
    }

    const verifyCode = async (otp) => {
        setShowLP(true)
        await axios.post(`${Constants.BASE_URL}/api/command_user/resetcode`, {
            token: otp
        })
        .then((res)=>{
            if (res.data.message === "valid"){
                alert(res.data.message)
                setIsValid(true)
                setUserID(res.data.description)
                setShowLP(false)
                
            } else {
                alert(res.data.message)
                setShowLP(false)
    
            }
        })
    }

    const resetPassword = async (password) => {
        setShowLP(true)

        const newPassword = {
            "userId": userId,
            "password": password
        }

        await axios.post(`${Constants.BASE_URL}/api/command_user/changepassword`, newPassword)
        .then((res)=>{
            if (res.data.message === "Success"){
                alert(res.data.message)
                onClose()
                setIsValid(false)
                setShowLP(false)
                
            } else {
                alert(res.data.message)
                setShowLP(false)
    
            }
        })
    }
    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth
            maxWidth="sm"
            >
                {!isValid && !isEmailSent?
                <>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} component="h2" variant="h5" >
                        {email? "Send code to the email below:" :"Find your account"}
                    </BootstrapDialogTitle>
                    {
                        !isValid && !isEmailSent && open && showLP?
                        <LinearProgress />:null
                    }
                    <DialogContent dividers >
                        <Typography gutterBottom>
                            Email:
                        </Typography>
                        <TextField value={email} onChange={(e)=>setEmail(e.target.value)} fullWidth size="small" autoFocus></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!email} variant="outlined" onClick={()=>{searchEmail(email)}} id="email" name="email" autoComplete="email" >
                            {email? "Send": "Search"}
                        </Button>
                    </DialogActions>
                </>
                :
                !isValid && isEmailSent?
                <>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} component="h2" variant="h5" >
                        Reset your password
                    </BootstrapDialogTitle>
                    {
                        !isValid && isEmailSent && open && showLP?
                        <LinearProgress />:null
                    }
                    <DialogContent dividers >
                        <Typography gutterBottom>
                            To continue, enter the one-time Password Reset Code we just sent to your email.
                        </Typography>
                        <TextField value={otp} onChange={(e)=>setOTP(e.target.value)} fullWidth size="small" autoFocus></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined"onClick={()=>{resendCode(emailRef.current)}}>Resend code</Button>
                        <Button variant="outlined" disabled={!otp} onClick={()=>verifyCode(otp)}>Verify</Button>
                    </DialogActions>
                </>
                :
                <>
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} component="h2" variant="h5" >
                        Enter new password
                    </BootstrapDialogTitle>
                    {
                        isValid && open && showLP?
                        <LinearProgress />:null
                    }
                    <DialogContent dividers >
                        <TextField 
                            error={newPassword?newPassword.length < 6 ? "At least 6  required" : "":""} 
                            helperText={newPassword?newPassword.length < 6 ? "At least 6 characters required" : "":""} 
                            value={newPassword} onChange={(e)=>setNewPassword(e.target.value)} fullWidth size="small" autoFocus></TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" disabled={!newPassword} onClick={()=>resetPassword(newPassword)}>Change PW</Button>
                    </DialogActions>
                </>
            }
        </BootstrapDialog>
    )
}

export default function ForgotPassword(props) {
    const [open, setOpen] = React.useState(false) // state for password dialog box
    const buttonVariant = props.variant || "contained"

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant={buttonVariant}
                style={{ fontSize: '1.5vh',
                        fontFamily : "Mulish",
                        lineHeight: 1,
                        textDecoration:"underline",
                        color:"white",
                        cursor:"pointer",
                        textTransform: "none",
                        }} onClick={()=>setOpen(true)} component="div" >
                    Reset Password
            </Button>
            <PasswordDialog open={open} onClose={handleClose} />
        </div>
  );
}
