import React, { useContext, useEffect, useState } from 'react';
import {  Grid, Typography, Paper, TextField, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { FixedSizeList } from 'react-window';

import axios from 'axios';

import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';
import AttractionListCard from './AttractionListCard.js';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    },
}


export default function AttractionList () {

    var dateToday = new Date().toDateString().replace(/ /,', ') 
    const [searchVal, setSearchVal] = useState('')
    const { 
        attractionsAll, setAttractionsAll,
        attractionsShow,  setAttractionsShow,
        attractionsShow2, setAttractionsShow2,
        newAttraction, setNewAttraction
    } = useContext(TourismContext);

    // start: for getting Attractions (Tourism)
    const fetchAttractions = async () => { 
        const res = await axios.get(`${Constants.BASE_URL}/api/tourism/get/touristattractions/all`)
        .then((res)=> {
        if (res.data.count>0) {
            setAttractionsAll(res.data)
            setAttractionsShow(res.data)
        } else {
            setAttractionsAll({count:0, values: []})
        }
        })
    }
    // end: for getting Attractions (Tourism)

    useEffect(()=>{
       fetchAttractions()
      }, [])

    const Summary = () => {
        return (
            <Grid>
                <Typography sx={styles.text}>
                {'Showing: '}{ 
                    attractionsShow2?
                    attractionsShow2.count:
                    attractionsShow?
                    attractionsShow.count:
                        " . . ."} results as of {dateToday}
                </Typography>
            </Grid>
        )
    }
    

    React.useEffect(()=>{
        if (attractionsShow && attractionsShow.values && searchVal != "") {
            var filtered2 = attractionsShow.values.filter(item => 
                item.name.toLowerCase().includes(searchVal.toLowerCase())
                );
            setAttractionsShow2({
                count: filtered2.length,
                values: filtered2
            })
        } 
        else {
            setAttractionsShow2(null)
        }
    }, [attractionsShow, searchVal])

    const searchFilter = (event) => {
        var text = event.target.value
        setSearchVal(text)
    }

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                height: "100%",
                width: "100%",
                backgroundColor: themes.colors.sub3,
                maxHeight: "90vh",
                marginBottom: 20
                }}
            >
                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Grid item style={{flexGrow: 0.8}}><Summary/></Grid>
                    <Tooltip title="Add an attraction">
                    <PostAddOutlinedIcon onClick={()=>setNewAttraction(true)} 
                        sx={{
                            color: themes.colors.filterText,
                            '&:hover': {
                                color: themes.colors.primary,
                                cursor: 'pointer'
                            }
                        }}
                    />
                    </Tooltip>
                    <Tooltip title="Refresh Attractions">
                    <RefreshIcon onClick={()=>fetchAttractions()} 
                        sx={{
                            color: themes.colors.filterText,
                            '&:hover': {
                                color: themes.colors.primary,
                                cursor: 'pointer'
                            }
                        }}
                    />
                    </Tooltip>
                </Grid> 
                <TextField id="outlined-basic" label="Search by Name" variant="outlined" fullWidth style={{marginTop:"2vh"}}
                    onChange = {text => searchFilter(text)}/>

                <Grid container style={{  width: '100%', paddingTop: '1vh', overflow: "clip" }}>
                    {
                        attractionsShow2 && attractionsShow2.values?
                            <FixedSizeList
                                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                                itemSize={200}
                                width={"100%"}
                                itemCount={attractionsShow2.count}
                                itemData={attractionsShow2.values}
                            >
                                {AttractionListCard}
                                
                            </FixedSizeList>
                        :
                        attractionsShow && attractionsShow.values?
                            <FixedSizeList
                                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                                itemSize={200}
                                width={"100%"}
                                itemCount={attractionsShow.count}
                                itemData={attractionsShow.values}
                            >
                                {AttractionListCard}
                                
                            </FixedSizeList>
                        :
                        null
                    }

                </Grid>
            </Paper>
        </Grid>
    );
}

