import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import {Chip} from '@mui/material';
import * as Constants from './index.js'

import CompletedIcon from '../assets/resolvedIcon.png'
import PendingIcon from '../assets/pendingIcon.png'
import NotStartedIcon from '../assets/notStartedIcon.png'
import axios from 'axios'

// for avatar color
export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      fontSize: 50
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
// for avatar color

// start: check if age is legal (18y/o above)
export function isDate18orMoreYearsOld(day, month, year) {
  return new Date(year+18, month, day) <= new Date();
}
// end: check if age is legal (18y/o above)

export const statusFormatter = (cell) => {
  return (
      <span>
          {cell==="Resolved"?
            <Chip icon={<CheckCircleOutlineIcon />} label={cell} variant="outlined" color="primary" />
            :cell==="Unresolved"?
            <Chip icon={<RunningWithErrorsOutlinedIcon />} label={cell} variant="outlined"  color="error"  />
            :
            <Chip icon={<PendingOutlinedIcon />} label={cell} variant="outlined" color="primary"  />
          }
      </span>
  );
};


export const verificationStatusFormatter = (cell) => {
  return (
      <span>
          {cell==="true"?
            <Chip icon={<VerifiedOutlinedIcon />} label={"Verified"} variant="outlined" color="primary" />
            :cell==="false"?
            <Chip label={"Unverified"} variant="outlined"  color="default"  />
            :null
          }
      </span>
  );
};

export const adminVerificationStatusFormatter = (cell) => {
  return (
      <span>
          {cell==="verified"?
            <Chip icon={<VerifiedOutlinedIcon />} label={"Verified"} variant="outlined" color="primary" />
            :cell==="unverified"?
            <Chip label={"Unverified"} variant="outlined"  color="default"  />
            :null
          }
      </span>
  );
};

export const getBarangayList = (district) => {

  switch(district) {
      case "Arevalo":
          return Constants.ArevaloList
      case "Iloilo City Proper":
          return Constants.CityProperList
      case "Jaro":
          return Constants.JaroList
      case "La Paz":
          return Constants.LaPazList
      case "Lapuz":
          return Constants.LapuzList
      case "Mandurriao":
          return Constants.MandurriaoList
      case "Molo":
          return Constants.MoloList
      default:
          return []
  }
};

export function colorMarker(category) {
    switch (category) {
      /*Icons need to be defined beforehand 
        according to leaflet-color-marker documentation*/
      case 'Resolved':     return CompletedIcon; break; 
      case 'Unresolved':   return NotStartedIcon; break;
    }
  }

export function djangoToJsDate(djangoDate) { 
  var jsDateTime = djangoDate.split('T')
  var jsDate = new Date(jsDateTime[0]).toLocaleString('en-us',{day:'numeric', month: 'short', year:'numeric'})
  var jsTime = jsDateTime[1].substring(0, jsDateTime[1].indexOf('.'))

  return [jsDate, jsTime]
  //  format: ['Dec 10, 2021', '10:40:59']
}

export function parseDate (date) {
  if (date){
  var splitdate = date.toLocaleString().split(",")[0].split("/")
  return (pad(splitdate[2],4).concat(pad(splitdate[0],2)).concat(pad(splitdate[1],2)))}
  // format: 20220106
};

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
} 

export const getTokenAndNotifyResolved = async (id, username, moduleName, reference_no) => {
  var response = {
    "id":id,
    "username": username, 
    "moduleName": moduleName,
    "tokenArr": []
  }
  const res = await axios.get(`${Constants.BASE_URL}/api/accounts/get/expopushtokens`, { params: {
    "username": username
  }})
  .then((res)=> {
    if (res.data.count>0) {
      res.data.values.map((token) => {
          sendPushNotification(token, "LUNGSOD Update", 
          `Hi, ${username}! Your ${moduleName}, with ID no: ${reference_no} has been resolved! We thank you for reporting!`)
      })

    }
  })
}

export const getTokenAndNotifySeen = async (id, username, moduleName, reference_no) => {
  var response = {
    "id":id,
    "username": username, 
    "moduleName": moduleName,
    "tokenArr": []
  }
  const res = await axios.get(`${Constants.BASE_URL}/api/accounts/get/expopushtokens`, { params: {
    "username": username
  }})
  .then((res)=> {
    if (res.data.count>0) {
      res.data.values.map((token) => {
          sendPushNotification(token, "LUNGSOD Update", 
          `Hi, ${username}! Your ${moduleName}, with ID no: ${reference_no} has been read! We'll notify you for updates!`)
      })

    }
  })
}

export const getTokenAndNotifyRemarks = async (id, username, moduleName, reference_no, remarks) => {
 
  const res = await axios.get(`${Constants.BASE_URL}/api/accounts/get/expopushtokens`, { params: {
    "username": username
  }})
  .then((res)=> {
    if (res.data.count>0) {
      res.data.values.map((token) => {
          sendPushNotification(token, "LUNGSOD Updated Remarks", 
          `Hi, ${username}! Your ${moduleName}, with ID no: ${reference_no} has been updated. \nRemarks: ${remarks}`)
      })

    }
  })
}

// Can use this function below, OR use Expo's Push Notification Tool-> https://expo.io/notifications
export async function sendPushNotification(expoPushToken, title, body) {


  const message = {
    to: expoPushToken,
    sound: 'default',
    title: title,
    body: body,
    data: { someData: 'goes here' },
  };

  const response = await fetch('https://exp.host/--/api/v2/push/send', {
    method: 'POST',

    body: JSON.stringify(message),
  })
  .then((response) => {
  })
  .catch((error) => {
  });
}

export function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

export function valuesToFeatureCollection(items){

  var data = items.map(function(item) {
    var itemObject = {
                    "type": "Feature",
                    "properties":{
                      "_id": item.id
                    },
                    "geometry": {
                      "type": "Point",
                      "coordinates": item.coords ? item.coords : [121.099812, 14.6733431]
                    }
                  }
    return itemObject;
  })

  var dataGeoJSON = {
      "type": "FeatureCollection",
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": data
      }
  return dataGeoJSON;
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return width/height
}
