import React, { useEffect, useState, useContext } from 'react';
import { 
    Button, 
    Grid, 
    Link,
    Toolbar, 
    IconButton, 
    Typography, 
    MenuItem, 
    Menu, 
    AppBar as MuiAppBar, 
    DialogContent,
} from '@mui/material';

import { 
    Menu as MenuIcon, 
    FilterList as FilterListIcon,
} from '@mui/icons-material';

import { useHistory } from "react-router-dom";
import { themes } from '../../core/theme'
import { TourismContext } from '../../contexts/TourismContext';
import { BootstrapDialog, BootstrapDialogTitle } from '../../components/dialog';

import logo from '../../assets/lungsod_logo.png';
import Dashboard from './Dashboard';
import Summary from './Summary'
import Tours from './Tours'
import Profile from '../ProfilePage/index copy';
import Admin from '../AdminPage';

import FilterDrawer from './Dashboard/filterDrawer';
import AttractionList from './Dashboard/AttractionList';
import AttractionDetails from './Dashboard/AttractionDetails';
import AttractionDetailsNew from './Dashboard/AttractionDetailsNew';

import AttractionListTours from './Tours/AttractionList';
import AttractionDetailsTours from './Tours/AttractionDetails';
import AttractionDetailsNewTours from './Tours/AttractionDetailsNew';
import { WindowContext } from '../../contexts/WindowContext';

const styles = {
    root: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    rootMobile: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        backgroundColor: 'cyan'
    },
    menuButton: {
        color: themes.colors.poloBlue,
        height: "4vh",
        paddingInline: "2vw",
        fontSize: '2vh',
        fontFamily : "Mulish",
        textTransform: "capitalize",
        borderRadius: 0,
        borderRight: 2, 
        borderColor: "white",
        "&:hover" : {
            color: 'white'
        }
    }
}

const TourismPage = () => {
    const history = useHistory();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const pages = ['Dashboard', 'Tours', 'Summary Table', 'Profile', 'Admin'];

    const [selectedBtn, setSelectedBtn] = useState(JSON.parse(localStorage.getItem('user')).admin_verification_status === "verified"? "Dashboard": "Profile");
    const {ratio, setRatio} = useContext(WindowContext)

    const [openFilter, setOpenFilter] = useState(false)
    const [openLayer, setOpenLayer] = useState(false)
    const [openTours, setOpenTours] = useState(false)

    const { 
        selectedAttraction, setSelectedAttraction,
        newAttraction, setNewAttraction,
        selectedTour, setSelectedTour,
        newTour, setNewTour,
     } = React.useContext(TourismContext)

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleBtnClick = (page) => {
        if (page !== "Digital Twin") {
            setSelectedBtn(page)
            setMobileMoreAnchorEl(null)
        }
    };
    
    const handleLogout = () => {
        localStorage.clear()
        history.replace('/')
    };

    const getContent = (index) => {
        switch (index) {
          case 'Dashboard':
            return <Dashboard/>;
          case 'Summary Table':
            return <Summary/>;
          case 'Tours':
            return <Tours/>;
          case 'Profile':
            return <Profile/>;
          case 'Admin':
            return <Admin/>;
          default:
            return <Dashboard/>;
        }
      };

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        >
       
        {JSON.parse(localStorage.getItem('user'))?
            JSON.parse(localStorage.getItem('user')).admin_verification_status === "verified"?
                JSON.parse(localStorage.getItem('user')).user_type !== "Admin"?
                pages.slice(0,3).map((page, index) => (
                    <MenuItem key={page} onClick={()=>handleBtnClick(page)}>
                        <Typography key={page+index}>{page}</Typography>
                    </MenuItem>
                    ))
                :
                pages.map((page, index) => (
                    <MenuItem key={page+index+page} onClick={()=>handleBtnClick(page)}>
                        <Typography key={page+index+page+index}>{page}</Typography>
                    </MenuItem>
                    ))
                :
                pages.slice(2,3).map((page, index) => ( // kapag unverified
                    <MenuItem key={page} onClick={()=>handleBtnClick(page)}>
                        <Typography key={page+index}>{page}</Typography>
                    </MenuItem>
                ))
            :null // kapag walang naka-save sa localstorage
            
        }
        <MenuItem key={"Digital Twin"} onClick={()=>handleBtnClick("Digital Twin")}>
            <Link
                href={"http://lungsod.tcagp.upd.edu.ph/digitaltwin/"}
                target="_blank"
            >
                Digital Twin
            </Link>
        </MenuItem>
        <MenuItem onClick={()=>handleLogout()}><Typography>Logout</Typography></MenuItem>
        </Menu>
    );
   

    const MenuButton = (props) => {
        return(
            <Button
                sx={{...styles.menuButton, color: selectedBtn === props.name?"white":themes.colors.poloBlue }}
                name={props.name}
                onClick={props.onClick}
            >
                {props.name}
            </Button>
        )
    }

    return (
        <Grid>
            
            {/* TOP SIDE COMPONENT: HEADER */}
            {ratio>1.1?
            <MuiAppBar position="sticky">
                <Toolbar>

                    {/* MENU BAR */}
                    <Grid container style={{ flex: 9, flexDirection: 'row' }}>

                        {JSON.parse(localStorage.getItem('user'))?
                            JSON.parse(localStorage.getItem('user')).admin_verification_status === "verified"?
                                JSON.parse(localStorage.getItem('user')).user_type !== "Admin"?
                                pages.slice(0,3).map((page, index) => (
                                    <MenuButton name={page} key={page} onClick={()=>handleBtnClick(page)}/>
                                    ))
                                :
                                pages.map((page, index) => (
                                    <MenuButton name={page} key={page+index+page} onClick={()=>handleBtnClick(page)}/>
                                    ))
                                :
                                pages.slice(2,3).map((page, index) => ( // kapag unverified
                                    <MenuButton name={page} key={page} onClick={()=>handleBtnClick(page)}/>
                                ))
                            :null // kapag walang naka-save sa localstorage
                            
                        }
                         <Link
                            href={"http://lungsod.tcagp.upd.edu.ph/digitaltwin/"}
                            target="_blank"
                        >
                            <MenuButton name={"Digital Twin"} key={"Digital Twin"} onClick={()=>handleBtnClick("Digital Twin")}/>
                        </Link>
                        <MenuButton name="Logout" onClick={()=>handleLogout()} /> 

                    </Grid>

                    {/* LOGO */}
                    <Grid container style={{ flex: 1, flexDirection: 'column' }}>
                        <img src={logo} alt="LUNGSOD" style={{width: undefined, height: '5vh', aspectRatio: 1, maxHeight: '10vh'}}/>
                    </Grid>
                
                </Toolbar>
            </MuiAppBar>
            :
             <MuiAppBar position="sticky">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2, color: "white", borderStyle:"solid", borderWidth:0.5, borderRadius:1 }}
                        onClick={handleMobileMenuOpen}
                    >
                        <MenuIcon  />
                    </IconButton>
                    <img src={logo} alt="LUNGSOD" style={{width: undefined, height: '5vh', aspectRatio: 1, maxHeight: '10vh'}}/>
                    <div style={{flexGrow:1}}></div>

                    {/* Filter & layer button in appbar */}
                    {/* {openFilter || openLayer? */}
                    {selectedBtn == "Dashboard" || selectedBtn == "Summary Table" ?
                        <div style={{display: "flex", flexDirection:"row"}}>
                            <IconButton onClick={()=>setOpenFilter(!openFilter)} variant='contained' color="primary" style={{backgroundColor: themes.colors.secondary, borderRadius:5}}>
                               <FilterListIcon />
                            </IconButton>
                            <div style={{width:"2%"}}></div>
                            <Button variant='contained' color="secondary" onClick={()=>setOpenLayer(!openLayer)}>Layer</Button>
                        </div>:
                        null
                    }

                    {selectedBtn == "Tours" ?
                        <div style={{display: "flex", flexDirection:"row"}}>
                            <div style={{width:"2%"}}></div>
                            <Button variant='contained' color="secondary" onClick={()=>setOpenTours(!openTours)}>Tours</Button>
                        </div>:
                        null
                    }
                    {/* Filter & layer button in appbar */}

                    {renderMobileMenu}
                </Toolbar>
            </MuiAppBar>
            }

            {/* BOTTOM SIDE COMPONENT: CONTENT */}
            <Grid container style={{flex:1, backgroundColor:"red"}}>
              {getContent(selectedBtn)}
            </Grid>   

            {/* Filter & layer dialogbox in appbar */}
            <BootstrapDialog fullScreen open={openFilter && ratio < 1.1} onClose={()=>setOpenFilter(false)}>
                <BootstrapDialogTitle onClose={()=>setOpenFilter(false)} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    Filter
                </BootstrapDialogTitle>
               
                <DialogContent dividers style={{overflowY:"visible"}}>
                    <Grid container style={{alignSelf:"center", justifySelf:"center"}}>
                        <FilterDrawer/>
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog fullScreen open={openLayer && ratio < 1.1} onClose={()=>setOpenLayer(false)}>
                <BootstrapDialogTitle onClose={()=>setOpenLayer(false)} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    Layer
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container style={{alignSelf:"center", justifySelf:"center"}}>
                        { selectedAttraction ? <AttractionDetails/> : <AttractionList/> }
                    </Grid>
                </DialogContent>
            </BootstrapDialog>
            {/* Filter & layer dialogbox in appbar */}

            {/* Create New Attraction dialogbox in appbar */}
            <BootstrapDialog
                fullScreen={ratio>1.1?false:true}
                onClose={() => setNewAttraction(false)}
                aria-labelledby="customized-dialog-title"
                open={newAttraction}
                fullWidth
            >
                <AttractionDetailsNew onClose={() => setNewAttraction(false)}/>
            </BootstrapDialog>
            {/* Create New Attraction dialogbox in appbar */}

            {/* Tours & Create New Tour dialogbox in appbar */}
            <BootstrapDialog fullScreen open={openTours && ratio < 1.1} onClose={()=>setOpenTours(false)}>
                <BootstrapDialogTitle onClose={()=>setOpenTours(false)} style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    Tours
                </BootstrapDialogTitle>
                <DialogContent dividers >
                    <Grid container style={{alignSelf:"center", justifySelf:"center"}}>
                        { selectedTour ? <AttractionDetailsTours/> : <AttractionListTours/> }
                    </Grid>
                </DialogContent>
            </BootstrapDialog>

            <BootstrapDialog
                fullScreen={ratio>1.1?false:true}
                onClose={() => setNewTour(false)}
                aria-labelledby="customized-dialog-title"
                open={newTour}
                fullWidth
            >
                <AttractionDetailsNewTours onClose={() => setNewTour(false)}/>
            </BootstrapDialog>
            {/* Tours & Create New Tour dialogbox in appbar */}
        
        </Grid>
    );
}

export default function TourismDashboard() {
    return(<TourismPage/>)
}
