import { createTheme } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';

export const themes = {
    colors: {
        primary: '#12365F', // blue
        secondary: '#F1C644', // yellow
        tertiary: '#BA3F1D', // red
        sub1: '#CB8589', // lred
        sub2: '#DEECFA', // lblue
        sub3: '#F5F8FC', // gray
        hoverColor: '#dddddd', // gray for hover
        greyTxt: '#979797', // gray text
        sub4: '#F5F8FC', // for bg Color
        rowColor: '#DEECFA',
        poloBlue: '#80A1C1', // polo blue
        greyBorder: 'rgba(82,82,82,0.22)',
        filterText: '#666666' 
    }
}


export const generalTheme = createTheme({ // generalTheme is used to change the colors generally (for buttons, checkboxes, datagrid colors)
  palette: {
    primary: {
      main: themes.colors.primary,
      contrastText: '#fff',
    },
    secondary: {
      main: themes.colors.secondary,
    },
    text: {
      main: "#fff",
    },
  },
});