import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button, TextField, DialogContent, DialogActions, LinearProgress, InputAdornment } from '@mui/material';
import { Select, FormControl, FormHelperText, MenuItem, Divider } from '@mui/material';
import {themes} from '../../../core/theme'
import axios from 'axios';
import { BASE_URL, districtNames, emergencyCat, regExp_phoneNum } from '../../../core'
import { BootstrapDialogTitle } from '../../../components/dialog';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css";
import LCG from 'leaflet-control-geocoder';

import { MapConsumer, MapContainer, TileLayer, useMap, Marker, Tooltip } from 'react-leaflet';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { getBarangayList } from '../../../core/functions'
import { EmergencyPinUnresolvedSelected } from '../../../core/mapIcons'

const filter = createFilterOptions();

function LeafletgeoSearch() {
    const map = useMap();
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      const searchControl = new GeoSearchControl({
        provider,
        showMarker: false
      });
  
      map.addControl(searchControl);
  
      return () => map.removeControl(searchControl);
    }, []);
  
    return null;
}

const styles = {
    root: {
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
}


export default function SosDetailsNew ({onClose}) {
    "function used to create sos that are not through the application"

    const [showLP, setShowLP] = React.useState(false)

    const [nameValue, setNameValue] = React.useState({
        name: ""
    })
    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [categoryValue, setCategoryValue] = React.useState({})
    const [descValue, setDescValue] = React.useState('n/a')
    const [city, setCity] = React.useState('')
    const [district, setDistrict] = React.useState('')
    const [street, setStreet] = React.useState('')

    const [nameValueError, setNameValueError] = React.useState(false)
    const [phoneNumberError, setPhoneNumberError] = React.useState(false)
    const [categoryValueError, setCategoryValueError] = React.useState(false)
    const [descValueError, setDescValueError] = React.useState(false)
    const [districtError, setDistrictError] = React.useState(false)
   
    const mapRef = React.useRef(); // for map!
    const [searchedLoc, setSearchedLoc] = React.useState({})
    const [uniqSenders, setUniqueSenders] = React.useState([])
    const [contactData, setContactData] = React.useState({})


    const [ptcName, setPtcName] = useState({ value: '', error: '' })
    const [ptcRelationship, setPtcRelationship] = useState({ value: '', error: '' })
    const [ptcMobileNumber, setPtcMobileNumber] = useState({ value: '', error: '' })
    const [ptcEmail, setPtcEmail] = useState({ value: '', error: '' })

    const [currentBarangayList, setCurrentBarangayList] = React.useState([]);

    React.useEffect(()=>{
        const fetchData = async() => {
            const res = await axios(`${BASE_URL}/api/emergency/get/uniqueSenders`)
            .then((res)=>{
               setUniqueSenders(res.data.values)
            })
        }
        fetchData()
    }, [])


    const handleCategory = (newValue) => {
        if (newValue) {
            setCategoryValue(newValue); 
            setCategoryValueError(false)
        } else {
            setCategoryValue(''); 
            setCategoryValueError(true)
        }
    };

    const handleDesc = (event) => {
        if (event.target.value) {
            setDescValue(event.target.value); 
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }
    };

    const handleCity = (event) => {
        if (event.target.value) {
            setCity(event.target.value); 
        } else {
        }
    };

    const handleStreet = (event) => {
        if (event.target.value) {
            setStreet(event.target.value); 
        } else {
        }
    };

    const handleDistrict = (event) => {
        setCurrentBarangayList(getBarangayList(event.target.value))
        setDistrict(event.target.value)
        if (event.target.value) {
            setDistrictError(false)
        } else {
            setDistrictError(true)
        }
    }

    const createCommandUserLog = (action, report, model_edits) => {
        var par  = {
            "user_id": JSON.parse(localStorage.getItem('user')).user_id,
            "model_name": report.hasOwnProperty('method')?"Emergency":"Report",
            "model_id": report.id,
            "model_reference_no": report.reference_no,
            "model_edits": model_edits.length>0?model_edits:[],
            "action": action
        }
    
        const res = axios.post(`${BASE_URL}/api/logs/create/commanduser`, par)
        .then((res)=> {
            console.log("createCommandUserLog", res.data.message)
    
        })
    }
    
    const handleSave = () => {
        var formData
        const fetchData = async() => {
            const res = await axios.post(`${BASE_URL}/api/emergency/create`, formData)
            .then((res)=>{
                console.log("handleSave res", res.data)
                setShowLP(false)
                onClose()
                var model_edits = []
                model_edits.push(['method', 'Manual'])
                createCommandUserLog("created", res.data.values[0], model_edits)


            })
            .catch((error)=> {
                setShowLP(false)
            })
        }

        if (nameValue.name) {
            setNameValueError(false)
        } else {
            setNameValueError(true)
        }

        setPhoneNumberError(!regExp_phoneNum.test(phoneNumber))

        if (Object.keys(categoryValue).length>0) {
            setCategoryValueError(false)
        } else {
            setCategoryValueError(true)
        }

        if (descValue) {
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }

        if (district) {
            setDistrictError(false)
        } else {
            setDistrictError(true)
        }

        var newDate = new Date();
        var timeText = (newDate.getTime()/1000).toString()
        
        formData = {
            "name": nameValue.name,
            "username": "",
            "user_id": "",
            "category": categoryValue.label,
            "date": timeText,
            "status": "Unresolved",
            "type": "Point",
            "latitude":  Number(searchedLoc.y),
            "longitude":  Number(searchedLoc.x),
            "address": searchedLoc.label,
            "street": street,
            "district": district,
            "city": city,
            "method": "Manual",
            "contact_no": phoneNumber,
          }

        if (
            nameValue.name && !nameValueError  &&
            Object.keys(categoryValue).length > 0 &&
            descValue && Object.keys(searchedLoc).length > 0 &&
            regExp_phoneNum.test(phoneNumber)
            ) {
            var upload = window.confirm("Are you sure you want to record this emergency?")
            
            if (upload) {
                fetchData()
                setShowLP(true)
            }
        }  else {
        }
    }

    const getEmergencyContactById = async (id) => {
            const res = await axios.get(`${BASE_URL}/api/emergency/contact/get`, { params: {"user_id": id}})
            .then((res)=>{
                if (res.data) {
                    setContactData(res.data)
                }
            })
    }

    const [addNew, setAddNew] = React.useState(false)
    const geocoder = LCG.nominatim();
    return (
        <React.Fragment>
            <BootstrapDialogTitle  onClose={onClose}>Record an Emergency </BootstrapDialogTitle>
            {showLP?<LinearProgress/>:null}
            
            <DialogContent dividers>
                <Grid container style={{ minHeight: 'fit-content' }}>

                    <Grid container style={{width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Name:`}</Typography>
                                <Autocomplete
                                    disablePortal
                                    fullWidth
                                    id="combo-box-demo"
                                    options={uniqSenders}
                                    style={{maxWidth: "60%", textAlign: "right"}}
                                    onChange={(event, newValue, reason) => {
                                        if (typeof newValue === 'string') {
                                            setNameValue({
                                              name: newValue,
                                            });
                                            setNameValueError(false)

                                          } else if (newValue && newValue.inputValue) {
                                            setNameValue({
                                                name: newValue.name
                                            });
                                            if (newValue.name) {
                                                setNameValueError(false)
                                            } else {
                                                setNameValueError(true)
                                            }

                                          } else {
                                            if (!newValue) {
                                                setNameValue({
                                                    name: "",
                                                });
                                                setNameValueError(true)
                                                setContactData({})
                                                setPhoneNumber('')
                                                setPhoneNumberError(true)

                                            } else {
                                                setNameValue({
                                                    name: newValue.name,
                                                });
                                                setNameValueError(false)
                                                setPhoneNumber(newValue.contact_no)
                                                setPhoneNumberError(!regExp_phoneNum.test(newValue.contact_no))
                                                if (newValue.user_id !== "") {
                                                    getEmergencyContactById(newValue.user_id)
                                                } else {
                                                    setContactData({})
                                                }
                                            }
                                          }
                                        
                                       
                                    }}
                                    renderInput={(params) => 
                                        <TextField {...params} 
                                            error={nameValueError}
                                            helperText={nameValueError? "This is a required field. Include first and last name.": ""}
                                        />
                                    }
                                    getOptionLabel={(option) => {

                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }

                                        if (typeof option === 'object') {
                                            return option.name
                                        }

                                        return option
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                
                                        const { inputValue } = params;
                                        const label = options.name

                                        const isExisting = options.some((option) => inputValue === label);
                                        if (inputValue !== '' && !isExisting) {
                                          filtered.push({
                                            inputValue,
                                            name: inputValue
                                          });
                                        }
                                
                                        return filtered;
                                    }}
                                    freeSolo
                                />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Contact No.:`}</Typography>
                            <TextField 
                                style={{backgroundColor:"white", borderRadius:5, maxWidth: "60%", textAlign: "right"}}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                                }}
                                error={phoneNumberError} 
                                helperText={phoneNumberError? "Invalid number.":null}
                                 value={phoneNumber} 
                                onChange={(e)=> {
                                    setPhoneNumberError(!regExp_phoneNum.test(e.target.value))
                                    setPhoneNumber(e.target.value)
                                }}
                                required fullWidth name="contact_no" autoComplete="number" id="number" 
                            />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Person to Contact:`}</Typography>
                            {Object.keys(contactData).length>1?
                            <Grid container style={{padding: "2%"}}>
                                <Grid item xs={5}><Typography>Name: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography>{contactData.contact_name}</Typography>
                                </Grid>
                               
                                <Grid item xs={5}><Typography>Relationship: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography>{contactData.contact_relationship}</Typography>
                                </Grid>
                                <Grid item xs={5}><Typography>Email: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography>{contactData.contact_email}</Typography>
                                </Grid>
                                <Grid item xs={5}><Typography>No.: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography>{contactData.contact_number}</Typography>
                                </Grid>
                            </Grid>
                            :
                            <React.Fragment>
                                <Grid container style={{padding: "2%"}} spacing={1}>
                                    {addNew?
                                    <>
                                        <Grid item xs={5}><Typography>Name: </Typography></Grid>
                                        <Grid item xs={7}>
                                            <TextField size="small" fullWidth>{contactData.contact_name}</TextField>
                                        </Grid>
                                       
                                        <Grid item xs={5}><Typography>Relationship: </Typography></Grid>
                                        <Grid item xs={7}>
                                            <TextField size="small" fullWidth>{contactData.contact_relationship}</TextField>
                                        </Grid>
                                        <Grid item xs={5}><Typography>Email: </Typography></Grid>
                                        <Grid item xs={7}>
                                            <TextField size="small" fullWidth>{contactData.contact_email}</TextField>
                                        </Grid>
                                        <Grid item xs={5}><Typography>No.: </Typography></Grid>
                                        <Grid item xs={7}>
                                            <TextField size="small" fullWidth>{contactData.contact_number}</TextField>
                                        </Grid>
                                    </>
                                    :<Typography>{contactData.message}</Typography>}
                                </Grid>
                            </React.Fragment>
                            }
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Category:`}</Typography>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                id="combo-box-demo"
                                options={emergencyCat}
                                style={{maxWidth: "60%", textAlign: "right"}}
                                onChange={(event, newValue, reason) => {
                                    handleCategory(newValue)
                                }}
                                renderInput={(params) => 
                                    <TextField {...params} 
                                        error={categoryValueError}
                                        helperText={categoryValueError? "This is a required field": ""}
                                    />
                                }
                                />
                                {categoryValueError}
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Description:`}</Typography>
                            <TextField 
                                disabled={showLP}
                                required
                                fullWidth
                                style={{maxWidth: "60%"}}
                                multiline
                                rows={3}
                                onChange={handleDesc}
                                error={descValueError}
                                helperText={descValueError? "This is a required field": ""}
                                value={descValue}
                            />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "10%"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`City:`}</Typography>
                            <TextField 
                                size="small"
                                disabled={showLP}
                                required
                                fullWidth
                                style={{maxWidth: "60%"}}
                                onChange={handleCity}
                            />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>District: </Typography>
                            <FormControl fullWidth size="small" required 
                                error={districtError?true:false}
                                style={{maxWidth: "60%"}}
                            >
                                <Select
                                    style={{backgroundColor:"white", borderRadius:5}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={handleDistrict} 
                                    name="district"
                                >
                                    <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                                    {districtNames.map((name)=> {
                                        return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                                    })}
                                </Select>
                                <FormHelperText>{districtError?"This is a required field":""}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Street:`}</Typography>
                            <TextField 
                                disabled={showLP}
                                required
                                fullWidth
                                style={{maxWidth: "60%"}}
                                size="small"
                                multiline
                                onChange={handleStreet}
                            />
                        </Grid>
                        
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Location:`}</Typography>
                            <TextField 
                                editable={false}
                                disabled
                                color={searchedLoc.label?"primary":"error"}
                                required
                                fullWidth
                                style={{maxWidth: "60%"}}
                                multiline
                                
                                inputProps={{ style: { color:searchedLoc.label? themes.colors.primary: themes.colors.tertiary } }}
                                value={searchedLoc.label?searchedLoc.label:"Please search a location on the map."}
                            />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%", borderColor:"red", borderStyle: "solid", borderWidth: searchedLoc.y?0:1, borderRadius: 5 }}>
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Search a Location on the Map:`}</Typography>
                            <MapContainer
                                whenCreated={ mapInstance => { 
                                    mapRef.current = mapInstance 
                                    mapRef.current.on('geosearch/showlocation', (result)=>setSearchedLoc(result.location));

                                }}
                            center={[10.7232, 122.5621]} zoom={13} scrollWheelZoom={true} style={{height: 300, width: '100%'}} >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <LeafletgeoSearch/>

                                {Object.keys(searchedLoc).length>0?
                                    <Marker
                                    icon={EmergencyPinUnresolvedSelected}
                                    key={"searched-location"}
                                    position={[searchedLoc.y, searchedLoc.x]}
                                    draggable
                                    eventHandlers={{
                                        dragend: (e) => {
                                            geocoder.reverse(e.target._latlng, mapRef.current.options.crs.scale(mapRef.current.getZoom()), function(results) {
                                                var r = results[0];
                                                setSearchedLoc({
                                                    label: r.name,
                                                    y: e.target._latlng.lat,
                                                    x: e.target._latlng.lng
                                                })

                                            });
                                        }
                                    }}
                                    >
                                        {searchedLoc.label? <Tooltip direction="top">{searchedLoc.label}</Tooltip>: null}
                                    </Marker>
                                :null}
                            </MapContainer>
                            {searchedLoc.y?
                                <Typography>[lat: {Math.round(searchedLoc.y*1000)/1000}, lon: {Math.round(searchedLoc.x*1000)/1000}]</Typography>
                                : null
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
           
        </React.Fragment>
    );
}

