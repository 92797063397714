import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, Button, Avatar, Fab, Badge } from '@mui/material'
import { adminVerificationStatusFormatter, stringAvatar } from '../../core/functions'
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material';
import * as Constants from '../../core'
import axios from 'axios';

export default function ProfilePicture({user}) {

    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null)
    const [userDisplayPhotoFinal, setUserDisplayPhotoFinal] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    
    const formData2 = {
        "tag_id": JSON.parse(localStorage.getItem('user')).user_id,
        "tag": "command_user_dp",
    }

    const addNewPhoto = e => {
        e.preventDefault();
        setSelectedFile(e.target.files[0]);
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            setPreview(reader.result); // for preview only
        });
        reader.readAsDataURL(e.target.files[0])
    };

    const uploadImage = async (e, id) => {
        e.preventDefault()
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
              }
          };
       
        localStorage.setItem('userDisplayPhotoFinal', preview)

        formData.append("img", selectedFile)
        formData.append("tag", "command_user_dp")
        formData.append("tag_id", id)
        await axios.post(`${Constants.BASE_URL}/api/image/create`, formData, config)
        .then((res) => {
            setSelectedFile(null)
        })
    }
    
    const fetchData = async (formData2) => {
        const res2 = await axios.post(`${Constants.BASE_URL}/api/image/get`, formData2)
        .then((res2)=>{
            if (res2.data.count>1){
                var index = res2.data.count-1
                var userDisplayPhoto = res2.data.values[index]['img']
                setUserDisplayPhotoFinal(userDisplayPhoto)
                localStorage.setItem("userDisplayPhotoFinal", userDisplayPhoto);
                
            } else {
                if (res2.data.values) {
                    var userDisplayPhoto = res2.data.values[0]['img']
                    setUserDisplayPhotoFinal(userDisplayPhoto)
                    localStorage.setItem("userDisplayPhotoFinal", userDisplayPhoto);
                }
            }
        })
    }

    useEffect(() => {
        if (localStorage.getItem('userDisplayPhotoFinal')) {
            var userDisplayPhoto = localStorage.getItem('userDisplayPhotoFinal')
            setUserDisplayPhotoFinal(userDisplayPhoto)
        } else {
            fetchData(formData2)
        }
    }, [localStorage.getItem('userDisplayPhotoFinal')])

    return (
        <Container 
            style={{width:"100%", paddingTop:20, paddingBottom:20}}
        >
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <Fab 
                        onClick={()=>{setIsEditing(!isEditing); fetchData(formData2)}} 
                        aria-label="edit" size="small">
                        {isEditing?<CloseIcon/>:<EditIcon/>}
                    </Fab>
                }
            >
                 { 
                    isEditing && preview? <Avatar style={{height:150, width: 150}} src={preview}/>:
                    userDisplayPhotoFinal? 
                    <Avatar style={{height:150, width: 150}} src={userDisplayPhotoFinal}/>
                        :
                        <Avatar style={{height:150, width: 150,}} {...stringAvatar(`${user.first_name} ${user.last_name}`)}/>
                }
            </Badge>
            <br/>
           
            {isEditing && 
                <React.Fragment>
                    <br/>
                    <Button variant="outlined" component="label" fullWidth style={{textTransform: 'none', width:150}}> Add new photo
                        <input name="gov_id_img" onChange={addNewPhoto} hidden type="file" accept="image/x-png,image/gif,image/jpeg"/>
                    </Button>
                    <br/>
                </React.Fragment>
            }

            {isEditing && selectedFile?
                <Grid container direction="row" justifyContent="space-between" style={{padding:10}}>
                    <Button 
                        style={{width:"48%"}}   
                        size="small" 
                        variant="contained" 
                        onClick={(e)=>uploadImage(e, JSON.parse(localStorage.getItem('user')).user_id)}
                    >
                        Save
                    </Button>
                    <Button 
                        style={{width:"48%"}} 
                        size="small" 
                        variant="outlined" 
                        onClick={()=>{setPreview(null);setSelectedFile(null)}}
                    >
                        Undo
                    </Button>
                </Grid>
                :
                null
            }

            <br/>
            
            <Typography component="div" gutterBottom textAlign="center">Role: {user.user_type}</Typography>
            <Typography component="div" gutterBottom textAlign="center">{adminVerificationStatusFormatter(user.admin_verification_status)}</Typography>
            <br/>
        </Container>
    )}
