import React, { useContext, useEffect, useState, useRef } from 'react';
import { Button, Grid, Typography, Paper, TextField } from '@mui/material';
import { FixedSizeList } from 'react-window';

import axios from 'axios';
import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';
import ReportCardCopy from './RaiListCard.js';
import NotificationSound from '../../../assets/notification-sound.wav'

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    },
}


export default function RaiList () {

    var dateToday = new Date().toDateString().replace(/ /,', ') 
    const [searchVal, setSearchVal] = useState('')
    const { reportsAll, setReportsAll,
        checkReports, setCheckReports,
        newReports, setNewReports,
        reportsShow, setReportsShow,
        reportsShow2, setReportsShow2,
        
    } = useContext(RaiContext)

    const handleNewReport = async () => {
        setCheckReports({count:0, values: []})
        fetchReports()
    }

    // start: for new reports (RAI)
    const fetchReports = async () => {
        var newArr 
        var newCount 
        var newArr2
        var newCount2 
        const res = await axios.get(`${Constants.BASE_URL}/api/reports/get/new`, { params: {
            "id": JSON.parse(localStorage.getItem('user')).user_id,
        }})
        .then((res)=> {
        if (res.data.count>0) {
            newArr = res.data.values.concat(reportsAll.values)
            newCount = res.data.count + reportsAll.count
            setReportsAll({ // kapag may new report
                count: newCount,
                values: newArr
            })

            if (newReports.count>0) {
                newArr2 = res.data.values.concat(newReports.values)
                newCount2 = res.data.count + newReports.count
                setNewReports({
                    count: newCount2,
                    values: newArr2
                })
            } else {
                setNewReports(res.data)
            }
        } else {
            setNewReports({count:0, values: []})
        }
        })
    }
    // end: for new reports (RAI)

    const Summary = () => {
        return (
            <Grid>
                <Typography sx={styles.text}>
                {'Showing: '}{ 
                    reportsShow2? reportsShow2.count:
                    reportsShow? reportsShow.count:
                        " . . ."} results as of {dateToday}
                </Typography>
                
            </Grid>
        )
    }

    React.useEffect(()=>{

        if (reportsShow && reportsShow.values  && searchVal !== "") {
            var filtered2 = reportsShow.values.filter(item => item.reference_no.toLowerCase().includes(searchVal.toLowerCase()) || item.name.toLowerCase().includes(searchVal.toLowerCase()));

            setReportsShow2({
                count: filtered2.length,
                values: filtered2
            })
           
        } else {
            setReportsShow2()
        }
    }, [reportsShow, searchVal])

    const searchFilter = (event) => {
        var text = event.target.value
        setSearchVal(text)
    }

    const audioPlayer = useRef(null);

    function playAudio() {
      audioPlayer.current.play()
      .catch(error=>{
        console.log("error in autoplay", error)
      })
    }

    useEffect(()=>{
        if (checkReports.count>0) {
            playAudio()
        }

    }, [checkReports.count])
    
    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                height: "100%",
                width: "100%",
                backgroundColor: themes.colors.sub3,
                maxHeight: "90vh",
                marginBottom: 20
                }}
            >
                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Summary/>
                </Grid> 
                <TextField id="outlined-basic" label="Search by ID/ Name" variant="outlined" fullWidth style={{marginTop:"2vh"}}
                    onChange = {text => searchFilter(text)}/>

                <Grid container style={{ height: '90%', width: '100%', paddingTop: '1vh', overflowY: "clip" }}>
                    {/* NOTIFICATIONS FOR NEW EMERGENCIES AND REPORTS */} 
                    {checkReports.count>0?<Button style={{height: '5vh', marginBottom:10}} onClick={handleNewReport} fullWidth variant="contained">Show {checkReports.count} new {checkReports.count > 1? 'reports': 'report'}</Button>:null}
                    {checkReports.count>0?<audio ref={audioPlayer} src={NotificationSound}/>:null}

                    {
                        (reportsShow2 && reportsShow2.values)?
                        <FixedSizeList
                            height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                            itemSize={200}
                            width={"100%"}
                            itemCount={ 
                                (reportsShow2 && reportsShow2.values)? 
                                reportsShow2.count
                                :null
                            }
                            itemData={
                                (reportsShow2 && reportsShow2.values)? 
                                reportsShow2.values
                                :null
                            }
                        >
                            {ReportCardCopy}
                            
                        </FixedSizeList>
                
                        : 
                         (reportsShow && reportsShow.values)?
                        <FixedSizeList
                            height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                            itemSize={200}
                            width={"100%"}
                            itemCount={ 
                                (reportsShow && reportsShow.values)? 
                                reportsShow.count
                                :null
                            }
                            itemData={
                                (reportsShow && reportsShow.values)? 
                                reportsShow.values
                                :null
                            }
                        >
                            {ReportCardCopy}
                            
                        </FixedSizeList>
                        :null
                    }
                    
                </Grid>
            </Paper>
        </Grid>
    );
}
