import React, { useContext, useEffect, useState } from 'react';
import { 
    Button, Typography, Paper,
} from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { djangoToJsDate } from '../../../core/functions';


const styles = {
    oddRow: {
        backgroundColor: "#ffffff",
    }
}


export const Logs = ({rows}) => {
    const [seeMore, setSeeMore] = useState(false)

    const ActionsTable = ({row}) => {
        return (
            <table style={{width:"100%", backgroundColor:"#eee"}}>
                <tbody>
                    <tr >
                        <th>#</th>
                        <th>Field</th>
                        <th >To</th>
                    </tr>
                    {row.model_edits.map((i, index)=> {
                        return (
                            <tr style={index%2==0?styles.oddRow:null}>
                                <td style={{fontSize: 10}}>{index+1}</td>
                                <td style={{fontSize: 10}}>{i[0][0].toUpperCase()+i[0].substring(1)}</td>
                                <td style={{wordBreak: "break-all", fontSize: 10, minWidth: 100}}>{i[1]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    }

    return (
        <Paper elevation={3} style={{width:"100%"}}>
            <TableContainer component={Paper} style={{maxWidth: 325, minWidth: '100%'}}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 900}} >ACTION</TableCell>
                            <TableCell style={{fontWeight: 900}} align="right">DONE BY</TableCell>
                            <TableCell style={{fontWeight: 900}} align="right">TIMESTAMP</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows && rows.length>0?
                        seeMore?
                        rows.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{
                                    row.action.toUpperCase()==="EDIT" && row.model_edits.length>0?
                                        <>
                                            <Typography style={{fontWeight:700}}>{row.action.toUpperCase()}</Typography><br/>
                                            <ActionsTable row={row}/>
                                        </>
                                    :<Typography style={{fontWeight:700}}>{row.action.toUpperCase()}</Typography>}
                                </TableCell>
                                <TableCell align="right">{row.username}</TableCell>
                                <TableCell align="right">{djangoToJsDate(row.date)[0]+', '+ djangoToJsDate(row.date)[1]}</TableCell>
                            </TableRow>
                            ))
                        :
                        rows.slice(0,3).map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{
                                    row.action.toUpperCase()==="EDIT" && row.model_edits.length>0?
                                        <>
                                            <Typography style={{fontWeight:700}}>{row.action.toUpperCase()}</Typography><br/>
                                            <ActionsTable row={row}/>
                                        </>
                                    :<Typography style={{fontWeight:700}}>{row.action.toUpperCase()}</Typography>}
                                </TableCell>
                                <TableCell align="right">{row.username}</TableCell>
                                <TableCell align="right">{djangoToJsDate(row.date)[0]+', '+ djangoToJsDate(row.date)[1]}</TableCell>
                            </TableRow>
                        )):
                        <TableRow>
                            <TableCell align="right" sx={{padding: '2%'}}> No action made yet.</TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>

                {rows && rows.length>3?
                    <Button variant="text" onClick={()=>{setSeeMore(!seeMore)}} fullWidth style={{textTransform: 'none',}}>
                        {seeMore?"Hide":"See more"}
                    </Button>
                : null} 
            </TableContainer>
        </Paper>
    )
}