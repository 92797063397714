export default{
  "user_id": "bfe1e738-e8d6-484f-be6a-c681656f8d81",
  "user_type": null,
  "first_name": "Juan",
  "middle_name": "dela",
  "last_name": "Cruz",
  "birthday": "1999-01-01",
  "email": "jdcruz@albertflorin.anonaddy.com",
  "phone_number": "9324701101",
  "street_address": "Lot.8, Blk.23 Mansanas St, Mandurriao, Iloilo City, Iloilo",
  "barangay": "Arsenal Aduana",
  "district": "Iloilo City Proper",
  "city": "Iloilo City",
  "zip_code": "5000",
  "country": "Philippines",
  "username": "jdcruz",
  "password": "0b806398cef1de7d896c3527f7921b24882dc22a76d05e49d942da01f8449f63",
  "verification_status": "unverified",
  "date_created": "2024-08-06T11:31:52.037446",
  "new_reports": [],
  "new_emergency": [],
  "new_calls": [],
  "department_office": "CDRRMO",
  "position": "Numbah 1",
  "gov_id_type": "Super ID",
  "gov_id_no": "1",
  "admin_verification_status": "verified"
}
