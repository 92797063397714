import React, { useState } from 'react';
import { Button, Grid, Typography, Paper, Container} from '@mui/material';

import { themes } from '../../core/theme'
import logo from '../../assets/lungsod_logo.png';

const styles = {
    root: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    rootMobile: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        backgroundColor: 'cyan'
    },
    menuButton: {
        color: themes.colors.poloBlue,
        height: "4vh",
        paddingInline: "2vw",
        fontSize: '2vh',
        fontFamily : "Mulish",
        textTransform: "capitalize",
        borderRadius: 0,
        borderRight: 2, 
        borderColor: "white",
        "&:hover" : {
            color: 'white'
        }
    }
}

const PrivacyPolicy = () => {
    const [selectedBtn, setSelectedBtn] = useState(null);

    const MenuButton = (props) => {
        return(
            <Button
                sx={{...styles.menuButton, color: selectedBtn === props.name?"white":themes.colors.poloBlue }}
                name={props.name}
                onClick={props.onClick}
            >
                {props.name}
            </Button>
        )
    }

    
    return (
        <Grid container sx={styles.root}>
            
            <Grid container style={{ flex: 1, maxHeight: '7vh', backgroundColor: themes.colors.primary, alignItems: 'center', justifyContent: 'start', paddingInline: '1vw' }}>
                
                <Grid container style={{ flex: 9, flexDirection: 'row' }}>
                    <MenuButton name="Privacy Policy " onClick={() => setSelectedBtn("Dashboard")}/>
                </Grid>

                <Grid container style={{ flex: 1, flexDirection: 'column' }}>
                    <img src={logo} alt="LUNGSOD" style={{width: undefined, height: '5vh', aspectRatio: 1, maxHeight: '10vh'}}/>
                </Grid>
                
            </Grid>

            <Grid container style={{ flex: 9, flexDirection: 'column', maxHeight: '93vh' }}>
                <Paper style={{width: '100%', height: '100%', overflowX: 'hidden', overflowY: 'scroll', paddingBottom: 20 }}>
                    <Container>
                        <br/>
                        <Typography style={{fontSize:40, textAlign:"center", fontWeight:1000}}>
                        Privacy Policy
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"center", fontWeight:300}}>
                        for LUNGSOD CITY CONNECT by PROJECT LUNGSOD
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:24, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Last Updated June 25, 2022
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Introduction
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Project LUNGSOD respects all of its users' individual privacy and protects any personal information that is shared in the City Connect Mobile Application. Security of the individual's right to privacy is ensured with the utmost confidentiality in processing any of the user's personal information. 
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Furthermore, Project LUNGSOD strives to comply with the Data Privacy Act of 2012 (RA 10173) implemented to protect the privacy of any user using the developed system. The parties intend to adhere to all the principles set forth in this Privacy Policy and recognize the need for appropriate protection and management of any personal information. 
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The goal is to provide protection for all users’ privacy regardless of what type of device is used to access Our Services. By using Our Services, the User consents to the collection, storage, processing, transferring, disclosure, and other usages of the information described in this Privacy Policy as well as agrees to its Terms.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Terms
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The parties (<b>“Team”, “We”, “Our”, “Us”</b>), indicated in this Privacy Policy, refer to Project LUNGSOD (<b>"Developer"</b>), the main developer, and to the Iloilo City Government (<b>“ICG”</b>), the main agency in sustaining and maintaining the City Connect Mobile Application (<b>“App”</b>);
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The USER (<b>“You”, “Users”</b>), indicated in this Privacy Policy (<b>“Policy”</b>), refers to a resident/visitor of consenting age that signed up and is currently enrolled in the Users database of the App;
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The <b>COLLABORATORS</b>, indicated in this Privacy Policy, refer to secured partnerships acquired during the course of the development of the City Connect Mobile Application. Third parties as of the latest release of this Privacy Policy include Youth First Initiative PH - for their e-commerce app, “Bakal Lokal” and Innovate Iloilo. Any additions to the list of third parties shall be included in the updated Policy
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The <b>THIRD PARTIES</b>, indicated in this Privacy Policy, refer to entities that offer services on their own platforms and are included in the App through rendering features (ex. React Native Web View) to improve the App. The Team does not claim ownership of the services of Third Parties and does not have control over their Services or data collected therein. Third parties as of the latest release of this Privacy Policy include the <a href="https://www.sss.gov.ph/">Republic of the Philippines Social Security System</a>, <a href="https://www.philhealth.gov.ph/">Philippine Health Insurance Corporation</a>, <a href="https://www.pagibigfundservices.com/virtualpagibig/">PAG-IBIG</a>, <a href="http://nbi.gov.ph/">National Bureau of Investigation</a>, and <a href="https://www.google.com/maps">Google Maps</a>. Any additions to the list of third parties shall be included in the updated Policy.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The <b>Services</b>, indicated in this Privacy Policy, refer to all kinds of assistance available in the City Connect Mobile Application. These include features that may ask for personal information, interests, feedback, and the response of users to modules included in the Mobile Application including but not limited to Emergency (SOS) feature, City Hall Services, E-Government, Report an Issue, Health, Mobility, and Tourism.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        What information do we collect?
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:16, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Personal information you disclose to us
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We collect personal information that you voluntarily provide to us when registering at the App, expressing an interest in obtaining information about us or our products and services, when participating in activities on the App (such as updating your profile information) or otherwise contacting us.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The personal information that we collect depends on the context of your interactions with us and the app, the choices you make and the products and features you use. The personal information we collect can include the following:
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Publicly Available Personal Information.</b> We collect first name, last name, and nickname; birthday, email, phone number, address; and other similar data.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The Services, indicated in this Privacy Policy, refer to all kinds of assistance available in the City Connect Mobile Application. These include features that may ask for personal information, interests, feedback, and the response of users to modules included in the Mobile Application including but not limited to Emergency (SOS) feature, City Hall Services, E-Government, Report an Issue, Health, Mobility, and Tourism.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:16, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Information automatically collected
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We automatically collect certain information when you visit, use or navigate the App. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your device characteristics, country, location, information about how and when you use our App and other technical information. This information is primarily needed to maintain the security and operation of our App, and for our internal analytics and reporting purposes.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Like many businesses, we also collect information through cookies and similar technologies.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:16, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Information collected through Our App
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        If you use our App, we may also collect the following information:
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Geo-Location Information.</b> We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Mobile Device Access.</b> We may request access or permission to certain features from your mobile device, including your contacts, sms messages, storage, and other features. These include media attachments for sending reviews and reports in the App. If you wish to change our access or permissions, you may do so in your device's settings.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Mobile Device Data.</b> We may automatically collect device information (such as your mobile device ID), operating system, and version information.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Push Notifications.</b> We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
                        </Typography>  
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        What if you don't let us collect certain information?
                        </Typography>
                        {/* <br/> */}
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Some information is required for our Services to work. Other information is optional, but without it, the quality of your experience might be affected.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Where do we store your information?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Your data may be stored in servers, systems, or devices owned, operated, or managed by Project LUNGSOD and the Iloilo City Government.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        You may withdraw your consent to the use and disclosure of Data by us with reasonable notice; however, doing so may prevent proper Use of City Connect, or any part thereof and may also result in the cessation of your use of the Application, or any part thereof. We may retain, use, disclose and process your Data for any of the purposes set out herein where permitted under any applicable law.
                        </Typography> 
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        How do we use your information?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We use personal information collected via our App for a variety of purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We use the information we collect or receive:
                        <ul>
                            <li>To deliver feedback and response to services the user currently reported;</li>
                            <li>To prevent or investigate any fraud, unlawful activity or omission, or misconduct via the use of your Account, whether or not there is any suspicion of the aforementioned;</li>
                            <li>To deal with conflict of interests or investigate complaints in relation to your Account;</li>
                            <li>To follow safety, security, public service, or legal requirements and processes;</li>
                            <li>To communicate all relevant information, data, and services that are available at the Iloilo City Government and the user may benefit from;</li>
                            <li>To engage in data mining and build-up activities;</li>
                            <li>To identify, prevent, and correct errors and inefficiencies due to misuse of the platform;</li>
                            <li>To help customize user experience;</li>
                            <li>To provide a better customer experience to the citizens and identify, improve and develop more efficient services that the ICG has recognized;</li>
                            <li>To perform statistical and analytical research and analyses about user's interest and the services he/she acquired provided by the App; and</li>
                            <li>To enforce terms and conditions of the App;</li>
                        </ul>
                        </Typography> 
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Will your information be shared with anyone?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We may process or share data based on the following legal basis:
                        <ul>
                            <li><b>Consent:</b> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</li>
                            <li><b>Legitimate Interests:</b> We may process your data when it is reasonably necessary to achieve our legitimate interests.</li>
                            <li><b>Performance of a Contract:</b> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                            <li><b>Legal Obligations:</b> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                            <li><b>Vital Interests:</b> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
                        </ul>
                        </Typography><br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        More specifically, we may need to process your data or share your personal information in the following situations:
                        <ul>
                            <li><b>Vendors, Consultants and Other Third-Party Service Providers.</b> We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the App, which will enable them to collect data about how you interact with the App over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.</li>
                            <li><b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li><b>Affiliates.</b> We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.</li>
                        </ul>
                        </Typography>  
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Do we use Google Maps?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        This mobile application uses Google Maps APIs. You may find the Google Maps APIs Terms of Service <a href="https://cloud.google.com/maps-platform/terms/">here</a>. To better understand Google's Privacy Policy, please refer to this <a href="https://policies.google.com/privacy">link</a>.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        By using our Maps API Implementation, you agree to be bound by Google's Terms of Service. By using our implementation of the Google Maps APIs, you agree to allow us to gain access to information about you including personally identifiable information (such as usernames) and non-personally identifiable information (such as location). We will be collecting the following information:
                        <ul>
                            <li><b>geolocation.</b></li>
                        </ul>
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The App also uses Google Maps rendered in a Web View. This is used for basic searching of Points of Interest and Directions. The Team does not claim ownership for any Services created or owned by Google.
                        </Typography> 
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        For a full list of what we use information for, please see the previous section titled "How do we use your information" and "Will your information be shared with anyone?". You agree to allow us to obtain or cache your location. You may revoke your consent at any time. We use information about location in conjunction with data from other data providers.
                        </Typography>  
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        How do we keep your information safe?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Project LUNGSOD and the Iloilo City Government are committed to ensuring that all information shared in the City Connect Mobile Application is secured. By doing so, the City Government organized physical, electronic, and managerial procedures to secure the data that the system collects.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Do we collect information from minors?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the App, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the App. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at projectlungsod.upd@up.edu.ph.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        What are the limits for using your information and how long do we keep your information?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Project LUNGSOD and the Iloilo City Government recognize the purposes for which the data indicated in the Information Collection clause of this Privacy Policy is being collected. This personal information shall and will only be limited to the purposes identified by the parties (See Purpose of Collection). Unless the user consents or the parties are required by law, the information given will only be used for the purposes for which it was collected. In the event that the user’s personal data will be used for another purpose, later on, the parties shall seek the user's further legal permission or consent; except where the other purpose is compatible with the original purpose. All data will be kept and stored as long as required to fulfill those purposes and as long as necessary to comply with legal obligations, resolve disputes and enforce this agreement.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        What are your privacy rights? (RA 10173 Data Privacy Act of 2012)
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to access.</b> In certain circumstances, users may have the right to access their personal data upon demand with data to be provided in a structured, machine-readable, and commonly used format.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to erasure.</b> In the event of a possible modification of data, the user has also the right to correct or revise the personal data that the system holds.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to object.</b> Users may also have the right to request to stop processing their personal data.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to restrict processing.</b> Users may also have the right to restrict the processing of their personal data in certain circumstances.
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to full transparency.</b> Information on data processing such as the date when his or her personal information was last accessed and modified, with the designation, identity, and address of the personal information controller, can be accessed by the user or upon their request. 
                        </Typography>
                        <br/>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        <b>Right to submit a complaint.</b> All users have the right to submit a complaint before the National Privacy Commission or a data protection authority about the parties’ method of collection and use of personal data gathered.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        Do we make updates to this Privacy Policy?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        The Team may amend this statement at any time by posting a new version and/or whenever there are updates on the City Connect Mobile Application. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. It is the user’s responsibility to review this statement periodically as their continued use of the products and Services represents the user’s agreement with the then-current statement. 
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        How can you contact us about this Policy?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        If you have any questions about our Privacy Policy, you can contact us at projectlungsod.upd@up.edu.ph.
                        </Typography>
                    </Container>

                    <Container>
                        <br/>
                        <Typography style={{fontSize:18, textAlign:"left", fontWeight:500, color: themes.colors.primary}}>
                        How can you review, update, or delete the data we collect from you?
                        </Typography>
                        <Typography style={{fontSize:12, textAlign:"justify"}}>
                        Based on the laws of some countries, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request by sending an email to projectlungsod.upd@up.edu.ph. We will respond to your request within 30 days.
                        </Typography>
                    </Container>
                </Paper>
                
            </Grid>   
        
        </Grid>
    );
}

export default function PrivacyPolicyPage() {
    return(<PrivacyPolicy/>)
}
