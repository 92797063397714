import React, { useContext, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip, 
        GeoJSON, LayersControl, useMap, FeatureGroup, 
        ScaleControl, ImageOverlay } from 'react-leaflet';
import { Typography, TextField } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { sampleDistrictGeojson } from '../../../core/data'
import { SosContext } from '../../../contexts/SosContext';

import northArrowLogo from '../../../assets/Logos/northArrowIcon.svg'
import 'leaflet.browser.print/dist/leaflet.browser.print'
import 'leaflet.browser.print/src/leaflet.browser.print.sizes'
import 'leaflet.browser.print/src/leaflet.browser.print.utils'
import 'leaflet.browser.print/src/leaflet.browser.print.modes'
import 'leaflet-draw/dist/leaflet.draw.js'
import 'leaflet-draw/dist/leaflet.draw.css'

import { Contrast } from '@mui/icons-material';

import { 
    EmergencyPinUnresolved, EmergencyPinUnresolvedSelected, 
    EmergencyPinResolved, EmergencyPinResolvedSelected,
    CustomMarker 
} from '../../../core/mapIcons';
import { Metadata } from './DashboardMapMetadata';

const { BaseLayer } = LayersControl


function Sos() {
    const { 
        emergencyShow, 
        selectedRow, setSelectedRow, 
        emergencyShow2,
        value // tabvalue
     } = useContext(SosContext)

    const map = useMap();
    
    useEffect(()=>{
        if (selectedRow) {
            map.flyTo([selectedRow.coords[1], selectedRow.coords[0]], 16, {
                animate: true,
                duration: 0.5 // in seconds
            })
        } else {
            map.flyTo([10.7232, 122.5621], 13, {
                animate: true,
                duration: 0.5 // in seconds
            })
        }
    }, [selectedRow])

    return (
        <>

        { value == 0?
        emergencyShow && emergencyShow.values ? 
        emergencyShow.values.map((point) => {
            if (point.coords) {
                return (
                    <Marker
                    icon={
                        selectedRow && selectedRow.id? 
                        point.status==="Resolved" && selectedRow.id===point.id ? EmergencyPinResolvedSelected: 
                        point.status==="Unresolved" && selectedRow.id===point.id ? EmergencyPinUnresolvedSelected:
                        point.status==="Resolved" ? EmergencyPinResolved:EmergencyPinUnresolved:


                        emergencyShow2 && emergencyShow2.values ?
                        point.status==="Resolved" && emergencyShow2.values.some((item)=>item.id===point.id)? EmergencyPinResolvedSelected:
                        point.status==="Unresolved" && emergencyShow2.values.some((item)=>item.id===point.id)? EmergencyPinUnresolvedSelected:
                        point.status==="Resolved" ? EmergencyPinResolved:EmergencyPinUnresolved:

                        point.status==="Resolved" ? EmergencyPinResolved:EmergencyPinUnresolved
                    }

                    key={point.id}
                    position={[point.coords[1], point.coords[0]]}
                    eventHandlers={{
                        click: (e) => {
                            setSelectedRow(point)
                        }
                    }}
                    >
                        <Tooltip direction="top">{point.category}</Tooltip>
                    </Marker>
                )
            }
        }) : null
        : null
        }
        </>
    );
  }
  
const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}
export default function DashboardMap() { 
    
    const [mapTitlePrint, setMapTitlePrint] = React.useState("Map Title")
    const [basemap, setBasemap] = useState('ESRI');
    const { mapRef, groupRef } = useContext(SosContext)

    const handleBaseLayerChange = (event) => {
        console.log(event.name)
        setBasemap(event.name);
    };

    const whenReadyHandler = event => {
        const { target } = event;
        target.on('baselayerchange', handleBaseLayerChange);
    }

    const getGeoJsonStyle = () => {
        switch (basemap) {
            case 'ESRI':
                return {
                    fillColor: "#ffffff00",
                    color: "#4ff9f9",
                    opacity: 0.65
                };
            case 'OpenStreetMap':
                return {
                    fillColor: "#ffffff00",
                    color: "#0d3c47",
                    opacity: 0.65
                };
            case 'CartoDB':
                return {
                    fillColor: "#ffffff00",
                    color: "#4ff9f9",
                    opacity: 0.65
                };
            default:
                return {
                    fillColor: "#ffffff00",
                    color: "#4ff9f9",
                    opacity: 0.65
                };
        }
    };

    const inputMapTitle = (e) => {
        setMapTitlePrint(e.target.value)
    }
    
    function NorthArrowControl({ position }) {
        const positionClass =
          (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
        return (
            <div className={positionClass} style={{marginBottom:"35px"}}>
                <img className="leaflet-control" src={northArrowLogo} alt="north-arrow-logo" />
            </div>
        )
    }

    var _round = function(num, len) {
        return Math.round(num*(Math.pow(10, len)))/(Math.pow(10, len));
    };
    var strLatLng = function(latlng) {
        return "("+_round(latlng.lat, 6)+", "+_round(latlng.lng, 6)+")";
    };
    var getPopupContent = function(layer) {
        if (layer instanceof L.Marker) {
            return strLatLng(layer.getLatLng());
        } else if (layer instanceof L.CircleMarker) {
            var center = layer.getLatLng(),
                radius = layer.getRadius();
            return "Center: "+strLatLng(center)+"<br />"
                  +"Radius: "+_round(radius, 2)+" m";
        } else if (layer instanceof L.Polygon) {
            var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
                area = L.GeometryUtil.geodesicArea(latlngs);
            return "Area: "+L.GeometryUtil.readableArea(area, true);
        } else if (layer instanceof L.Polyline) {
            var latlngs = layer._defaultShape ? layer._defaultShape() : layer.getLatLngs(),
                distance = 0;
            if (latlngs.length < 2) {
                return "Distance: N/A";
            } else {
                for (var i = 0; i < latlngs.length-1; i++) {
                    distance += latlngs[i].distanceTo(latlngs[i+1]);
                }
                return "Distance: "+_round(distance, 2)+" m";
            }
        }
        return null;
    };
    const _created = (e) => {
        var layer = e.layer;
        var content = getPopupContent(layer);
        if (content !== null) {
            layer.bindPopup(content);
        }
    };

    const _edited = (e) => {
        var layers = e.layers,
        content = null;
        layers.eachLayer(function(layer) {
            content = getPopupContent(layer);
            if (content !== null) {
                layer.setPopupContent(content);
            }
        });
    }
    
    return (
        <MapContainer center={[10.7232, 122.5621]} zoom={13} scrollWheelZoom={true} style={{height:'100%', width: '100%'}} 
            whenCreated={ mapInstance => { 
                mapRef.current = mapInstance
                L.control.browserPrint({
                    position: 'topleft', 
                    title: 'Print',
                    printModes: [
                        "Landscape",
                        "Portrait",
                    ],
                }).addTo(mapRef.current);
                mapRef.current.on(L.BrowserPrint.Event.Print, function(e){
                
                
                    L.control.scale({position:'topright'}).addTo(e.printMap); //scale!
                
                    var north2 = L.control({position: "topright"});
                    north2.onAdd = function(map) {
                        var div = L.DomUtil.create("div", "leaflet-control");
                        div.innerHTML = `<img src=${northArrowLogo} alt="lungsod-logo" />`;
                        return div;
                    }
                    north2.addTo(e.printMap);
                
                });
            } }
            whenReady={whenReadyHandler}
            >
            <LayersControl change={handleBaseLayerChange}>
                <BaseLayer checked name="ESRI">
                    <TileLayer
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </BaseLayer>
                <BaseLayer name="OpenStreetMap">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </BaseLayer>
                <BaseLayer name="CartoDB">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    />
                </BaseLayer>
            </LayersControl>

            <FeatureGroup ref={groupRef}>
                <Sos/>
            </FeatureGroup>

            <GeoJSON style={getGeoJsonStyle} data={sampleDistrictGeojson} />
            <NorthArrowControl position="bottomleft"/>
            <ScaleControl position="bottomleft"/>

            <div className="leaflet-bottom leaflet-right">
                <TextField className="leaflet-control" onChange={(e)=>{inputMapTitle(e)}} InputProps={{style: {fontWeight: 700, fontSize: "1rem"}}} style={{marginBottom:"3vh", backgroundColor: "rgba(255,255,255,0.8)"}} defaultValue={mapTitlePrint}></TextField>
            </div>
            
           <Metadata mapTitlePrint={mapTitlePrint} />
        </MapContainer>
        
    )
}


