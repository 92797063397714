import React, { useState } from 'react';
import { Grid, Typography, CardContent, TextField, FormControl, Select, MenuItem, InputAdornment } from '@mui/material'
import { listOfOffices } from '../../core/data';

import { enGB } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import * as Constants from '../../core'

export default function ProfileInformation(props) {
    var isEditing = props.isEditing
    var editedUser = props.editedUser
    var date = props.date
    var dateError = props.dateError
    var currentBarangayList = props.currentBarangayList
    var handleBirthday = props.handleBirthday
    var formValChange = props.formValChange

    return (

        <CardContent>
            <Grid container direction="row" justifyContent="space-between" spacing={1}>
                <Grid item xs={12} md={4}>
                    <Typography component="div" variant="subtitle2" color="text.secondary" gutterBottom>First Name</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}} disabled={!isEditing} value={editedUser.first_name} onChange={formValChange} size="small" fullWidth id="first_name"  name="first_name"  />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Middle Name</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}} disabled={!isEditing} value={editedUser.middle_name} onChange={formValChange} size="small" fullWidth name="middle_name" id="middle_name" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle2" color="text.secondary"  gutterBottom>Last Name</Typography>
                    <TextField component="div" style={{backgroundColor:"white", borderRadius:5}} disabled={!isEditing} value={editedUser.last_name} onChange={formValChange} size="small" fullWidth name="last_name" id="last_name" />
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Birthdays (dd/mm/yyyy)</Typography>
                    <DatePicker date={date} onDateChange={handleBirthday} locale={enGB} name="birthday">
                    {({ inputProps, focused }) => (
                        <TextField fullWidth size="small" style={{backgroundColor:"white", borderRadius:5}}
                        name="birthday"
                        disabled={!isEditing}
                        required
                        autoComplete='off'
                        className={'input' + (focused ? ' -focused' : '')}
                        {...inputProps}
                        error={!dateError}
                        helperText={!dateError?"Must be at least 18 y/o.":null}
                        />
                    )}
                    </DatePicker>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Contact No.</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}}
                        disabled={!isEditing}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                        }}
                        error={editedUser.isError.phone_number.length > 0 } 
                        helperText={editedUser.isError.phone_number.length > 0? editedUser.isError.phone_number:null}
                        value={editedUser.phone_number} onChange={formValChange} size="small" required fullWidth name="phone_number" autoComplete="phone_number" id="phone_number" />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Email</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}} 
                    error={editedUser.isError.email.length > 0 } 
                    helperText={editedUser.isError.email.length > 0? editedUser.isError.email:null}
                    disabled={!isEditing} value={editedUser.email} onChange={formValChange} size="small" fullWidth id="email" name="email"  />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="text.secondary"  gutterBottom>Address</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}}  disabled={!isEditing} value={editedUser.street_address} onChange={formValChange} size="small" fullWidth name="street_address" id="street_address" />
                </Grid>
            
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>District</Typography>
                    <FormControl fullWidth size="small" required>
                        <Select
                            style={{backgroundColor:"white", borderRadius:5}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={editedUser.district?editedUser.district:""}
                            onChange={formValChange}
                            name="district"
                            disabled={!isEditing}
                        >
                            <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                            {Constants.districtNames.map((name)=> {
                                return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Barangay</Typography>
                    <FormControl fullWidth size="small" required>
                        <Select
                            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                            style={{backgroundColor:"white", borderRadius:5}}
                            labelId="demo-simple-select-label-brgy"
                            id="demo-simple-select-brgy"
                            value={editedUser.barangay}
                            onChange={formValChange}
                            name="barangay"
                            disabled={!isEditing} 
                        >
                            <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                            {currentBarangayList.map((name)=> {
                                return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Zip Code</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}} disabled label={editedUser.zip_code} size="small" fullWidth name="zip_code" id="zip_code" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Country</Typography>
                    <TextField style={{backgroundColor:"white", borderRadius:5}} disabled label={editedUser.country} size="small" fullWidth name="country" id="country" />
                </Grid>
            </Grid>
        </CardContent>
    )}