import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, Paper, FormControl, Select, MenuItem, Autocomplete, TextField, Chip, Box, OutlinedInput, InputLabel } from '@mui/material';

import { themes } from '../../../../core/theme';
import { TourismContext } from '../../../../contexts/TourismContext';

import CheckboxCategory from './checkboxCategory';
import MultipleSelectDistrict from './multipleSelectDistrict';
import MultipleSelectBarangay from './multipleSelectBarangay';
import CsvUpload from './CsvUpload';
import CsvUploadImages from './CsvUploadImages';

const styles = {
  text: {
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
    color: themes.colors.filterText
  }
}

export default function FilterDrawer(props) {

  const noZoom = false || props.noZoom;

  const { 
    districtName, setDistrictName, 
    attractionCategories, setAttractionCategories, 
    attractionsAll, setAttractionsShow, 
    barangayName, setBarangayName,
    mapRef, groupRef
  } = useContext(TourismContext)

  // start: Jan 24: checkbox category for report status
  const attractionCat = [
    {
        Parent: 'Categories', 

        // List coming from CSV as of April 5, 2022
        Child: [ 'Attractions', 'Museums and Cultural Sites', 'Outdoor Activities',
               'Souvenirs and Delicacies', 'Food and Dining', 'Restaurants', 
               'Fun and Nightlife', 'Nature', 'Cafes' ],
        Context: [attractionCategories, setAttractionCategories]
    }, 
  ]
  // end: Jan 24: checkbox category for report status

  const onClickFilter = () => {

    // FILTER BY DISTRICT
    // Source: https://stackoverflow.com/questions/28138139/javascript-filter-array-of-objects-by-array-of-strings
    if (districtName.length > 0) {
      var filteredByDistrict = attractionsAll.values.filter(function (attraction) {
        return districtName.indexOf(attraction.district) >= 0; 
      });
    } else {
      var filteredByDistrict = attractionsAll.values
    }

    if (barangayName.length > 0) {
      // FILTER BY BARANGAY
      // Source: https://stackoverflow.com/questions/28138139/javascript-filter-array-of-objects-by-array-of-strings
      var filteredByBarangay = filteredByDistrict 
    } else {
      var filteredByBarangay = filteredByDistrict
    }

    // FILTER BY CATEGORY
    // Get list of checked categories
    var filteredCategories = []
    for (const key in attractionCategories) {
      if (attractionCategories[key]) {
        filteredCategories.push(key)
      }
    } 
    // Source: https://www.geeksforgeeks.org/how-to-find-if-two-arrays-contain-any-common-item-in-javascript/
    var filteredByCategory = filteredByBarangay.filter(function (attraction) {
      return filteredCategories.some(item => (attraction.categories).includes(item))
    });

    setAttractionsShow({count: filteredByCategory.length, values: filteredByCategory})

  }

  const onClickReset = () => {
    setDistrictName([])
    setBarangayName([])
    for (const key in attractionCategories) {
      attractionCategories[key] = true
    }
    setAttractionsShow(attractionsAll)
  }

  const zoomToSelected = () => {
    if (mapRef.current) {
      var map = mapRef.current
      var group = groupRef.current
      map.fitBounds(group.getBounds());
    }
  }

  return (
    // MAIN CONTAINER
    <Grid container spacing={0}>
      <Paper
        square
        elevation={0}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          maxHeight: "90vh",
          marginBottom: 20
        }}
      >
        {/* FILTERS COMPONENT */}
        <Grid container style={{ flex: 4, height: '88%', width: '100%', paddingTop: '1vh', 
                      borderBottomWidth: 1, borderBottomColor: themes.colors.greyBorder, overflow: "auto" }}>

          <Typography style={styles.text}>Filter by District:</Typography>
          <Grid item xs={12}>
            <MultipleSelectDistrict/>
          </Grid>

          {/* KAPAG MORE THAN ONE NAWAWALA TO */}
          { districtName.length == 1 ? 
          <>
            <Typography style={styles.text}>Filter by Barangay:</Typography>
            <Grid item xs={12}>
              <MultipleSelectBarangay/>
            </Grid>
          </> : null }

          <Typography style={styles.text}>Filter by Category:</Typography>
          <Grid item xs={12}>
            {attractionCat?attractionCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>

        </Grid>

        {/* BUTTON FUNCTIONS */}
        <Grid container style={{ width: '100%', alignItems: 'center'}}>
          <Grid container direction="row" justifyContent="space-between">
            <Button style={{...styles.text, color:"white", width:"48%"}} variant="contained" onClick={onClickFilter}>Filter</Button>
            <Button style={{...styles.text, color:themes.colors.primary, width:"48%"}} variant="outlined" onClick={onClickReset}>Reset</Button>
          </Grid>
          {!noZoom?
          <Grid container direction="row" justifyContent="space-between" style={{marginTop: "5%"}} >
            <Button style={{...styles.text, color:themes.colors.primary, width:"100%"}} variant="outlined" onClick={zoomToSelected}>Zoom to bounds</Button>
          </Grid>: null }
        </Grid>

        <Grid container style={{marginTop: "5%"}} >
          <CsvUpload/>
        </Grid>

        <Grid container style={{marginTop: "5%"}} >
          <CsvUploadImages/>
        </Grid>
      </Paper>     
    </Grid>
  );
}
