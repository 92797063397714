import React, { useContext, useEffect, useState } from 'react';
import { 
    Button, Grid, Typography, Paper, 
    Skeleton, CardMedia, IconButton, Box,CircularProgress, Tooltip 
} from '@mui/material';

import { themes } from '../../core/theme'
import { CloseOutlined, AddPhotoAlternateOutlined } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel'
import axios from 'axios';
import * as Constants from '../../core'

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.greyTxt,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: themes.colors.primary,
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    imgIconButton: {
        "&:hover" : {
            opacity: 0.7, 
            backgroundColor: themes.colors.primary, 
        },
        opacity: 0.8, 
        backgroundColor: themes.colors.primary, 
        color: "white",
    }
}

export default function WhatsNew () {

    const [selectedImgArray, setSelectedImgArray] = React.useState([])
    const [isImgArrayLoading, setIsImgArrayLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false)
    const [imgIndex, setImgIndex] = React.useState(0);
    const [editedImgArray, setEditedImgArray] = React.useState([])

    const [removedPhotos, setRemovedPhotos] = React.useState([]) // mga i-reremove from the original uploaded
    const [addedPhotos, setAddedPhotos] = React.useState([]) // kaka-add lang na photos

    const [preview, setPreview] = React.useState(null)

    const getImagesByTagAndTagId = async (tag, tag_id) => {
        setIsImgArrayLoading(true)
        const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, {
          "tag": tag,
          "tag_id": tag_id
        })
        .then((res)=> {
          if (res.data.count>0) {
            setSelectedImgArray(res.data.values)
            setEditedImgArray(res.data.values)
          } else {
            setSelectedImgArray([])
            setEditedImgArray([])
          }
          setIsImgArrayLoading(false)
        })
    }

    React.useEffect(()=> {
        getImagesByTagAndTagId("whats_new", "admin")
    }, [])

    const handleRemovePhoto = () => {
        if (editedImgArray.length>0) {
            setEditedImgArray(editedImgArray.filter((img, index)=> index !== imgIndex))

            var isPrevIncluded = editedImgArray.filter((img, index)=> index === imgIndex)[0].id
            var item = editedImgArray.filter((img, index)=> index === imgIndex)[0]

            if (isPrevIncluded) {
                setRemovedPhotos([...removedPhotos, item])

            } else {
                setAddedPhotos(addedPhotos.filter((img, index)=> img.img !== item.img))
            }
        }
    }
    const addNewPhoto = e => {
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            setPreview(reader.result); // for preview only
            setEditedImgArray([...editedImgArray, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
            setAddedPhotos([...addedPhotos, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
        });
        reader.readAsDataURL(e.target.files[0])
       
    };

    const handleNext = (next, active) => {
        setImgIndex(next)
    }

    const handlePrev = (prev, active) => {
        setImgIndex(prev)
    }

    const handleUndo = () => {
        setIsEditing(false); 
        setEditedImgArray(selectedImgArray)
        setAddedPhotos([])
        setRemovedPhotos([])
    }

    const handleSave = () => {
        setLoading(true)
        
        if (addedPhotos.length>0) {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            var formData2 = new FormData();
            formData2.append("tag", "whats_new")
            formData2.append("tag_id", "admin")
        
            addedPhotos.forEach((element, index) => formData2.append(`img[${index}]`, element.img2))
            for (var pair of formData2.entries()) {
            }

            const fetchData2 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/create`, formData2, config)
                .then((res) => {
                    console.log("uploadImage res",res)
                    setAddedPhotos([])
                    setIsEditing(false)
                    setLoading(false)
                })
                .catch((e)=>{
                    console.log(e)
                    setIsEditing(false)
                    setLoading(false)
                })
            }
            fetchData2()
        }
       

        var id_array = []
        if (removedPhotos.length>0) {
            removedPhotos.forEach(element => console.log("element.id", id_array.push(element.id)))
            const formData3 = {
                "id_array": id_array
            }

            const fetchData3 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/delete/multiple`, formData3)
                .then((res) => {
                    setRemovedPhotos([])  
                    setIsEditing(false)
                    setLoading(false)
                })
            }
            fetchData3()
        }
    }

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                    maxHeight: "90vh",
                    padding: 20
                }}
            >
                <Typography sx={{...styles.attractionLabelText, fontFamily: "OpenSans ExtraBold"}}>What's New?</Typography>
                <Typography sx={{...styles.attractionLabelText, fontFamily: "OpenSans", fontSize: 12}}>For maximum quality, an image of aspect ratio 1062 by 630 pixels is suggested.</Typography>
                <Grid container style={{ height: '92%', width: '100%', paddingTop: '1vh', overflow: "auto"}}>
                    <Grid container sx={{alignItems: 'center', justifyContent: 'center', position: "relative", minHeight: 350}}>
                    
                    {isEditing?
                        <Grid container justifyContent={"space-around"}
                            style={{
                                position:"absolute", 
                                top: "40%", zIndex:5, 
                                width: "30%"
                            }}
                        >

                            <Tooltip title="Add a Photo">
                                <IconButton component="label" variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                >
                                    <input
                                        type={"file"}
                                        id={"photoInput"}
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={addNewPhoto}
                                        hidden
                                    />
                                    <AddPhotoAlternateOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Remove Photo">
                                <IconButton variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    onClick={handleRemovePhoto}
                                >
                                    <CloseOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :null}
                        
                        {isImgArrayLoading?
                            <Skeleton variant="rectangular" height={"30vh"} width={"100%"} />
                            :
                                editedImgArray.length>0?
                                <Carousel 
                                    sx={{height:"100%", minWidth: "100%"}} 
                                    autoPlay={false}
                                    cycleNavigation={false}
                                    indicatorIconButtonProps={{style: { position:"relative", bottom:"2vh"}}}
                                    next={ (next, active) => {handleNext(next, active)} }
                                    prev={ (prev, active) => {handlePrev(prev, active)} }
                                    >
                                    {
                                        editedImgArray.map((img, i) => 
                                            <CardMedia
                                                component="img"
                                                image={img['img']}
                                                alt="img[0]"
                                                key={img['img']}
                                                style={{
                                                    borderRadius:25, padding:5, height:"100%", width:"98%", 
                                                    opacity: isEditing?0.8:1
                                                }}
                                            /> )
                                    }
                                </Carousel>
                            :
                            <Paper sx={styles.attractionPic} elevation={3}>
                                <Grid container style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={styles.attractionLabelText}>NO IMAGE AVAILABLE</Typography>
                                </Grid>
                            </Paper>
                        }
            
                    </Grid>

                    <Grid container style={{height: '60%', width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                      
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>
                            {isEditing?
                                <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                    <Box sx={{ position: 'relative', width:"48%" }}>
                                    <Button 
                                        style={{width:"100%"}}
                                        onClick={handleSave} 
                                        variant="contained"
                                        disabled={editedImgArray.length==0}
                                        >
                                            Save Changes
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: themes.colors.primary,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                    </Box>
                                    <Button style={{width:"50%"}} 
                                        onClick={handleUndo} 
                                        variant="outlined">Undo</Button>
                                </Grid> :
                                    JSON.parse(localStorage.getItem('user')).user_type == "Admin" ?
                                    <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                        <Button fullWidth onClick={()=>setIsEditing(true)} variant="contained">Edit</Button>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                        <Grid container style={{ width: '100%', height: '5%'}} />
                       
                    </Grid>
                    
                </Grid>
            </Paper>
        </Grid>
    );
}

