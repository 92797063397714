import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';
import { themes } from '../../../../core/theme';
import { RaiContext } from '../../../../contexts/RaiContext';

import CancelIcon from '@mui/icons-material/Cancel';
import * as Constants from '../../../../core'

const styles = {
  textField: {
    "& .MuiOutlinedInput-input" : { // text style
        fontFamily: 'OpenSans',
        fontSize: '1vh', 
    },
    "& .MuiOutlinedInput-notchedOutline" : { // border of textField
        backgroundColor: "rgba(218,218,218,0.3)",
        boxShadow: 3, 
    },
    "& .MuiInputLabel-outlined, .MuiInputLabel-root": { // label style
        color: themes.colors.filterText,
        fontFamily: 'OpenSans',
        fontSize: '1vh',
    }
  },
  inputLabel: { // label style
    color: themes.colors.filterText,
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
  },
  selectChoices: { // select choices style
    color: 'white',
    fontFamily: 'OpenSans',
    fontSize: '1vh',
    backgroundColor: themes.colors.primary
  }
}

// start: for multiple select 1
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function getStyles(name, districtName, theme) {
  return {
    fontWeight:
      districtName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    fontSize: '1.5vh',
    fontFamily: 'OpenSans'
  };
}
// end: for multiple select 1


export default function MultipleSelectDistrict() {
  const { districtName, setDistrictName, checkedAll, setCheckedAll } = React.useContext(RaiContext)

  // start: for multiple select 2
  const theme = useTheme();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    if (value[value.length-1] !== 'Select all') {
      setDistrictName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    } else {
      if (checkedAll) {
        setDistrictName([]);
      } else {
        setDistrictName(Constants.districtNames)
      } 
      setCheckedAll(!checkedAll)
    }
  };

  const handleDelete = (e, chipToDelete) => {
    setDistrictName((districtName) => districtName.filter((chip) => chip !== chipToDelete));
    // source: delete an item in an array using states: https://mui.com/components/chips/#basic-chip
    // source: delete an item in an array using states: https://stackoverflow.com/questions/57341541/removing-object-from-array-using-hooks-usestate
  };

  const selectAll = () => {
    if (checkedAll) {
      setDistrictName([]);
    } else {
      setDistrictName(Constants.districtNames);
    } 
    setCheckedAll(!checkedAll)
  }

  React.useEffect(()=>{ // useEffect use to update "Select All/ Deselect all state"
    if (districtName.length<7) {
      setCheckedAll(false)
    }
  }, [districtName])
  // end: for multiple select 2

  return (
    
    <FormControl fullWidth style={{marginTop: 10, }} size="small">
        <InputLabel id="demo-multiple-chip-label" sx={styles.inputLabel}>Select by District</InputLabel>
        <Select
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={districtName}
            onChange={handleChange}
            input={
              <OutlinedInput 
              id="select-multiple-chip" 
              label="Select by District"
              sx={styles.textField} 
              />
            }
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                    <Chip key={value} label={value} 
                    deleteIcon={
                        <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                        style={{color: 'white'}}
                        />
                    }
                    onDelete={(e) => handleDelete(e, value)}
                    sx={styles.selectChoices}
                    />
                ))}
                </Box>
            )}
            MenuProps={MenuProps}
            >
            <MenuItem value={"Select all"} style={{
                      fontWeight:theme.typography.fontWeightRegular,
                      fontSize: '1.5vh',
                      fontFamily: 'OpenSans'
            }} onClick={selectAll}>{checkedAll?"Deselect all":"Select all"}</MenuItem>
            {Constants.districtNames.map((name) => (
              <MenuItem
                key={name}
                value={name}
                style={getStyles(name, districtName, theme)}
                >
                {name}
              </MenuItem>
            ))}
        </Select>
    </FormControl>
      
  );
}
