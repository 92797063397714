import React, { useEffect, useContext } from 'react';
import { Grid, Typography } from '@mui/material';

import Map from './DashboardMap'
import Tools from './Tools';
import {themes} from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: themes.colors.sub3,
        minHeight: "93vh",
    },
    menuButton: {
        color: themes.colors.poloBlue,
        height: "6vh",
        paddingInline: "2vw",
        fontSize: '2vh',
        fontFamily : "Mulish",
        textTransform: "capitalize",
        borderRadius: 0,
        borderRight: 2, 
        borderColor: "white",
        "&:hover" : {
            color: 'white'
        }
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
      }
}

const Dashboard = () => {

    const { selectedRow } = useContext(RaiContext)

    return (
        <Grid container sx={styles.root}>
            
            {/* LOADING SCREEN */}

            {/* LEFT SIDE COMPONENT: TOOLS */}
            <Grid container style={{ flex: 3, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>
                <Tools/>
            </Grid>

            {/* RIGHT SIDE COMPONENT: MAP */}
            <Grid container style={{ flex: 17, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>
                <Map/>
            </Grid>    
        
        </Grid>
    );
}

export default function RaiDashboard() {
    return(<Dashboard/>)
}
