import * as React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward, TrendingFlat, Equalizer as EqualizerIcon } from '@mui/icons-material';
import { themes } from '../../../core/theme'
import * as fn from '../../../core/functions'

const styles = {
    cardContainer: {
        width: 300,
        height:180, 
        margin: '1vw',
        alignSelf:"center",
        '&:hover': {
            cursor: 'pointer'
        },
    },
    attractionLabel: {
        width: '11vh', 
        height: '11vh',
        backgroundColor: themes.colors.tertiary,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: themes.colors.primary,
        fontFamily: 'OpenSans Semibold',
        textAlign: 'center',
        fontSize: '2.3vh',
        lineHeight: '3vh',
        marginInline: '1vw'
    },
    attractionDescLabel: {
        color: themes.colors.primary,
        fontFamily: 'OpenSans',
        fontSize: '1.2vh',
        textAlign: 'right'
    },
    attractionDescValue: {
        color: "#898A8D",
        fontFamily: 'OpenSans',
        fontSize: '1.3vh',
    },
}

export default function SummaryCard(props) {
  
  var value = props.value || ""
  var district = value.district

  if (district != "null") {
    district = value.district 
  } else {
    district = "Null"
  }

  return (
    <Paper sx={styles.cardContainer} elevation={3}>
        <Grid container style={{height:"100%"}} >

            <Grid container direction="row" justifyContent={"space-between"}>

                <Grid item container style={{maxWidth: "50%", alignItems: 'center', justifyContent: 'center'}}>
                    <Typography sx={styles.attractionLabelText}>{value.district}</Typography>        
                </Grid>

                <Grid item container direction="column" style={{maxWidth: "50%", alignItems: 'center', justifyContent: 'center', height:"100%"}}>
                    <Grid item><Typography sx={{...styles.attractionLabelText, color: themes.colors.greyTxt, fontSize: '2vh',}}>Emergencies</Typography></Grid><br/>
                    <Grid item><Typography sx={{...styles.attractionLabelText, fontFamily: "OpenSans ExtraBold", fontSize: '6vh'}}>{value.this_month_count}</Typography></Grid><br/>
                    <Grid item><Typography sx={{...styles.attractionLabelText, color: themes.colors.greyTxt, fontSize: '1.5vh'}}>this month</Typography></Grid>       
                </Grid>    
            </Grid>

            <Grid container style={{width: '100%', flex: 2, backgroundColor: themes.colors.sub2, flexDirection: 'row'}}>   
                <Grid container style={{width: '100%', flex: 3, flexDirection: 'row', alignItems: 'center', paddingLeft: '1vw'}}>
                    { value.percentage > 0 ? <ArrowUpward style={{color: themes.colors.primary, height: '100%'}}/> : 
                        value.percentage < 0 ? <ArrowDownward style={{color: themes.colors.primary, height: '100%'}}/> : 
                        <TrendingFlat style={{color: themes.colors.primary, height: '100%'}}/>
                    } 
                    <Typography sx={{...styles.attractionLabelText, color: themes.colors.greyTxt, fontSize: '2vh'}}>{typeof(value.percentage)==="string"?"n/a":`${fn.roundToTwo(Math.abs(value.percentage))}%`}</Typography>        
                </Grid>

                <Grid container style={{width: '100%', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                    <EqualizerIcon style={{color: themes.colors.primary, height: '100%'}}/>       
                </Grid>     
            </Grid>
        </Grid>
    </Paper>

  );
}

