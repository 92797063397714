import React from 'react';

import ReportAnIssueDashboard from '../RaiPages';
import SosDashboard from '../SosPages';
import TourismDashboard from '../TourismPages';

export default function Main() {
    const module = JSON.parse(localStorage.getItem('user')).department_office.replace("\n","") || "None"
    switch (module) {
        case "CDRRMO":
            return <SosDashboard/>;
        case "PSTMO":
            return <ReportAnIssueDashboard/>;
        case "CTO":
            return <TourismDashboard/>;
        default:
            return <ReportAnIssueDashboard/>;
    }
}


