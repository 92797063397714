 
import iloiloLogo from '../../../assets/Logos/City of Iloilo Seal.png';
import dostLogo from '../../../assets/Logos/dost.png';
import upLogo from '../../../assets/Logos/up.png';
import tcagpLogo from '../../../assets/Logos/TCAGP_blue.png';
import lungsodLogo from '../../../assets/Logos/lungsod_blue.png';
import { Typography } from '@mui/material';

export const Metadata = ({mapTitlePrint}) => {
    var dateToday = new Date().toDateString().replace(/ /,', ') 

    return (
        <div leaflet-browser-print-pages="" className="legend-over-the-map-bottom" >
            {/* VISIBLE ONLY WHEN PRINTING */}
            <div className="legend-over-the-map-bottom-left">
                <Typography variant="h3" gutterBottom component="div" style={{fontWeight:700}}>{mapTitlePrint}</Typography>
                <small> Coordinate Reference System: WGS 84 <br/> Date Generated: {dateToday}</small> 
            </div>

            <div className="legend-over-the-map-bottom-right">
                <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent: "space-between", alignItems:"flex-start"}}>
                    <img src={iloiloLogo} alt="iloilo-logo" height={"40px"}></img>
                    <img src={dostLogo} alt="dost-logo" height={"40px"}></img>
                    <img src={upLogo} alt="up-logo" height={"40px"}></img>
                    <img src={tcagpLogo} alt="tcagp-logo" height={"40px"}></img>
                    <img src={lungsodLogo} alt="lungsod-logo" height={"60px"}></img>
                </div>
                <small>&copy; This map was made through the City Command Dashboard Created by Project LUNGSOD for the use of Iloilo City Government.</small>
            </div>
        </div> 
    )
 }
 