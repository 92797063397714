import React from 'react';
import { Grid, Typography, FormHelperText, TextField, FormControl, Select, MenuItem, Box, Button, IconButton, Tooltip } from '@mui/material'
import { listOfOffices } from '../../core/data';
import { themes } from '../../core/theme'
import { Close as CloseIcon } from '@mui/icons-material';

export default function OrganizationInformation(props) {
    var createdUser = props.createdUser
    var setCreatedUser = props.setCreatedUser

    var imgData = props.imgData
    var setImgData = props.setImgData

    var selectedFile = props.selectedFile
    var setSelectedFile = props.setSelectedFile

    var formValChange = props.formValChange

    return (
        <Grid container direction="row" justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Department Office *</Typography>
                <FormControl fullWidth size="small" required error={createdUser.isError.department_office?true:false}>
                    <Select
                        style={{backgroundColor:"white", borderRadius:5}}
                        labelId="demo-simple-select-label-department_office"
                        id="demo-simple-select-department_office"
                        value={createdUser.department_office}
                        onChange={(e)=>formValChange(e, "department_office")} 
                        name="department_office"
                    >
                        <MenuItem key="None" value={""} style={{display: 'none'}}></MenuItem>
                        {listOfOffices.map((office)=> {
                            return (<MenuItem key={office} value={office}>{office}</MenuItem>)
                        })}
                    </Select>
                    <FormHelperText>{createdUser.isError.department_office}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Position *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}} 
                    value={createdUser.position} 
                    onChange={(e)=>formValChange(e, "position")} 
                    size="small" required fullWidth name="position" type="position" id="position" 
                    error={createdUser.isError.position?true:false}
                    helperText={createdUser.isError.position}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID Type *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    value={createdUser.gov_id_type} 
                    onChange={(e)=>formValChange(e, "gov_id_type")} 
                    size="small" required fullWidth name="gov_id_type" type="id-type" id="id-type" 
                    error={createdUser.isError.gov_id_type?true:false}
                    helperText={createdUser.isError.gov_id_type}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID No. *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}} value={createdUser.gov_id_no} 
                    onChange={(e)=>formValChange(e, "gov_id_no")} 
                    size="small" required fullWidth name="gov_id_no" type="id-no" id="id-no" 
                    error={createdUser.isError.gov_id_no?true:false}
                    helperText={createdUser.isError.gov_id_no}
                />
            </Grid>
            
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID Picture *</Typography>
                <Button variant="contained"component="label" fullWidth> 
                    Upload ID
                    <input 
                        name="gov_id_img" 
                        onChange={(e)=>formValChange(e, "gov_id_img")} 
                        hidden type="file" accept="image/x-png,image/gif,image/jpeg"
                    />
                </Button>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>File Name:</Typography>
                {selectedFile?
                    <TextField 
                        style={{backgroundColor:"white", borderRadius:5}} 
                        fullWidth 
                        inputProps={{ readOnly: true, }} 
                        focused size="small" 
                        value={selectedFile.name}
                    />
                    :
                    <TextField 
                        style={{backgroundColor:"white", borderRadius:5}}
                        fullWidth 
                        inputProps={{ readOnly: true, }} 
                        size="small" value={""}
                        error={createdUser.isError.gov_id_img?true:false}
                        helperText={createdUser.isError.gov_id_img}
                    />
                }
            </Grid>

            {selectedFile?
                <Grid item xs={12} md={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: "auto" }}>
                        <img style={{height:200}} src={imgData} alt={"gov_id_img"}/>
                        <Tooltip title="Remove">
                            <IconButton
                                aria-label="close"
                                onClick={()=>{
                                    setSelectedFile(null)
                                    setImgData(null)
                                    setCreatedUser({
                                        ...createdUser,
                                        gov_id_img: ""
                                    })
                                }}
                                sx={{
                                    alignSelf:"flex-end",
                                    position: 'relative',
                                    color:themes.colors.hoverColor,
                                    top: -200,
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Grid>
            :null}
        </Grid>
)}