import React, { useContext, useEffect, useState } from 'react';
import { Paper, Grid } from '@mui/material';
import { SosContext } from '../../../contexts/SosContext';
import SummaryCard from './SummaryCard';
import HorizontalScroll from 'react-scroll-horizontal'

export default function SummaryCardsList () {

    const { emergencyAll } = useContext(SosContext);
    const [tableCardValues, setTableCardValues] = useState([])

    function getByValue(key, value, array) {
        var occurs = 0;
        for (var i=0; i<array.length; i++) {
          if ( key in array[i] && array[i][key] == value ) occurs++;
        }
      
        return occurs;
    }

    useEffect(async()=> {
        if (emergencyAll) {
          if (emergencyAll.count>0) {
            const unique = [...new Set(emergencyAll.values.map(report => report.district))];
    
            var monthNow = new Date().getMonth() + 1
            var yearNow = new Date().getFullYear()
           
            var filteredReports // reports this month
            filteredReports = emergencyAll.values.filter(function(report) {
              var [year, month] = report.date.split('-')
              return (monthNow === +month) && (yearNow == year)
            })          
    
            var current = new Date()
            var dateLastMonth = current.setMonth(current.getMonth()-1)
            dateLastMonth = new Date(dateLastMonth)
    
            var lastMonth = Number(dateLastMonth.toLocaleDateString().split('/')[0])
            var lastYear = Number(dateLastMonth.toLocaleDateString().split('/')[2])
           
            var filteredReports2 // reports last month
            filteredReports2 = emergencyAll.values.filter(function(report) {
              var [year, month] = report.date.split('-')
              return (lastMonth === +month) && (lastYear == year)
            })
    
            var count = null // count last month, initial
            var count2 = null // count this month, final 
            var per = null
            for (let i = 0; i < unique.length; i++) {
              
              count2 = await getByValue("district", unique[i], filteredReports)
              count = await getByValue("district", unique[i], filteredReports2)
              if (count!=0) {
                per = ((count2-count)/count)*100
              } else if (count==0) {
                per = "n/a"
              } else if ( count == 0 && count2 == 0) {
                per = 0
              }
              var obj = {
                "district": unique[i],
                "this_month_count": count2,
                "last_month_count": count,
                "percentage": per
              }
              if (tableCardValues.length<unique.length) {
                setTableCardValues(tableCardValues => [...tableCardValues, {
                  "district": unique[i],
                  "this_month_count": count2,
                  "last_month_count": count,
                  "percentage": per
                }]) // not working
              }
             
            }
          }
        }

      }, [emergencyAll])
    
    return (
      <Paper
        elevation={0}
        style={{
          height: "100%",
          width: "100%",
          marginBottom: 20,
        }}
      >
        {tableCardValues.length>4?
          <HorizontalScroll style={{ overflowX: "scroll" }}>
            {
              tableCardValues.map((value)=> {
                return(
                  <SummaryCard key={value.district} value={value}/>
                )
              })
            }
          </HorizontalScroll>
          :
          <Grid direction="row" container>
            <Grid item>
            {tableCardValues.map((value)=> {
              return(
                <SummaryCard key={value.district} value={value}/>
              )
            })}
            </Grid>
          </Grid>
        }
      </Paper>
    );
}

