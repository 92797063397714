import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Grid, Typography, Paper, DialogContent, 
  DialogActions, FormControl, Select, Box,
  MenuItem, Autocomplete, TextField, LinearProgress, 
  InputLabel, OutlinedInput, Chip, Stepper, Step, StepLabel, IconButton
} from '@mui/material';

import axios from 'axios';
import { themes } from '../../../../../core/theme';
import * as Constants from '../../../../../core'
import { BootstrapDialogTitle, BootstrapDialog } from '../../../../../components/dialog'
import CloseIcon from '@mui/icons-material/Close';
import { CsvPreview, CsvPreviewMapping } from './functions';
import { WindowContext } from '../../../../../contexts/WindowContext';

const steps = ['Add CSV File', 'Map CSV Data', 'Add Images', 'Upload'];

var imgProperties = [
  {"label":"Tag", "property": "tag"}, 
  {"label":"Attraction ID", "property": "tag_id"},
  {"label":"Image Name", "property": "img"}
]

function CsvMapping ({csvHeader, array, properties, inputObj, setInputObj}) {
  var csvHeader = csvHeader
  var array = array
  var properties = properties
  var inputObj = inputObj
  var setInputObj = setInputObj

  const [preview, setPreview] = React.useState(false)

  const handleCategories = (event) => {
    var name = event.target.name
    var value = event.target.value
    setInputObj({
      ...inputObj,
      [name]: value
    })
  }

  const handleSelect = (event, newValue, name, reason) => {
    var event = event
    var name = name
    var newValue = newValue
    var reason = reason
    
    if (reason == 'clear' && delete inputObj[name]) {
      var updatedCsvObj = JSON.parse(JSON.stringify(inputObj))
      delete updatedCsvObj[name]
      setInputObj(updatedCsvObj)
    } else {
      var exists = Object.keys(inputObj).some(function(k) {
        return inputObj[k] === newValue
      });
      
      var keyToRemove
      if (exists) {
        // kung nag-eexist na, remove yung una sa inputObj
        keyToRemove = Object.keys(inputObj).find((key)=>inputObj[key] === newValue)
        delete inputObj[keyToRemove]
      } 
      
      if (newValue) {
        setInputObj({
          ...inputObj,
          [name]: newValue
        })
      } else {
        setInputObj(delete inputObj[name])
      }
    }
    

  };

  return (
    <Grid container spacing={2} style={{padding: 10, marginTop:10}}>
      <Grid item xs={12} md={1} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Label</Typography></Grid>
      <Grid item xs={12} md={3}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Source Column</Typography></Grid>
      <Grid item xs={12} md={1} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Label</Typography></Grid>
      <Grid item xs={12} md={3}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Source Column</Typography></Grid>
      <Grid item xs={12} md={1} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Label</Typography></Grid>
      <Grid item xs={12} md={3}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Source Column</Typography></Grid>
      {properties?
        properties.map((prop, index)=>
          <>
            {
            prop.type==="ArrayField"? 
              <>
              <Grid key={"1-"+index} item xs={12} md={1} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}>
                <Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>{prop.label}</Typography>
              </Grid>
              <Grid key={"2-"+index} item xs={12} md={3} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                <FormControl fullWidth >
                  <InputLabel id="demo-multiple-chip-label" key="label-only">Can select multiple</InputLabel>
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    key={prop.property}
                    value={Object.keys(inputObj).length>0?
                      inputObj.hasOwnProperty(prop.property) && inputObj[prop.property].length>0?inputObj[prop.property]:[]:[]}
                    onChange={handleCategories}
                    input={<OutlinedInput id="select-multiple-chip" label="Can select multiple" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    name={prop.property}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                  >
                    {csvHeader.map((i) => (
                      <MenuItem
                        key={i}
                        value={i}
                      >
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
            : 
            <>
              <Grid key={"1-"+index} item xs={12} md={1} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}>
                <Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700, textTransform: "capitalize"}}>{prop.label.replace(/_/g, " ")} </Typography>
              </Grid>
              <Grid key={"2-"+index} item xs={12} md={3} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  options={csvHeader}
                  key={prop.property}
                  name={prop.property}
                  value={Object.keys(inputObj).length>0 && inputObj.hasOwnProperty(prop.property)?inputObj[prop.property]:''}
                  onChange={(event, newValue, reason) => {
                    handleSelect(event, newValue, prop.property, reason)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            </>
            }
          </>
        )
      :null}
      <Button style={{marginTop:"2%"}}  variant="contained" onClick={()=>{setInputObj({})}}>Clear All</Button>
      <Button style={{marginTop:"2%", marginBottom:"2%"}} fullWidth variant="contained" onClick={()=>{setPreview(!preview)}}>{preview?"Hide ":"Show " } Preview CSV mapping</Button>
      {preview && Object.keys(inputObj).length>0 && Object.keys(inputObj).some(key => inputObj[key] !== "")? <CsvPreviewMapping csvHeader={inputObj} array={array} properties={properties}/>: null}
    </Grid>
  )
}

function CsvMappingImages ({csvHeader, array, properties, inputObj, setInputObj}) {
  var csvHeader = csvHeader
  var array = array
  var properties = properties
  var inputObj = inputObj
  var setInputObj = setInputObj

  const [preview, setPreview] = React.useState(false)

  const handleSelect = (event, newValue, name, reason) => {
    var event = event
    var newValue = newValue
    var name = name
    var reason = reason

    if (reason == 'clear' && delete inputObj[name]) {
      var updatedObj = JSON.parse(JSON.stringify(inputObj))
      delete updatedObj[name]
      setInputObj(updatedObj)
    } else {
      var exists = Object.keys(inputObj).some(function(k) {
        return inputObj[k] === newValue
      });
      
      var keyToRemove
      if (exists) {
        // kung nag-eexist na, remove yung una sa inputObj
        keyToRemove = Object.keys(inputObj).find((key)=>inputObj[key] === newValue)
        delete inputObj[keyToRemove]
      } 
      
      if (newValue) {
        setInputObj({
          ...inputObj,
          [name]: newValue
        })
      } else {
        setInputObj(delete inputObj[name])
      }
    }
  };

  const {ratio, setRatio} = useContext(WindowContext)

  return (
    <Grid container spacing={2} style={{padding: 10, marginTop:10}}>
      <Grid item xs={12} md={3} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Label</Typography></Grid>
      <Grid item xs={12} md={9}><Typography variant="subtitle1" gutterBottom component="div" style={{fontWeight:700}}>Source Column</Typography></Grid>
      {properties?
        properties.map((key, index)=>
            <>
              <Grid key={"1-"+index} item xs={12} md={3} style={{display:"flex", flexDirection:"column", justifyContent:"flex-end"}}>
                <Typography variant="subtitle1" gutterBottom component="div" 
                  style={{fontWeight:700, textTransform:"capitalize"}}>{key.label.replace(/_/g, " ")} </Typography>
              </Grid>
              <Grid key={"2-"+index} item xs={12} md={9} style={{display:"flex", flexDirection:"column", justifyContent:"flex-start"}}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="combo-box-demo"
                  options={csvHeader}
                  key={key.property}
                  name={key.property}
                  defaultValue={key.property}
                  value={Object.keys(inputObj).length>0 && inputObj.hasOwnProperty(key.property) ?inputObj[key.property]:''}

                  onChange={(event, newValue, reason) => {
                    handleSelect(event, newValue, key.property, reason)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </>
        )
      :null}
      <Button style={{marginTop:"2%"}}  variant="contained" onClick={()=>{setInputObj({})}}>Clear All</Button>
      <Button style={{marginTop:"2%", marginBottom:"2%"}} fullWidth variant="contained" onClick={()=>{setPreview(!preview)}}>{preview?"Hide ":"Show " } Preview</Button>
      {preview && Object.keys(inputObj).length>0 && Object.keys(inputObj).some(key => inputObj[key] !== "")? <CsvPreviewMapping csvHeader={inputObj} array={array} properties={imgProperties}/>: null}
    </Grid>
  )
}

function CsvUploadDialog ({onClose}) {
  // source: https://dev.to/pankod/how-to-import-csv-file-with-react-4pj2
  const fileReader = new FileReader();
  const [file, setFile] = React.useState()
  const [csvHeader, setCsvHeader] = React.useState([])
  const [array, setArray] = React.useState([])
  const [properties, setProperties] = React.useState()
  const [inputCsvObj, setInputCsvObj] = React.useState({})
  const [inputZipObj, setInputZipObj] = React.useState({}) //for mapping of images
  const [showLP, setShowLP] = React.useState(false)
  const [zipFile, setZipFile] = React.useState()
 
  React.useEffect(()=> {
    const res = axios.get(`${Constants.BASE_URL}/api/tourism/get/touristattractions/properties`)
    .then((res)=> {
      setProperties(res.data)
    })
  }, [])
  
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);

    if (e.target.files[0]) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(e.target.files[0]);
    }

  };

  const handleZipChange = (e) => {
    setZipFile(e.target.files[0]);
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).replace(/(\r\n|\n|\r)/gm,'').split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const array = csvRows.map((i, index) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index2) => {
        object[header] = values[index2];
          return object;
      }, {});
      return obj;
    });

    setCsvHeader(csvHeader)
    setArray(array);

  };


  function getStepContent (stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <br></br>
            <React.Fragment>
              <Typography>Total no: of rows: {array.length}</Typography>
              <CsvPreview csvHeader={csvHeader} array={array} properties={csvHeader}/>
            </React.Fragment>
          </>
        )
      case 1:
        return (
          <CsvMapping csvHeader={csvHeader} array={array} properties={properties} inputObj={inputCsvObj} setInputObj={setInputCsvObj}/>
        )
      case 2:
        return (
          <React.Fragment>
            {/* <Typography>HEHEHE 2</Typography> */}
            <Button
              variant="contained"
              component="label"
              fullWidth
              style={{justifyContent: "flex-start", marginBottom:"1%"}}
            >
              <Typography style={{paddingRight:"1%"}}>Upload Zip File:</Typography>
              <Typography style={{borderRadius:5, borderWidth:1, borderStyle: "solid", borderColor:"white", padding:"1%", flexGrow:1, margin: "1%", textTransform: "none"}}>{zipFile? zipFile.name: "No File Chosen"}</Typography>
              <input
                type={"file"}
                id={"zipFileInput"}
                accept={".zip"}
                onChange={handleZipChange}
                style={{display:"none"}}
              />
              <IconButton onClick={()=>setZipFile()}>
                <CloseIcon/>
              </IconButton>
            </Button>
            {zipFile?<CsvMappingImages csvHeader={csvHeader} array={array} properties={imgProperties} inputObj={inputZipObj} setInputObj={setInputZipObj}/>:null}

          </React.Fragment>
        )
      case 3:
        return (
          <React.Fragment>
            {skipped.has(2)?
              <Typography variant="h5" style={{fontWeight: 700}}>
              Are you sure you want to skip <span style={{color:"red"}}> Step 3: Add Images</span> and proceed to uploading?
            </Typography>:
            <Typography variant="h5" style={{fontWeight: 700}}>
              Are you sure you want to proceed uploading <span style={{color:"red"}}>both the CSV and images</span>?
            </Typography>
            }
          </React.Fragment>
        )
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
      return step === 2;
  };

  const isStepSkipped = (step) => {
      return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);

    if (activeStep === steps.length-1) { // finishing na!

      // PROCESSING STEP 2 - MAP CSV DATA
      const formData = new FormData();
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };
      Object.keys(inputCsvObj).forEach(function(key) { 
        if (Array.isArray(inputCsvObj[key])) {
          inputCsvObj[key].forEach((i, index) => {
            formData.append(`categories[${index}]`, i)
          })
        } else {
          formData.append(key,inputCsvObj[key])
        }
      })
      formData.append('file', file)
     
      // PROCESSING STEP 2 - MAP CSV DATA


      // PROCESSING STEP 3 - ADD IMAGES
      if (!skipped.has(2)) {
        Object.keys(inputZipObj).forEach(function(key) { 
          if (Array.isArray(inputZipObj[key])) {
            inputZipObj[key].forEach((i, index) => {
              formData.append(`categories[${index}]`, i)
            })
          } else {
            formData.append(key,inputZipObj[key])
          }
        })
      
        formData.append('img_zip', zipFile)
      }
      //PROCESSING STEP 3 - ADD IMAGES

      const fetchData = async () => {
        setShowLP(true)
        const res = await axios.post(`${Constants.BASE_URL}/api/tourism/create/csv/touristattractions`, formData, config)
        .then((res) => {
          setShowLP(false)
          alert(res.data.message+'\n'+res.data.description)
          onClose()
        })
        
      }
      for (var pair of formData.entries()) {
      }
      fetchData()

      setActiveStep(activeStep)
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
      setActiveStep(0);
  };
  
  return (
    <React.Fragment>
      <BootstrapDialogTitle onClose={onClose} id="customized-dialog-title" component="h2" variant="h5" >
        Upload Tourism Attractions
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                  labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                  );
              }
              if (isStepSkipped(index)) {
                  stepProps.completed = false;
              }
              return (
                  <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
              );
          })}
      </Stepper>
      </BootstrapDialogTitle>
      {
        showLP?
        <LinearProgress />:null
      }
      <DialogContent dividers>
        {activeStep == 0 || activeStep == 1? 

        <Button
          variant="contained"
          component="label"
          fullWidth
          style={{justifyContent: "flex-start", marginBottom:"1%", backgroundColor: themes.colors.sub1}}
        >
          <Typography style={{paddingRight:"1%"}}>Upload CSV File:</Typography>
          <Typography style={{borderRadius:5, borderWidth:1, borderStyle: "solid", borderColor:"white", padding:"1%", flexGrow:1, margin: "1%", textTransform: "none"}}>{file? file.name: "No File Chosen"}</Typography>
          <input
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            style={{display:"none"}}
          />
          <IconButton onClick={()=>{setFile(); setActiveStep(0); setArray([])}}>
            <CloseIcon/>
          </IconButton>
        </Button>
        :null}
        {array.length>0?getStepContent(activeStep):null}
      </DialogContent>
     
      <DialogActions>
          {activeStep === steps.length ? (
              <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reset</Button>
                  </Box>
              </React.Fragment>
          ) : (
              <React.Fragment>
                  {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                      variant="outlined"
                      color="inherit"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      >
                      Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {isStepOptional(activeStep) && (
                      <Button variant="outlined" color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                      </Button>
                      )}

                      <Button onClick={handleNext} variant="contained"
                        disabled={
                          (activeStep===0 && !file) ||
                          (activeStep===1 && (Object.keys(inputCsvObj).length===0 || Object.keys(inputCsvObj).some(key=>inputCsvObj[key] === ""))) ||
                          (activeStep===2 && (Object.keys(inputZipObj).length!==3 || !zipFile))
                          }>
                        {activeStep === steps.length - 1 ? 'Upload' : 'Next'}
                      </Button>
                  </Box>
              </React.Fragment>
          )}
      </DialogActions>
    </React.Fragment>
  )
}

export default function CsvUpload () {
  const [openCsvDialog, setOpenCsvDialog] = React.useState(false)

  const handleClose = () => {
      setOpenCsvDialog(false);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" fullWidth onClick={()=>{setOpenCsvDialog(true)}}>Upload Tourism Attractions</Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openCsvDialog}
        fullWidth
        maxWidth="xl"
      >
        <CsvUploadDialog onClose={handleClose}/>
      </BootstrapDialog>
    </React.Fragment>
  )
}