import * as React from 'react';
import { TextField, DialogActions, DialogContent, Button, LinearProgress, Paper, Typography } from '@mui/material';
import { BootstrapDialog, BootstrapDialogTitle } from '../../components/dialog'

import { isDate18orMoreYearsOld, getBarangayList } from '../../core/functions'
import { BASE_URL, regExp, regExp_phoneNum } from '../../core'
import { themes } from '../../core/theme'

import { useHistory } from "react-router-dom";

import axios from 'axios';
import AccountInformation from './AccountInformation';
import ProfileInformation from './ProfileInformation';
import OrganizationInformation from './OrganizationInformation';

const styles = {
    signup: {
        backgroundColor: 'white',
        color: themes.colors.primary,
        fontFamily: "MulishBold",
        fontSize: '2vh',
        "&:hover" : {
            backgroundColor: "#80A1C1",
            color: 'white'
        },
        textTransform: "none"
    }
}

 function SignupDialog({open, onClose}) {
    const history = useHistory();

    const [next, setNext] = React.useState(false);
    const [currentBarangayList, setCurrentBarangayList] = React.useState([]);
    
    const [createdUser, setCreatedUser] = React.useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        birthday: "",
        phone_number: "",
        email: "",
        street_address: "",
        district: "",
        barangay: "",

        department_office: "",
        position: "",
        gov_id_type: "",
        gov_id_no: "",
        gov_id_img: "",

        username: "",
        password: "",
        isError: {
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            district: '',
            barangay: '',

            department_office: "",
            position: "",
            gov_id_type: "",
            gov_id_no: "",
            gov_id_img: "",

            username: '',
            password: ''
        }
    });
    const [date, setDate] = React.useState() // for birthday
    const [dateError, setDateError] = React.useState(true) // for birthday
    const [otp, setOTP] = React.useState('')
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [imgData, setImgData] = React.useState(null);
    const [showLP, setShowLP] = React.useState(false)

    const handleBirthday = event => {
        const offset = event.getTimezoneOffset()
        var event2 = new Date(event.getTime() - (offset*60*1000)) // nababawasan ng 1 day kapag normal iso string lang
        var dateNew = event2.toISOString('en-GB').split('T')[0] 
        var year = event2.getFullYear()
        var month = event2.getMonth()
        var day = event2.getDate()
        setDateError(isDate18orMoreYearsOld(day, month, year))
        setDate(event)
        setCreatedUser({ ...createdUser, birthday: dateNew})
    } 
   
    const formValChange = (e, q) => {
        e.preventDefault();
        const name = q;
        const value = e.target.value;

        switch (name) {
            case "first_name":
                createdUser.isError.first_name =
                    value === "" ? "This is a required field.":""
                break;
            case "middle_name":
                createdUser.isError.middle_name =
                    value ===  "" ? "This is a required field.":""
                break;
            case "last_name":
                createdUser.isError.last_name =
                    value === "" ?"This is a required field.":""
                break;
            case "username":
                createdUser.isError.username =
                    value === "" ?"This is a required field.":
                    value.length < 4 ? "At least 4 characters required":"";
                break;
            case "email":
                createdUser.isError.email = regExp.test(value)
                    ? ""
                    : "Email address is invalid";
                break;
            case "street_address":
                createdUser.isError.street_address =
                    value === "" ?"This is a required field.":""
                break;
            case "password":
                createdUser.isError.password =
                    value.length < 6 ? "At least 6 characters required" : "";
                break;
            case "phone_number":
                createdUser.isError.phone_number = regExp_phoneNum.test(value)
                    ? "" 
                    :"Input a ten-digit contact number.";
                break;
            case "department_office":
                setCreatedUser({
                    [name]: value.value
                })
                createdUser.isError.department_office =
                    value === "" ?"This is a required field.":""
                break;
            case "district":
                setCreatedUser({
                    [name]: value.value
                })
                createdUser.isError.district =
                    value === "" ?"This is a required field.":""
                setCurrentBarangayList(getBarangayList(value))
                break;
            case "barangay":
                createdUser.isError.barangay =
                    value === "" ? "This is a required field.":""
                break;
            case "position":
                createdUser.isError.position =
                    value === "" ? "This is a required field.":""
                break;
            case "gov_id_type":
                createdUser.isError.gov_id_type =
                    value === "" ? "This is a required field.":""
                break;
            case "gov_id_no":
                createdUser.isError.gov_id_no =
                    value === "" ? "This is a required field.":""
                break;
            case "gov_id_img":
                try {
                    setSelectedFile(e.target.files[0]);
                    var reader = new FileReader();
                    reader.addEventListener("load", () => {
                        setImgData(reader.result);
                    });
                    reader.readAsDataURL(e.target.files[0])
                } catch (err) {}
                createdUser.isError.gov_id_img =
                    value === "" ? "Please upload an image.":""
                break;
            default:
                break;
        }
        setCreatedUser({
            ...createdUser,
            [name]: value
        })
    };

    React.useEffect(()=>{
        if (currentBarangayList.length>0) {
            if (!currentBarangayList.includes(createdUser.barangay)) { // needed para pagnagbago yung list of district, magnunull yung barangay
                setCreatedUser({
                    ...createdUser,
                    barangay:"",
                })
            }
        }
    }, [currentBarangayList])

    const handleSendVerificationCode = async (e) => {
        e.preventDefault();
        const formData = {
            email: createdUser.email,
            username: createdUser.username
        }
        var {isError, ...createdUser2} = createdUser; // removes gov_id_img and isError from createdUser and store it in createdUser 2
        
        var lenEmpty = 0 // number of unfilled/empty inputs
        var lenError = 0 // number of invalid inputs

        Object.keys(createdUser2).forEach(function(key, index) {
            if (!createdUser2[key]) {
                formValChange(e, key)
                if (key === "birthday") {
                    setDateError(false)
                }
                lenEmpty+=1
            }
        })

        Object.keys(createdUser.isError).forEach(function(key, index) {
            if (createdUser.isError[key]) {
                lenError+=1
            }
        })
        if (lenEmpty === 0 && lenError === 0 && dateError) {
            setShowLP(true)
            await axios.post(`${BASE_URL}/api/command_user/sendverificationemail`, formData)
            .then((res)=>{
                if (res.data.message === "Verification email sent") {
                    setNext(true)
                }
                alert(res.data.description)
                setShowLP(false)
            })
            .catch((err) => {
            })
        }
        
    };
    const handleSignup = async (e) => {
        setShowLP(true)
        var {isError, ...createdUser2} = createdUser; // removes isError from createdUser and store it in createdUser 2
        createdUser2['token'] = otp
        console.log("handleSignup fetchData2 createdUser2", createdUser2)
        await axios.post(`${BASE_URL}/api/command_user/createandverify`, createdUser2)
        .then((res)=>{
            alert(res.data.message+"\n"+res.data.description)
            setShowLP(false)
            if (res.data.message === "Sign-up successful") {
                setNext(false)
                onClose()
            }
            fetchData3()

        })

        const fetchData3 = async () => {
            await axios.post(`${BASE_URL}/api/command_user/login`, {
                "password": createdUser.password,
                "username": createdUser.username})
            .then((res)=>{
                if (res.data.message !== "Log-in failed") {
                    alert(res.data.user_id)
                    localStorage.setItem('user', JSON.stringify(res.data))
                    uploadImage(e, res.data.user_id)
                } else {
                    alert("Log in failed")
                }
            })
        }
    }

    const uploadImage = async (e, id) => {
        e.preventDefault()
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        
        formData.append("img", selectedFile)
        formData.append("tag", "command_user_id")
        formData.append("tag_id", id)
       
        await axios.post(`${BASE_URL}/api/image/create`, formData, config)
        .then((res) => {
            history.push('/main')
        })
    }

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="sm"
            PaperProps={{
                sx: {
                    overflowY: "hidden" // needed kasi may extra space sa baba
                }
            }}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} component="h2" variant="h5" >
                Register an Account
            </BootstrapDialogTitle>

            {open && showLP? <LinearProgress />:null}

            {next?
                <DialogContent dividers >
                    <Typography gutterBottom>
                        To continue, enter the one-time Verification Code we just sent to your email.
                    </Typography>
                    <TextField value={otp} onChange={(e)=>setOTP(e.target.value)} fullWidth size="small" autoFocus></TextField>

                </DialogContent>
                :
                <DialogContent dividers >
                    <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                        <Typography component="h2" variant="h6" gutterBottom >
                            <strong>Profile </strong>
                        </Typography>

                        <ProfileInformation 
                            createdUser={createdUser} 
                            date={date}
                            dateError={dateError}
                            currentBarangayList={currentBarangayList}
                            formValChange={formValChange} 
                            handleBirthday={handleBirthday}
                        />
                    </Paper>
                    <br/>

                    <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                        <Typography component="h2" variant="h6" gutterBottom >
                            <strong>Organization Information </strong>
                        </Typography>
                        <OrganizationInformation 
                            createdUser={createdUser} 
                            setCreatedUser={setCreatedUser} 
                            imgData={imgData}
                            setImgData={setImgData}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            formValChange={formValChange} 
                        />
                    </Paper>

                    <br/>
                    <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                        <Typography component="h2" variant="h6" gutterBottom >
                            <strong>Account Information </strong>
                        </Typography>
                        <AccountInformation 
                            createdUser={createdUser}
                            formValChange={formValChange}
                        />
                    </Paper>
                </DialogContent>
            }

            <DialogActions>
                {next?
                    <>
                        <Button onClick={()=>setNext(false)}>Back</Button>
                        <Button 
                            onClick={handleSignup} 
                            variant='contained' 
                            disabled={!otp}
                        >
                            Sign up
                        </Button>
                    </>
                    :
                    <Button 
                        onClick={handleSendVerificationCode} 
                        variant='contained' 
                    >
                        Next
                    </Button>
                }
            </DialogActions>
        </BootstrapDialog>
    )
  }

export default function Signup(props) {
    
    const [open, setOpen] = React.useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };
    
  return (
        <div>
            <Button
                onClick={handleClickOpen}
                type="submit"
                fullWidth
                variant="outlined"
                sx={{ ...styles.signup }}
                >
                Sign up
            </Button>
            <SignupDialog open={open} onClose={handleClose}/>
        </div>
  );
}
