import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, Paper, TextField, 
    FormControl, MenuItem, Select, Skeleton, 
    CardMedia, IconButton, Box, CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import axios from 'axios';

import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';
import { djangoToJsDate, getTokenAndNotifyResolved, getTokenAndNotifySeen, getTokenAndNotifyRemarks } from '../../../core/functions';
import Carousel from 'react-material-ui-carousel'
import { Logs } from './Logs';


const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.greyTxt,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    resolved: {
        backgroundColor: themes.colors.primary, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    unresolved: { 
        backgroundColor: themes.colors.tertiary, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    verified: {
        backgroundColor: themes.colors.primary,
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    unverified: {
        backgroundColor: themes.colors.greyTxt, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
}


export default function ReportDetails () {

    const {
        selectedRow, setSelectedRow, 
        reportsAll, setReportsAll, 
        selectedImgArray, setSelectedImgArray, 
        newReports, setNewReports,
    } = useContext(RaiContext);
    const [isImgArrayLoading, setIsImgArrayLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [statusValue, setStatusValue] = useState(selectedRow.status) //sa select/ dropdown
    const [isVerifiedValue, setIsVerifiedValue] = useState(selectedRow.is_verified==="false"?"Unverified":"Verified") //sa select/ dropdown
    const [remarksValue, setRemarksValue] = useState(selectedRow.remarks) //sa select/ dropdown
    const [moduleName, setModuleName] = useState(selectedRow.hasOwnProperty('method')?"emergency":"report") //sa select/ dropdown
    const [logRows, setLogRows] = React.useState()
    const [loading, setLoading] = useState(false)


    const getImagesByTagAndTagId = async (tag, tag_id) => {
        setIsImgArrayLoading(true)
        const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, {
          "tag": tag,
          "tag_id": tag_id
        })
        .then((res)=> {
          if (res.data.count>0) {
            setSelectedImgArray(res.data.values)
          } else {
            setSelectedImgArray(null)
          }
          setIsImgArrayLoading(false)
        })
    }
    
    const getLogRows= async (report_id) => {
        const res = await axios.get(`${Constants.BASE_URL}/api/logs/get/commanduser/model_id`, { params : {
            "model_id": report_id
        }})
        .then((res) => {
            setLogRows(res.data)
        })
    }

    const createCommandUserLog = (action, report, model_edits) => {
        var par 
        if (action.includes("edit")) {
            par = {
                "user_id": JSON.parse(localStorage.getItem('user')).user_id,
                "model_name": report.hasOwnProperty('method')?"Emergency":"Report",
                "model_id": report.id,
                "model_reference_no": report.reference_no,
                "model_edits": model_edits.length>0?model_edits:[],
                "action": action
            }
        } else {
            par = {
                "user_id": JSON.parse(localStorage.getItem('user')).user_id,
                "model_name": report.hasOwnProperty('method')?"Emergency":"Report",
                "model_id": report.id,
                "model_reference_no": report.reference_no,
                "action": action
            }
        }

    
        const res = axios.post(`${Constants.BASE_URL}/api/logs/create/commanduser`, par)
        .then((res)=> {
            getLogRows(report.id)

        })
    }

    useEffect(() => { // needed kapag isEditing ang state tapos nagpalit ng selected row/ nagclose.
        if (selectedRow) {
          getImagesByTagAndTagId(moduleName, selectedRow.id)
          getLogRows(selectedRow.id)
          // yung newEmergency, newReports, notify when seen
        }
        setIsEditing(false)

    }, [selectedRow, getImagesByTagAndTagId, moduleName])


    useEffect(() => {

        if (logRows) {
            if (newReports.count>0) {
                var newArr = newReports.values.filter(val => val.id !== selectedRow.id) 
                if (newArr.length < newReports.count) {
                    setNewReports({
                        count: newArr.length,
                        values: newArr
                    })
                }
                if (newReports.values.some((val) => val.id === selectedRow.id)) {
                    createCommandUserLog("seen", selectedRow)
    
                    if (logRows.length===0) {
                        getTokenAndNotifySeen(selectedRow.id, selectedRow.username, moduleName, selectedRow.reference_no)
                    }
                }
            } else {

            }
        }
    }, [logRows, newReports, createCommandUserLog, moduleName, selectedRow, setNewReports])

    const handleStatus = (event) => {
        setStatusValue(event.target.value); // Resolved/ Unresolved
    };
    const handleIsVerified= (event) => {
        setIsVerifiedValue(event.target.value); // Verified/ Unverified
    };
    const handleRemarks = (event) => {
        setRemarksValue(event.target.value); 
    };
    const handleUndo = () => {
        setIsEditing(false); 
        setStatusValue(selectedRow.status)
        setIsVerifiedValue(selectedRow.is_verified==="false"?"Unverified":"Verified")
        setRemarksValue(selectedRow.remarks); 
    }
    const handleSave = async (id, status, remarks, is_verified, moduleName) => {
        setLoading(true)
        var verification // kabaligtaran ng sa handleUndo
        if (is_verified === "Verified") {
            verification = "true"
        } else {
            verification = "false"
        }
        
            const res = await axios.post(`${Constants.BASE_URL}/api/reports/edit/status`, {
                "id": id,
                "status": status,
                "remarks": remarks,
                "is_verified": verification
            })
            .then((res) => {
                if (res.data.message === "Item successfully edited") {
                    setLoading(false)

                    var newReportsAll= reportsAll.values.map(obj => res.data.values.find(o => o.id === obj.id) || obj)
                    setReportsAll({ // // pagka-edit and save
                        count: newReportsAll.length,
                        values: newReportsAll})
                    // start: for logging the edits
                    var model_edits = []
                    if (status !== selectedRow.status) {model_edits.push(['status', status])}
                    if (remarks !== selectedRow.remarks) {
                        model_edits.push(['remarks', remarks])
                        getTokenAndNotifyRemarks(id, selectedRow.username, moduleName, selectedRow.reference_no, remarks)
                    }
                    if (verification !== selectedRow.is_verified) {model_edits.push(['verified', verification])}
                    createCommandUserLog("edit", res.data.values[0], model_edits)
                    // end: for logging the edits
                    setSelectedRow(res.data.values[0])
                }
            })
            
        // }
        if (status !== selectedRow.status && status === "Resolved") { // notify once, when resolved.
            getTokenAndNotifyResolved(id, selectedRow.username, moduleName, selectedRow.reference_no)
        }
    }
    
    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                    maxHeight: "90vh",
                    marginBottom: 20
                }}
            >
                <Grid container direction="row" alignItems="center" style={{padding:"2vh"}}>

                    {/* BACK BUTTON */}
                    <Grid item>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={()=>setSelectedRow(null)}
                            aria-label="close"
                            >
                            <ArrowBackIosIcon fontSize='large'/>
                        </IconButton>
                    </Grid>

                    {/* NAME OF ATTRACTION */}
                    <Grid item>
                        <Typography style={styles.title}>Case ID {selectedRow.reference_no}</Typography>
                    </Grid>
                </Grid> 
                
                <Grid container style={{ height: '92%', width: '100%', paddingTop: '1vh', overflow: "auto"}}>
                    {/* IMAGES - carousel */}
                    <Grid container sx={{alignItems: 'center', justifyContent: 'center'}}>
                        {isImgArrayLoading?
                            <Skeleton variant="rectangular" height={"30vh"} width={"100%"} />:
                            selectedImgArray?selectedImgArray.length>0?
                                <Carousel sx={{width:"100%", height:300}} cycleNavigation={false}
                                    indicatorIconButtonProps={{style: { position:"relative", bottom:"2vh"}}}>
                                    {
                                        selectedImgArray.map((img, i) => 
                                            <CardMedia
                                                component="img"
                                                image={img['img']}
                                                alt="img[0]"
                                                style={{borderRadius:10, padding:5, height:"100%", width:"98%", height:270}}
                                            /> )
                                    }
                                </Carousel>
                                
                                : null
                            :
                            <Paper sx={styles.attractionPic} elevation={3}>
                                <Grid container style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={styles.attractionLabelText}>NO IMAGE AVAILABLE</Typography>
                                </Grid>
                            </Paper>
                        }
                    
                    </Grid>

                    {/* ATTRACTION DESCRIPTIONS */}
                    <Grid container style={{height: '60%', width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Contact No:`}</Typography>
                            <Typography display="inline" sx={styles.text}>{selectedRow.contact_no? `+63${selectedRow.contact_no}`:"------" }</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Location:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.address}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Date:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{djangoToJsDate(selectedRow.date)[0]}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Time:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{djangoToJsDate(selectedRow.date)[1]}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`District:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.district}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Category:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.category}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary}}>{`Description:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.desc | selectedRow.desc != "null"?selectedRow.desc:""}</Typography>
                        </Grid>

                        {/* EDITABLE ATTRIBUTES - STATUS */}
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Status:`}</Typography>
                            {isEditing && selectedRow.status !== "Resolved" ?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedRow.status}
                                        onChange={handleStatus}
                                        size='small'
                                    >
                                        <MenuItem value={"Resolved"}>
                                            <Typography sx={{...styles.resolved, width: "95%"}}>
                                                Resolved
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={"Unresolved"}>
                                            <Typography sx={{...styles.unresolved, width: "95%"}}>
                                                Unresolved
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...selectedRow.status==="Resolved"?styles.resolved:styles.unresolved, whiteSpace: 'pre-line', width: '60%', textAlign:'right', marginTop: '0.5vh'}}>{selectedRow.status}</Typography>
                            }
                        </Grid>

                        {/* EDITABLE ATTRIBUTES - VERIFICATION */}
                        <Grid container style={{ width: '100%', justifyContent:"space-between"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary}}>{`Verification:`}</Typography>
                            {isEditing && selectedRow.is_verified==="false"?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="is-verified"
                                        id="is-verified-select"
                                        defaultValue={selectedRow.is_verified==="false"?"Unverified":"Verified"}
                                        onChange={handleIsVerified}
                                        size='small'
                                        >
                                        <MenuItem value={"Verified"}>
                                            <Typography sx={{...styles.verified, width: "95%"}}> 
                                                Verified
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={"Unverified"} >
                                            <Typography sx={{...styles.unverified, width: "95%"}}>
                                                Unverified
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...selectedRow.is_verified==="false"? styles.unverified : styles.verified, whiteSpace: 'pre-line', width: '60%', textAlign:'right', marginTop: '0.5vh'}}>{selectedRow.is_verified==="false"? "Unverified" : "Verified"}</Typography>
                            }
                        </Grid>

                        {/* EDITABLE ATTRIBUTES - REMARKS */}
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Remarks:`}</Typography>
                            {isEditing?
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    defaultValue={selectedRow.remarks}
                                    onChange={handleRemarks}
                                    style={{width: "100%"}}
                                />:
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    value={selectedRow.remarks}
                                    style={{width: "100%"}}
                                    disabled
                                />
                            }
                            
                        </Grid>
                      
                        {/* EDIT & SAVE/UNDO BUTTONS */}
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>
                            {isEditing?
                                <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                    <Box sx={{ position: 'relative', width:"48%" }}>
                                    <Button style={{width:"100%"}}
                                        disabled={
                                            (statusValue === selectedRow.status) && (remarksValue === selectedRow.remarks) && 
                                            (isVerifiedValue === (selectedRow.is_verified==="false"?"Unverified":"Verified")) ||
                                            loading
                                        }
                                        onClick={()=>handleSave(selectedRow.id, statusValue, remarksValue, isVerifiedValue, moduleName)} variant="contained">
                                            Save Changes
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: themes.colors.primary,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                    </Box>
                                    <Button style={{width:"50%"}} onClick={handleUndo} variant="outlined">Undo</Button>
                                </Grid> :
                                JSON.parse(localStorage.getItem('user')).user_type == "Admin" ?
                                    <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                        <Button fullWidth onClick={()=>setIsEditing(true)} variant="contained">Edit</Button>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                        <Grid container style={{ width: '100%', height: '5%'}} />
                       
                        {/* ADMIN LOGS */}
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginBottom: "2%" }}>
                            <Typography style={{fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginBottom:"2%"}}>Logs</Typography>
                            <Logs rows={logRows} />
                            
                        </Grid>
                        <Grid container style={{ width: '100%', height: '5%'}} />
                    </Grid>
                    
                </Grid>
            </Paper>
        </Grid>
    );
}

