import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Typography, Paper } from '@mui/material';
import { RaiContext } from '../../../../contexts/RaiContext';
import { themes } from '../../../../core/theme';
import DateRangePickerRND from './dateRangePicker'
import CheckboxCategory from './checkboxCategory';
import MultipleSelectDistrict from './multipleSelectDistrict';
import axios from 'axios';
import * as Constants from '../../../../core'

const styles = {
  text: {
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
    color: themes.colors.filterText
  }
}

export default function FilterDrawer(props) {

  const noZoom = false || props.noZoom;

  const {
    reportsAll, setReportsAll, 
    reportsShow, setReportsShow, 
    checkReports, setCheckReports,
    newReports, setNewReports,

    checked, setChecked, 
    startDateLocal, setStartDateLocal, 
    endDateLocal, setEndDateLocal,
    startDate, setStartDate, 
    endDate, setEndDate, 
    districtName, setDistrictName, 
    checkedAll, setCheckedAll, 
    mapRef, groupRef,
    brownout, setBrownout,
    wasteManagement, setWasteManagement,
    engineeringAndConstruction, setEngineeringAndConstruction,
    traffic, setTraffic,
    water, setWater,
    others, setOthers,
    verificationStatus, setVerificationStatus
  } = useContext(RaiContext)


  // start: Jan 24: checkbox category for report status
  const status = [
    {
        Parent: 'Status', 
        Child: ['Resolved', 'Unresolved'],
        Context: [checked, setChecked]
    }, 
  ]
  // end: Jan 24: checkbox category for report status

  // start: Feb 03: checkbox category for report verification status
  const verificationCat = [
    {
        Parent: 'Verification Status', 
        Child: ['Verified', 'Unverified'],
        Context: [verificationStatus, setVerificationStatus]
    }, 
  ]
  // end: Feb 03: checkbox category for report verification status

  // start: Jan 24: Categories for RAI categories
  const raiCat = [
    {
      Parent: 'Brownout',
      Child: ['Unscheduled brownout', 'Gakaguba amon appliances', 
          'Patay siga ang kuryente', 'Kadako sang pagsaka sang bill', 
          'Taas ang cost sang kuryente per kilowatt hour'],
      Context: [brownout, setBrownout]
    },
    {
      Parent: 'Engineering and Construction',
      Child: ['Broken streetlights', 'Broken water pipes', 
          'Overgrown tree branches', 'Potholes', 'Sewage blockage', 
          'Tangled electric wires', 'Uncovered/destroyed manholes'],
      Context: [engineeringAndConstruction, setEngineeringAndConstruction]
    },
    {
        Parent: 'Traffic',
        Child: [
          'Disregard of traffic signs', 
          'Hit and Run',
          'Illegal counterflow',
          'Illegal parking',
          'Improper loading/unloading',
          'Obstruction of road passage',
          'Road crash collision',
          'Traffic standstill more than 15 mins',
        ],
        Context: [traffic, setTraffic]
    },
    {
        Parent: 'Waste Management', 
        Child: ['Improper spilling', 'Litter', 'Pet waste', 'Vegetative waste'],
        Context: [wasteManagement, setWasteManagement]
    }, 
    {
      Parent: 'Water', 
      Child: ['Temporary loss of water', 'Total loss of water'],
      Context: [water, setWater]
    }, 
    {
        Parent: 'Others',
        Context: [others, setOthers]
    }
  ]
  // end: Jan 24: Categories for RAI categories

  // start: check if there is a new report every 3000 millisec using the route - check/new
  const fetchData = async () => {
    const res = await axios.get(`${Constants.BASE_URL}/api/reports/check/new`, { params: {
      "id": JSON.parse(localStorage.getItem('user')).user_id,
    }})
    .then((res)=> {
      if (res.data.count>0) {
        setCheckReports(res.data)
      } else {
        setCheckReports({count:0, values: []})
      }
      
    })
  }
  // end: check if there is a new report every 3000 millisec using the route - check/new

  // start: for getting old data, called only once
  const fetchData2 = async () => {
    const res = await axios.get(`${Constants.BASE_URL}/api/reports/get/old`, { params: {
      "id": JSON.parse(localStorage.getItem('user')).user_id,
    }})
    .then((res)=> {
      if (res.data.count>0) {
        setReportsAll(res.data) // pagkarating
      } else {
        setReportsAll({count:0, values: []})
      }
      
    })
  }
  // end: for getting old data, called only once
 
  // start: On initial mount
  useEffect(() => { 
      fetchData()
      fetchData2()
  }, [])
  // end: On initial mount

  useEffect(()=>{
    if (reportsAll) {
    }
  }, [reportsAll])

  useEffect(()=>{
    if (reportsAll) {
    }
  }, [reportsShow])

  // start: refresh every 3000 milliseconds
  useEffect(() => { // on succeeding loads, interval is every 5 seconds. prevents snackbar to notify repeatedly
    const interval = setInterval(() => {
      fetchData()
    }, 3000)
    return()=>clearInterval(interval)
  }, [checkReports, newReports]) 
  // end: refresh every 3000 milliseconds

  //  start: needed Jan 24 updates reportsShow on save report details
  useEffect(() => {
    if (reportsAll.count) {
      var keys = Object.keys(checked);
      var filtered = keys.filter(function(key) { // returns all keys with value == true
          return checked[key]
      });
      var filteredReports = reportsAll.values.filter(function(report) {
        for (var i = 0; i < filtered.length; i++){  // check in
          if (report.status === filtered[i].replace(/_/g,' ')) { // replaces underscore with space
            return true
          }
        }
      })
      

      // start: verification
      var keysVerification = Object.keys(verificationStatus);
      var filteredVerification = keysVerification.filter(function(key) { // returns all keys with value == true
        return verificationStatus[key]
      });
      filteredReports = filteredReports.filter(function(report) {
        // return all reports
        for (var i = 0; i < filteredVerification.length; i++){  // check in
          if (filteredVerification[i] == "Verified") {
            if (report.is_verified === "true") { // replaces underscore with space
              return true
            }
          } else if (filteredVerification[i] == "Unverified") {
            if (report.is_verified === "false") { // replaces underscore with space
              return true
            }
          }
          
        }
      })
      // end: verification

      if (startDate != undefined && endDate != undefined) {
        filteredReports = filteredReports.filter(function(report) {
          var date = report.date.replace(/-/g,'');
          date = date.substring(0, date.indexOf('T'));
  
          if (startDate <= date && date <= endDate) { 
            return true
          }
        })
      }
  
      if (districtName.length>0) {
        filteredReports = filteredReports.filter(function(report) {
          if (report.district != null) {
  
            for (var i = 0; i < districtName.length; i++){  // check in
              if (report.district.includes(districtName[i].replace(/_/g,' '))) { // replaces underscore with space
                return true
              }
            }
          }
        })
      }
  
      // start: getting all rep issues & category=others (saved as Union)
      var keys2 = Object.keys(wasteManagement);
      var filtered2 = keys2.filter(function(key) { // returns all keys with value == true
        return wasteManagement[key]
      });
      var keys3 = Object.keys(engineeringAndConstruction);
      var filtered3 = keys3.filter(function(key) { // returns all keys with value == true
        return engineeringAndConstruction[key]
      });
      var keys4 = Object.keys(traffic);
      var filtered4 = keys4.filter(function(key) { // returns all keys with value == true
        return traffic[key]
      });
      var keys5 = Object.keys(brownout);
      var filtered5 = keys5.filter(function(key) { // returns all keys with value == true
        return brownout[key]
      });
      var keys6 = Object.keys(water);
      var filtered6 = keys6.filter(function(key) { // returns all keys with value == true
        return water[key]
      });
  
      var combinedFilters = filtered6.concat(filtered2, filtered3, filtered4, filtered5)
      // either or
      var filteredReports2 = reportsAll.values.filter(function(report) {
        for (var i = 0; i < combinedFilters.length; i++){  // check in
          if (report.issue === combinedFilters[i].replace(/_/g,' ')) { // replaces underscore with space
            return true
          }
        }
      })
      if (others) {
        var filteredReports3 = reportsAll.values.filter(function(report) {
          if (report.category === "Others".replace(/_/g,' ')) { // replaces underscore with space
            return true
          }
        })
      }
      var union
      if (filteredReports2 && filteredReports3) {
        union = [...new Set([...filteredReports3, ...filteredReports2])]; // union of all categories/ subcat and others
      } else if (filteredReports2) {
        union = filteredReports2
      } else if (filteredReports3) {
        union = filteredReports3
      }
      // source: union https://stackoverflow.com/questions/3629817/getting-a-union-of-two-arrays-in-javascript/10413596
      // end: getting all rep issues & category=others (saved as Union)
  
      // intersection of union and filteredReports
      var intersection = union.filter(x => filteredReports.includes(x))
      intersection = intersection.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      });
      // how to sort an array of objects:
      // source: https://stackoverflow.com/questions/37982476/how-to-sort-a-map-by-value-in-javascript
      setReportsShow({ // 3. React.useEffect onClickReset
        count: intersection.length,
        values: intersection}) // needed
    }
  }, [reportsAll])
  //  end: needed Jan 24 updates reportsShow on save report details

  const onClickReset = (event) => {
    // start: setting everything to true agen
    for (const key in checked) {
      if (key === "Unresolved") {checked[key] = true}
      else {checked[key] = false}
    }
    for (const key in verificationStatus) {
      verificationStatus[key] = true
    }
    for (const key in wasteManagement) {
      wasteManagement[key] = true
    }
    for (const key in engineeringAndConstruction) {
      engineeringAndConstruction[key] = true
    }
    for (const key in traffic) {
      traffic[key] = true
    }
    for (const key in brownout) {
      brownout[key] = true
    }
    for (const key in water) {
      water[key] = true
    }
    setOthers(true)
    // end: setting everything to true agen
   
    var filtered1 = ["Unresolved"]
    var filteredReports = reportsAll.values.filter(function(report) {
      // return all reports
      for (var i = 0; i < filtered1.length; i++){  // check in
        if (report.status === filtered1[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })

    filteredReports = filteredReports.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    });
    
    setReportsShow({ // 2. onClickReset
      count: filteredReports.length,
      values: filteredReports}) // needed
    setDistrictName([])
    setStartDateLocal()
    setEndDateLocal()
    setStartDate()
    setEndDate()
  }

  const onClickFilter = () => {
    var filteredReports 

    // start: status
    var keys1 = Object.keys(checked);
    var filtered1 = keys1.filter(function(key) { // returns all keys with value == true
      return checked[key]
    });
    filteredReports = reportsAll.values.filter(function(report) {
      // return all reports
      for (var i = 0; i < filtered1.length; i++){  // check in
        if (report.status === filtered1[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })
    // end: status
    // https://stackoverflow.com/questions/25095789/return-an-object-key-only-if-value-is-true
    


    // start: verification
    var keysVerification = Object.keys(verificationStatus);
    var filteredVerification = keysVerification.filter(function(key) { // returns all keys with value == true
      return verificationStatus[key]
    });
    filteredReports = filteredReports.filter(function(report) {
      // return all reports
      for (var i = 0; i < filteredVerification.length; i++){  // check in
        if (filteredVerification[i] == "Verified") {
          if (report.is_verified === "true") { // replaces underscore with space
            return true
          }
        } else if (filteredVerification[i] == "Unverified") {
          if (report.is_verified === "false") { // replaces underscore with space
            return true
          }
        }
        
      }
    })
    // end: verification


    // start: date
    if (startDate != undefined && endDate != undefined) {
      filteredReports = filteredReports.filter(function(report) {
        // var date = fn.parseDate(report.date)
        var date = report.date.replace(/-/g,'');
        date = date.substring(0, date.indexOf('T'));

        if (startDate <= date && date <= endDate) { 
          return true
        }
      })
    }
    // end: date

    // start: district
    if (districtName.length>0) {
      filteredReports = filteredReports.filter(function(report) {
        if (report.district != null) {

          for (var i = 0; i < districtName.length; i++){  // check in
            if (report.district.includes(districtName[i].replace(/_/g,' '))) { // replaces underscore with space
              return true
            }
          }
        }
      })
    }
    // end: district

    // start: getting all rep issues & category=others (saved as Union)
    var keys2 = Object.keys(wasteManagement);
    var filtered2 = keys2.filter(function(key) { // returns all keys with value == true
      return wasteManagement[key]
    });
    var keys3 = Object.keys(engineeringAndConstruction);
    var filtered3 = keys3.filter(function(key) { // returns all keys with value == true
      return engineeringAndConstruction[key]
    });
    var keys4 = Object.keys(traffic);
    var filtered4 = keys4.filter(function(key) { // returns all keys with value == true
      return traffic[key]
    });
    var keys5 = Object.keys(brownout);
    var filtered5 = keys5.filter(function(key) { // returns all keys with value == true
      return brownout[key]
    });
    var keys6 = Object.keys(water);
    var filtered6 = keys6.filter(function(key) { // returns all keys with value == true
      return water[key]
    });

    var combinedFilters = filtered6.concat(filtered2, filtered3, filtered4, filtered5)
    // either or
    var filteredReports2 = reportsAll.values.filter(function(report) {
      for (var i = 0; i < combinedFilters.length; i++){  // check in
        if (report.issue === combinedFilters[i].replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      }
    })
    if (others) {
      var filteredReports3 = reportsAll.values.filter(function(report) {
        if (report.category === "Others".replace(/_/g,' ')) { // replaces underscore with space
          return true
        }
      })
    }
    var union
    if (filteredReports2 && filteredReports3) {
      union = [...new Set([...filteredReports3, ...filteredReports2])]; // union of all categories/ subcat and others
    } else if (filteredReports2) {
      union = filteredReports2
    } else if (filteredReports3) {
      union = filteredReports3
    }
    // source: union https://stackoverflow.com/questions/3629817/getting-a-union-of-two-arrays-in-javascript/10413596
    // end: getting all rep issues & category=others (saved as Union)

    // intersection of union and filteredReports
    var intersection = union.filter(x => filteredReports.includes(x))
    intersection = intersection.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    });
    setReportsShow({ // 1. onClickFilter
      count: intersection.length,
      values: intersection}) // needed
  }
  
  
  const zoomToSelected = () => {
    if (mapRef.current) {
      var map = mapRef.current
      var group = groupRef.current
      map.fitBounds(group.getBounds());
    }
  }

  return (

    // MAIN CONTAINER
    <Grid container spacing={0}>
      <Paper
        square
        elevation={0}
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "white",
          maxHeight: "90vh",
          marginBottom: 20
        }}
      >
        {/* FILTERS COMPONENT */}
        <Grid
          container
          style={{
            flex: 4,
            height: '88%',
            width: '100%',
            paddingTop: '1vh',
            overflowY: "auto",
            overflowX: "clip",
          }}
        >
          <Typography style={styles.text}>Filter by Date:</Typography>
          <Grid item xs={12} style={{marginBottom: 10, paddingInline: 4}}>
            <DateRangePickerRND/>
          </Grid>

          <Typography style={styles.text}>Filter by District:</Typography>
          <Grid item xs={12} style={{marginBottom: 10, paddingInline: 4}}>
            <MultipleSelectDistrict/>
          </Grid>

          <Typography style={styles.text}>Filter by Status:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {status?status.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>
          
          <Typography style={styles.text}>Filter by Verification Status:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {verificationCat?verificationCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>

          <Typography style={styles.text}>Filter by Report Category:</Typography>
          <Grid item xs={12} style={{marginBottom: 10}}>
            {raiCat?raiCat.map((item, index)=> {
              return(
                <CheckboxCategory value={item} key={item+index} />
              )
            })
            :null}
          </Grid>
        </Grid>

        {/* BUTTON FUNCTIONS */}
        <Grid container style={{ height: '12%', width: '100%', alignItems: 'center'}}>
          <Grid container direction="row" justifyContent="space-between">
            <Button style={{...styles.text, color:"white", width:"48%"}} variant="contained" onClick={onClickFilter}>Filter</Button>
            <Button style={{...styles.text, color:themes.colors.primary, width:"48%"}} variant="outlined" onClick={onClickReset}>Reset</Button>
          </Grid>
          
          {!noZoom ? 
          <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
            <Button style={{...styles.text, color:themes.colors.primary, width:"100%"}} variant="outlined" onClick={zoomToSelected}>Zoom to bounds</Button>
          </Grid> : null}
        </Grid>
      </Paper>     
    </Grid>
  );
}
