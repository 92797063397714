import React, { useContext, useState, useEffect } from 'react';
import { Grid, Typography, Paper, IconButton, Button, TextField, 
    Box, CircularProgress, Tooltip, Skeleton, CardMedia } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {themes} from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';
import axios from 'axios';
import * as Constants from '../../../core'
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import Carousel from 'react-material-ui-carousel'
import { CloseOutlined, AddPhotoAlternateOutlined, PausePresentationSharp } from '@mui/icons-material';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.tertiary,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    imgIconButton: {
        "&:hover" : {
            opacity: 0.7, 
            backgroundColor: themes.colors.primary, 
        },
        opacity: 0.8, 
        backgroundColor: themes.colors.primary, 
        color: "white",
    }
}

const AddAttractionsBar = () => {
    
    const { attractionsAll, currentAttractionsUnderTour, setCurrentAttractionsUnderTour } = useContext(TourismContext);
    const [filteredAttractions, setFilteredAttractions] = useState([])
    const [searchVal, setSearchVal] = useState('')

    useEffect(()=>{
        if (attractionsAll && attractionsAll.values) {
            if (searchVal == "") {
                setFilteredAttractions(attractionsAll.values)
                return
            }
            var filtered = attractionsAll.values.filter(item => item.name.toLowerCase().includes(searchVal.toLowerCase()));
            setFilteredAttractions(filtered)
        } 
        else {
            setFilteredAttractions([])
        }
    }, [attractionsAll, searchVal])

    const searchFilter = (event) => {
        var text = event.target.value
        setSearchVal(text)
    }

    const addAttraction = (attraction) => {
        setCurrentAttractionsUnderTour(arr => [...arr, attraction].sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
    }

    const removeAttraction = (attraction) => {
        setCurrentAttractionsUnderTour(arr => arr.filter(e => e !== attraction))
    }

    return(
        <>
        <TextField id="outlined-basic" label="Search by Name" variant="outlined" fullWidth style={{marginTop:"2vh"}}
                onChange={text=>searchFilter(text)} value={searchVal}/>
        <Paper
            square
            elevation={0}
            direction='column'
            style={{
            height: "100%",
            width: "100%",
            backgroundColor: themes.colors.sub3,
            maxHeight: "50vh",
            marginBottom: 20,
            overflow: 'scroll',
            flexDirection: 'column'
            }}
        >
            {currentAttractionsUnderTour.map((attraction) => { 
                
                return (
                <Grid container sx={{flexDirection: 'row', flex: 1}}>
                    <Grid sx={{flex: 4}}>
                        <Typography sx={{...styles.text, fontSize:"1.5vh"}}>{attraction.name}</Typography>
                    </Grid>
                    <Grid sx={{flex: 1, alignItems: 'end'}}>
                        <Button sx={{ fontWeight: 'bold', fontSize:"1.5vh", textAlign: 'right'}} onClick={()=>removeAttraction(attraction)}>REMOVE</Button>
                    </Grid>
                </Grid>
                
            )})}

            {filteredAttractions.map((attraction) => { 
                return (
                    currentAttractionsUnderTour.filter(e => e.id == attraction.id).length < 1 ? 
                    <Grid container sx={{flexDirection: 'row', flex: 1}}>
                        <Grid sx={{flex: 4}}>
                            <Typography sx={{...styles.text, fontSize:"1.5vh"}}>{attraction.name}</Typography>
                        </Grid>
                        <Grid sx={{flex: 1, alignItems: 'end'}}>
                            <Button sx={{ fontWeight: 'bold', fontSize:"1.5vh", textAlign: 'right'}} onClick={()=>addAttraction(attraction)}>ADD</Button>
                        </Grid>
                    </Grid> :
                    null
                )}
            )}

        </Paper>
        </>
    )
}

export default function AttractionDetails () {

    const { selectedTour, setSelectedTour, toursShow, attractionsAll, currentAttractionsUnderTour, setCurrentAttractionsUnderTour } = useContext(TourismContext);

    const [isEditing, setIsEditing] = useState(false)

    const [nameValue, setNameValue] = useState(selectedTour.tour_name)
    const [descValue, setDescValue] = useState(selectedTour.desc)

    const [nameValueError, setNameValueError] = useState(false)
    const [descValueError, setDescValueError] = useState(false)

    const [loading, setLoading] = useState(false)
    const [selectedImgArray, setSelectedImgArray] = useState([])
    const [isImgArrayLoading, setIsImgArrayLoading] = useState(false);

    const [imgIndex, setImgIndex] = useState(0);
    const [editedImgArray, setEditedImgArray] = useState([])

    const [removedPhotos, setRemovedPhotos] = useState([]) // mga i-reremove from the original uploaded
    const [addedPhotos, setAddedPhotos] = useState([]) // kaka-add lang na photos
    

    const regExp_decimal = RegExp(/^\d*\.?\d*$/);

    useEffect(() => {
        setIsEditing(false)
        if (selectedTour) {
          getImagesByTagAndTagId("tours", selectedTour.id)
        }
    }, [selectedTour])

    // start: for getting Attractions (Tourism)
    const fetchAttractions = async () => { 
        const res = await axios.get(`${Constants.BASE_URL}/api/tours/get/id`, { params: { id: selectedTour.id } })
        .then((res)=> {
        if (res.data.count>0) {
            setCurrentAttractionsUnderTour(res.data.values)
        } else {
            setCurrentAttractionsUnderTour([])
        }
        })
    }
    // end: for getting Attractions (Tourism)

    useEffect(()=>{
       fetchAttractions()
      }, [])
    

    const getImagesByTagAndTagId = async (tag, tag_id) => {
        setIsImgArrayLoading(true)
        const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, {
          "tag": tag,
          "tag_id": tag_id
        })
        .then((res)=> {
          if (res.data.count>0) {
            setSelectedImgArray(res.data.values)
            setEditedImgArray(res.data.values)
          } else {
            setSelectedImgArray([])
            setEditedImgArray([])
          }
          setIsImgArrayLoading(false)
        })
    }

    const handleSave = () => {

        const reducedAttractions = currentAttractionsUnderTour.map(attraction => attraction.id)
        // EDIT NAME & DESCRIPTION
        const formData = {} 
        formData.id = selectedTour.id
        if (selectedTour.tour_name != nameValue) {
            formData.name = nameValue
        }
        if (selectedTour.desc != descValue) {
            formData.desc = descValue
        }
        if (selectedTour.attractions.sort() != reducedAttractions.sort()) {
            formData.attractions = reducedAttractions
        }
        setLoading(true)
        const fetchData = async() => {
            const res = await axios.post(`${Constants.BASE_URL}/api/tours/edit`, formData)
            .then((res)=>{
                if (res.data) {
                    setLoading(false)
                    setIsEditing(false)
                    setSelectedTour(res.data.values[0])
                    var foundIndex = toursShow.values.findIndex(x => x.id == selectedTour.id);
                    toursShow.values[foundIndex] = res.data.values[0];
                }
            })
        }
        fetchData()
        
        // EDIT IMAGES - ADD
        if (addedPhotos.length>0) {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            var formData2 = new FormData();
            formData2.append("tag", "tours")
            formData2.append("tag_id", selectedTour.id)
        
            addedPhotos.forEach((element, index) => formData2.append(`img[${index}]`, element.img2))
            for (var pair of formData2.entries()) {
            }

            const fetchData2 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/create`, formData2, config)
                .then((res) => {
                    setAddedPhotos([])
                })
            }
            fetchData2()
        }
       

        // EDIT IMAGES - DELETE
        var id_array = []
        if (removedPhotos.length>0) {
            const formData3 = {
                "id_array": id_array
            }

            const fetchData3 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/delete/multiple`, formData3)
                .then((res) => {
                    setRemovedPhotos([])
                })
            }
            fetchData3()
        }

    }

    const handleUndo = () => {
        setIsEditing(false); 
        setNameValue(selectedTour.tour_name)
        setDescValue(selectedTour.desc)
        setEditedImgArray(selectedImgArray)
        setAddedPhotos([])
        setRemovedPhotos([])
    }

    const handleName = (event) => {
        if (event.target.value) {
            setNameValue(event.target.value); 
            setNameValueError(false)
        } else {
            setNameValueError(true)
        }
    };

    const handleDesc = (event) => {
        if (event.target.value) {
            setDescValue(event.target.value); 
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }
    };

    const handleRemovePhoto = () => {
        if (editedImgArray.length>0) {
            setEditedImgArray(editedImgArray.filter((img, index)=> index !== imgIndex))

            // dapat sa ma-detect natin kung yung recently deleted na image ay nasa uploaded file
            var isPrevIncluded = editedImgArray.filter((img, index)=> index === imgIndex)[0].id
            var item = editedImgArray.filter((img, index)=> index === imgIndex)[0]

            if (isPrevIncluded) {
                setRemovedPhotos([...removedPhotos, item])

            } else {
                setAddedPhotos(addedPhotos.filter((img, index)=> img.img !== item.img))
            }
        }
    }

    const [preview, setPreview] = React.useState(null)
    const addNewPhoto = e => {
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            setPreview(reader.result); // for preview only
            setEditedImgArray([...editedImgArray, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
            setAddedPhotos([...addedPhotos, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
        });
        reader.readAsDataURL(e.target.files[0])
       
    };

    

    React.useEffect(()=>{
    }, [editedImgArray])

    React.useEffect(()=>{
    }, [removedPhotos])

    React.useEffect(()=>{
    }, [addedPhotos])

    

    const handleNext = (next, active) => {
        setImgIndex(next)
    }
    const handlePrev = (prev, active) => {
        setImgIndex(prev)

    }
    const handleDeleteAttraction = () => {
        var formData = {
            "id" : selectedTour.id
        }
        const fetchData = async () => {
            const res = await axios.post(`${Constants.BASE_URL}/api/tours/delete`, formData)
            .then((res) => {
                if (res.data) {
                    alert("Item deleted. Please refresh your webpage.")
                }
            })
        }
        var del = window.confirm("Are you sure you want to delete this attraction?")
        if (del) {
            fetchData()
        }

    }

    return (
        <>
        <Grid container direction="row" alignItems="center" style={{height: '6vh', padding:"2vh"}}>

            {/* BACK BUTTON */}
            <Grid item>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={()=>setSelectedTour(null)}
                    aria-label="close"
                    >
                    <ArrowBackIosIcon fontSize='large'/>
                </IconButton>
            </Grid>

            {/* NAME OF ATTRACTION */}
            <Grid item>
                <Typography style={styles.title}>{selectedTour.tour_name}</Typography>
            </Grid>
        </Grid>

        <Paper
            square
            elevation={0}
            style={{
                backgroundColor: themes.colors.sub3,
                height: '83vh',
                maxHeight: "90vh",
                marginBottom: 20,
                overflow: 'scroll'
            }}
        >
            
            
            <Grid container style={{ height: '95%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>

                {/* CAROUSEL */}
                <Grid container sx={{alignItems: 'center', justifyContent: 'center', position: "relative"}}>
                
                    {/* ADD & REMOVE PHOTO OVER CAROUSEL */}
                    {isEditing?
                        <Grid container justifyContent={"space-around"}
                            style={{position:"absolute", top: "40%", zIndex:5, 
                            width: "30%"
                        }}
                        >

                        {/* source: https://mui.com/material-ui/react-button/#upload-button */}
                            <Tooltip title="Add a Photo">
                                <IconButton component="label" variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    >
                                    <input
                                        type={"file"}
                                        id={"photoInput"}
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={addNewPhoto}
                                        hidden
                                    />
                                    <AddPhotoAlternateOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove Photo">
                                <IconButton variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    onClick={handleRemovePhoto}
                                >
                                    <CloseOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :null}
                        
                        {/* CAROUSEL OF IMAGES */}
                        {isImgArrayLoading?
                            <Skeleton variant="rectangular" height={"30vh"} width={"100%"} />:
                                editedImgArray.length>0?
                                <Carousel 
                                    sx={{width:"100%", height:300}} 
                                    autoPlay={false}
                                    cycleNavigation={false}
                                    indicatorIconButtonProps={{style: { position:"relative", bottom:"2vh"}}}
                                    next={ (next, active) => {handleNext(next, active)} }
                                    prev={ (prev, active) => {handlePrev(prev, active)} }
                                    >
                                    {
                                        editedImgArray.map((img, i) => 
                                            <CardMedia
                                                component="img"
                                                image={img['img']}
                                                alt="img[0]"
                                                style={{
                                                    borderRadius:10, padding:5, height:"100%", width:"98%", height:270,
                                                    opacity: isEditing?0.8:1
                                                }}
                                            /> )
                                    }
                                </Carousel>
                            :
                            <Paper sx={styles.attractionPic} elevation={3}>
                                <Grid container style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={styles.attractionLabelText}>NO IMAGE AVAILABLE</Typography>
                                </Grid>
                            </Paper>
                        }
                   
                
                </Grid>

                {/* ATTRACTION DESCRIPTIONS */}
                <Grid container style={{width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Name:`}</Typography>
                        {isEditing?
                            <TextField 
                                focused 
                                defaultValue={selectedTour.tour_name}
                                fullWidth
                                style={{maxWidth: "60%", textAlign: "right"}}
                                multiline
                                onChange={handleName}
                                error={nameValueError}
                                helperText={nameValueError? "This is a required field": ""}
                            />

                            :
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '70%', textAlign:'right', fontWeight: "bold"}}>{selectedTour.tour_name}</Typography>
                        }
                    </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between", flexDirection: 'column' }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Description:`}</Typography>
                        {isEditing?
                            <TextField 
                                focused 
                                defaultValue={selectedTour.desc}
                                fullWidth
                                style={{maxWidth: "100%"}}
                                multiline
                                rows={4}
                                onChange={handleDesc}
                                error={descValueError}
                                helperText={descValueError? "This is a required field": ""}
                            />:
                            <Typography display="inline" sx={{...styles.text, fontSize:"1.75vh", whiteSpace: 'pre-line', width: '100%', textAlign:'left'}}>{selectedTour.desc}</Typography>
                        }
                    </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between", flexDirection: 'column' }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Attractions: (${currentAttractionsUnderTour.length})`}</Typography>
                        {isEditing?
                            <AddAttractionsBar/>:
                            currentAttractionsUnderTour.length > 0 ? 
                            currentAttractionsUnderTour.map(attraction => { return(
                                <Typography display="inline" sx={{...styles.text, fontSize:"1.75vh", width: '100%', textAlign:'left'}}>{attraction.name}</Typography>
                            )}):
                            <Typography display="inline" sx={{...styles.text, fontSize:"1.75vh", whiteSpace: 'pre-line', width: '100%', textAlign:'left'}}>No attractions under this tour</Typography>
                        }
                    </Grid>

                    
                    {/* EDIT & SAVE/UNDO BUTTONS */}
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>
                        {isEditing?
                            <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                <Box sx={{ position: 'relative', width:"75%" }}>
                                    
                                <Button style={{width:"100%"}}
                                    onClick={handleSave} 
                                    variant="contained"
                                    size="large"
                                    disabled={
                                        loading || 
                                        (
                                        selectedTour.tour_name == nameValue && 
                                        selectedTour.desc == descValue &&
                                        selectedTour.attractions.sort() == currentAttractionsUnderTour.map(attraction => attraction.id).sort() &&
                                        addedPhotos.length===0 && removedPhotos.length===0 )
                                        || nameValueError || descValueError 
                                    }
                                    >Save Changes</Button>
                                     {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: themes.colors.primary,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
            
                                <Tooltip title="Close Edit">
                                    <IconButton 
                                    onClick={handleUndo} color="primary" aria-label="upload picture" component="span">
                                        <DoDisturbAltIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={handleDeleteAttraction} color="primary" component="span">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> :
                            JSON.parse(localStorage.getItem('user')).user_type == "Admin" ?
                                <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                    <Button fullWidth onClick={()=>setIsEditing(true)} variant="contained">Edit</Button>
                                </Grid>
                            :
                            null
                        }
                    </Grid>

                </Grid>
                
            </Grid>
        </Paper>
        </>
    );
}

