import React, { useContext } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip, 
        GeoJSON, LayersControl, useMap, FeatureGroup, Polygon } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import axios from 'axios';
import { sampleDistrictGeojson } from '../../../core/data'
import { themes } from '../../../core/theme';
import { RaiContext } from '../../../contexts/RaiContext';
import unresolved from '../../../assets/unresolved.png'
import { valuesToFeatureCollection } from '../../../core/functions';

import { buffer as turfBuffer, point as turfPoint, 
            concave as turfConcave, convex as turfConvex,
            voronoi as turfVoronoi } from "@turf/turf";

import icon from '../../../assets/resolvedIcon.png'

const { BaseLayer } = LayersControl

const districtOptions = { fillColor: "#ffffff00", color: "#0d3c47", opacity: 0.65 }
const bufferOptions = { fillColor: "#ffffff00", color: "#0d3c47", opacity: 0.65 }

const CustomMarker = L.icon({
    // iconUrl: resolved,
    iconUrl: icon,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

const pinIcon = L.icon({
    iconUrl: unresolved,
    tooltipAnchor: [16, -16],
    iconAnchor: [16, 32],
    popupAnchor: [0, 0],
    iconSize: [32, 32],
});


function Reports() {
    const { 
        reportsShow, 
        setSelectedRow, showRaiPoints, 
            buffer, concave, convex, voronoi } = useContext(RaiContext)

    const map = useMap();

    try {
        var reportsGeoJSON = valuesToFeatureCollection(reportsShow.values)
        
        var reportBuffered = turfBuffer(reportsGeoJSON, 1, {units: 'kilometers'});
    
        var reportConcave = turfConcave(reportsGeoJSON);
    
        var reportConvex = turfConvex(reportsGeoJSON);
    } catch (error) {
    }

    return (
        <>
        { 
        reportsShow && reportsShow.values && showRaiPoints ? 
        reportsShow.values.map((point) => {
            if (point.coords) {
                return (
                    <Marker
                    icon={pinIcon}
                    key={point.id}
                    position={[point.coords[1], point.coords[0]]}
                    eventHandlers={{
                        click: (e) => {
                            map.setView([point.coords[1], point.coords[0]], 20);
                            setSelectedRow(point)
                        }
                    }}
                    >
                        <Tooltip direction="top">{point.category}</Tooltip>
                    </Marker>
                )
            }
        }) : null}

        {/* BUFFER BY 1 KM */}
        { buffer && reportsShow && reportsShow.values ? <GeoJSON style={bufferOptions} data={reportBuffered} /> : null }

        {/* CONCAVE */}
        { concave && reportsShow && reportsShow.values ? <GeoJSON style={bufferOptions} data={reportConcave} /> : null }

        {/* CONVEX */}
        { convex && reportsShow && reportsShow.values ? <GeoJSON style={bufferOptions} data={reportConvex} /> : null }

        </>
    );
  }

export default function Map() { 

    const _created = (e) => console.log(e);
    
    
    return (
        <MapContainer center={[10.7232, 122.5621]} zoom={13} scrollWheelZoom={true} style={{height:'100%', width: '100%'}} >
            
            {/* BASEMAPS */}
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </BaseLayer>
                <BaseLayer name="CartoDB">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    />
                </BaseLayer>
                <BaseLayer name="ESRI">
                    <TileLayer
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </BaseLayer>
            </LayersControl>

            <Reports/>
          
            <GeoJSON style={districtOptions} data={sampleDistrictGeojson} />

        </MapContainer>
    )
}


