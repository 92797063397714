import React, { useContext } from 'react';
import { Grid, Typography, Paper, IconButton, Button, TextField, 
    Tooltip, CardMedia, DialogContent, DialogActions, LinearProgress } from '@mui/material';
import {themes} from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';
import axios from 'axios';
import * as Constants from '../../../core'
import Carousel from 'react-material-ui-carousel'
import { CloseOutlined, AddPhotoAlternateOutlined } from '@mui/icons-material';
import { BootstrapDialogTitle } from '../../../components/dialog';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.tertiary,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    imgIconButton: {
        "&:hover" : {
            opacity: 0.7, 
            backgroundColor: themes.colors.primary, 
        },
        opacity: 0.8, 
        backgroundColor: themes.colors.primary, 
        color: "white",
    }
}


export default function AttractionDetailsNew () {

    const { setNewTour } = useContext(TourismContext);
    const [showLP, setShowLP] = React.useState(false)

    const [imgArray, setImgArray] = React.useState([])
    const [imgIndex, setImgIndex] = React.useState(0);

    const [removedPhotos, setRemovedPhotos] = React.useState([]) // mga i-reremove from the original uploaded
    const [addedPhotos, setAddedPhotos] = React.useState([]) // kaka-add lang na photos

    const [nameValue, setNameValue] = React.useState('')
    const [descValue, setDescValue] = React.useState('')

    const [nameValueError, setNameValueError] = React.useState(false)
    const [descValueError, setDescValueError] = React.useState(false)

    const handleName = (event) => {
        if (event.target.value) {
            setNameValue(event.target.value);
            setNameValueError(false)
        } else {
            setNameValueError(true)
        }
    };

    const handleDesc = (event) => {
        if (event.target.value) {
            setDescValue(event.target.value); 
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }
    };


    const handleRemovePhoto = () => {
        if (imgArray.length>0) {
            setImgArray(imgArray.filter((img, index)=> index !== imgIndex))

            // dapat sa ma-detect natin kung yung recently deleted na image ay nasa uploaded file
            var isPrevIncluded = imgArray.filter((img, index)=> index === imgIndex)[0].id
            var item = imgArray.filter((img, index)=> index === imgIndex)[0]

            if (isPrevIncluded) {
                setRemovedPhotos([...removedPhotos, item])

            } else {
                setAddedPhotos(addedPhotos.filter((img, index)=> img.img !== item.img))
            }
            
        }
   
    }

    const [preview, setPreview] = React.useState(null)
    const addNewPhoto = e => {
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            setPreview(reader.result); // for preview only
            setImgArray([...imgArray, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
            setAddedPhotos([...addedPhotos, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
        });
        reader.readAsDataURL(e.target.files[0])
       
    };

    const handleNext = (next, active) => {
        setImgIndex(next)
    }

    const handlePrev = (prev, active) => {
        setImgIndex(prev)
    }

    const handleSave = () => {
        var formData
        const fetchData = async() => {
            console.log(formData)
            const res = await axios.post(`${Constants.BASE_URL}/api/tours/create`, formData)
            .then((res)=>{
                if (res.data) {
                    if (res.data) {
                        if (addedPhotos.length>0) {
                            // add image after creating an attraction and getting an id, res.data.id
                            const config = {
                                headers: {
                                    'content-type': 'multipart/form-data'
                                }
                            };
                            var formData2 = new FormData();
                            formData2.append("tag", "tours")
                            formData2.append("tag_id", res.data.id)
                        
                            addedPhotos.forEach((element, index) => formData2.append(`img[${index}]`, element.img2))
                            for (var pair of formData2.entries()) {
                            }
    
                            const fetchData2 = async () => {
                                const res2 = await axios.post(`${Constants.BASE_URL}/api/image/create`, formData2, config)
                                .then((res2) => {
                                    setAddedPhotos([])
                                    alert("Item added. Please refresh your webpage.")
                                    setShowLP(false)
    
                                })
                            }
                            fetchData2()
                        } else {
                            setShowLP(false)
                        }
                        
                    }
                }
            })
        }

        if (nameValue) {
            setNameValueError(false)
        } else {
            setNameValueError(true)
        }

        if (descValue) {
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }

        if (nameValue && descValue) {
            var upload = window.confirm("Are you sure you want to upload this tour?")
            
            if (upload) {
                formData = {
                    "tour_name": nameValue,
                    "desc": descValue
                }
                console.log(formData)
                fetchData()
                setShowLP(true)
            }
        } 
    }

    return (
        <React.Fragment>
            <BootstrapDialogTitle onClose={()=>setNewTour(false)}>Create New Tour</BootstrapDialogTitle>
            {/* LINEAR PROGRESS */}
            {showLP?<LinearProgress/>:null}
            
            <DialogContent dividers>
                <Grid container style={{ height: '92%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>

                    {/* CAROUSEL */}
                    <Grid container sx={{alignItems: 'center', justifyContent: 'center', position: "relative"}}>
                        {/* ADD & REMOVE PHOTO OVER CAROUSEL */}
                        <Grid container justifyContent={"space-around"}
                            style={{position:"absolute", top: "40%", zIndex:5, 
                            width: "30%"
                            }}
                        >
                            {/* source: https://mui.com/material-ui/react-button/#upload-button */}
                            <Tooltip title="Add a Photo">
                                <IconButton component="label" variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    >
                                    <input
                                        type={"file"}
                                        id={"photoInput"}
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={addNewPhoto}
                                        hidden
                                    />
                                    <AddPhotoAlternateOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove Photo">
                                <IconButton variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    onClick={handleRemovePhoto}
                                >
                                    <CloseOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        {/* CAROUSEL OF IMAGES */}
                        {imgArray.length>0?
                                <Carousel 
                                    sx={{width:"100%", height:300}} 
                                    autoPlay={false}
                                    cycleNavigation={false}
                                    indicatorIconButtonProps={{style: { position:"relative", bottom:"2vh"}}}
                                    next={ (next, active) => {handleNext(next, active)} }
                                    prev={ (prev, active) => {handlePrev(prev, active)} }
                                    >
                                    {
                                        imgArray.map((img, i) => 
                                            <CardMedia
                                                component="img"
                                                image={img['img']}
                                                alt="img[0]"
                                                style={{
                                                    borderRadius:10, padding:5, height:"100%", width:"98%",
                                                    opacity: 1,
                                                }}
                                            /> )
                                    }
                                </Carousel>
                        
                            :
                            <Paper sx={styles.attractionPic} elevation={3}>
                                <Grid container style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={styles.attractionLabelText}>NO IMAGE AVAILABLE</Typography>
                                </Grid>
                            </Paper>
                        }
                    
                    </Grid>

                    {/* ATTRACTION DESCRIPTIONS */}
                    <Grid container style={{width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Name:`}</Typography>
                                <TextField 
                                    disabled={showLP}
                                    required
                                    fullWidth
                                    style={{maxWidth: "60%", textAlign: "right"}}
                                    multiline
                                    onChange={handleName}
                                    error={nameValueError}
                                    helperText={nameValueError? "This is a required field": ""}
                                />
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Description:`}</Typography>
                                <TextField 
                                    disabled={showLP}
                                    required
                                    fullWidth
                                    style={{maxWidth: "60%"}}
                                    multiline
                                    rows={3}
                                    onChange={handleDesc}
                                    error={descValueError}
                                    helperText={descValueError? "This is a required field": ""}
                                />
                            </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            {/* SAVE BUTTON */}
            <DialogActions>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
           
        </React.Fragment>
    );
}

