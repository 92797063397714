import React, { useContext, useEffect } from 'react';
import { Grid, Typography, Paper, IconButton, Button, TextField, 
    Box, CircularProgress, Tooltip, Skeleton, CardMedia } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {themes} from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';
import axios from 'axios';
import * as Constants from '../../../core'
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import Carousel from 'react-material-ui-carousel'
import { CloseOutlined, AddPhotoAlternateOutlined } from '@mui/icons-material';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.tertiary,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    imgIconButton: {
        "&:hover" : {
            opacity: 0.7, 
            backgroundColor: themes.colors.primary, 
        },
        opacity: 0.8, 
        backgroundColor: themes.colors.primary, 
        color: "white",
    }
}

export default function AttractionDetails () {

    const { selectedAttraction, setSelectedAttraction, attractionsShow } = useContext(TourismContext);

    const [isEditing, setIsEditing] = React.useState(false)

    const [nameValue, setNameValue] = React.useState(selectedAttraction.name)
    const [descValue, setDescValue] = React.useState(selectedAttraction.desc)
    const [latValue, setLatValue] = React.useState(selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[1]:0)
    const [lonValue, setLonValue] = React.useState(selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[0]:0)

    const [nameValueError, setNameValueError] = React.useState(false)
    const [descValueError, setDescValueError] = React.useState(false)
    const [latValueError, setLatValueError] = React.useState(false)
    const [lonValueError, setLonValueError] = React.useState(false)

    const [loading, setLoading] = React.useState(false)
    const [selectedImgArray, setSelectedImgArray] = React.useState([])
    const [isImgArrayLoading, setIsImgArrayLoading] = React.useState(false);

    const [imgIndex, setImgIndex] = React.useState(0);
    const [editedImgArray, setEditedImgArray] = React.useState([])

    const [removedPhotos, setRemovedPhotos] = React.useState([]) // mga i-reremove from the original uploaded
    const [addedPhotos, setAddedPhotos] = React.useState([]) // kaka-add lang na photos

    const regExp_decimal = RegExp(/^\d*\.?\d*$/);

    React.useEffect(() => {
        setIsEditing(false)
        if (selectedAttraction) {
          getImagesByTagAndTagId("tourist_attractions", selectedAttraction.id)
        }
    }, [selectedAttraction])
    

    const getImagesByTagAndTagId = async (tag, tag_id) => {
        setIsImgArrayLoading(true)
        const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, {
          "tag": tag,
          "tag_id": tag_id
        })
        .then((res)=> {
          if (res.data.count>0) {
            setSelectedImgArray(res.data.values)
            setEditedImgArray(res.data.values)
          } else {
            setSelectedImgArray([])
            setEditedImgArray([])
          }
          setIsImgArrayLoading(false)
        })
    }

    const handleSave = () => {

        // EDIT NAME & DESCRIPTION
        const formData = {} 
        formData.id = selectedAttraction.id
        if (selectedAttraction.name != nameValue) {
            formData.name = nameValue
        }
        if (selectedAttraction.desc != descValue) {
            formData.desc = descValue
        }
        if (selectedAttraction.coords && selectedAttraction.coords.length>0 && selectedAttraction.coords[1] != latValue) {
            formData.location_lat = Number(latValue)
            formData.location_lon = Number(lonValue)
        }
        if (selectedAttraction.coords && selectedAttraction.coords.length>0 && selectedAttraction.coords[0] != lonValue) {
            formData.location_lat = Number(latValue)
            formData.location_lon = Number(lonValue)
        }
        if (!selectedAttraction.coords) {
            formData.location_lat = Number(latValue)
            formData.location_lon = Number(lonValue)
        }
      
        setLoading(true)
        const fetchData = async() => {
            const res = await axios.post(`${Constants.BASE_URL}/api/tourism/edit/touristattractions`, formData)
            .then((res)=>{
                if (res.data) {
                    setLoading(false)
                    setIsEditing(false)
                    setSelectedAttraction(res.data.values[0])
                    var foundIndex = attractionsShow.values.findIndex(x => x.id == selectedAttraction.id);
                    attractionsShow.values[foundIndex] = res.data.values[0];
                }
            })
        }
        fetchData()
        
        // EDIT IMAGES - ADD
        if (addedPhotos.length>0) {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            };
            var formData2 = new FormData();
            formData2.append("tag", "tourist_attractions")
            formData2.append("tag_id", selectedAttraction.id)
        
            addedPhotos.forEach((element, index) => formData2.append(`img[${index}]`, element.img2))
            for (var pair of formData2.entries()) {
            }

            const fetchData2 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/create`, formData2, config)
                .then((res) => {
                    setAddedPhotos([])
                })
            }
            fetchData2()
        }
       

        // EDIT IMAGES - DELETE
        var id_array = []
        if (removedPhotos.length>0) {
            removedPhotos.forEach(element => console.log("elementTapped.id", id_array.push(element.id)))
            const formData3 = {
                "id_array": id_array
            }

            const fetchData3 = async () => {
                const res = await axios.post(`${Constants.BASE_URL}/api/image/delete/multiple`, formData3)
                .then((res) => {
                    setRemovedPhotos([])
                })
            }
            fetchData3()
        }

    }

    const handleUndo = () => {
        setIsEditing(false); 
        setNameValue(selectedAttraction.name)
        setDescValue(selectedAttraction.desc)
        setEditedImgArray(selectedImgArray)
        setAddedPhotos([])
        setRemovedPhotos([])
    }

    const handleName = (event) => {
        if (event.target.value) {
            setNameValue(event.target.value); 
            setNameValueError(false)
        } else {
            setNameValueError(true)
        }
    };

    const handleDesc = (event) => {
        if (event.target.value) {
            setDescValue(event.target.value); 
            setDescValueError(false)
        } else {
            setDescValueError(true)
        }
    };

    const handleLat = (event) => {
        if (regExp_decimal.test(event.target.value)) {
            setLatValueError(false)
            setLatValue(event.target.value); 
        } else {
            setLatValueError(true)
        }
        if (!event.target.value) {
            setLatValueError(true)
        }
    };

    const handleLon = (event) => {
        if (regExp_decimal.test(event.target.value)) {
            setLonValueError(false)
            setLonValue(event.target.value); 
        } else {
            setLonValueError(true)
        }
        if (!event.target.value) {
            setLonValueError(true)
        }
    };

    const handleRemovePhoto = () => {
        if (editedImgArray.length>0) {
            setEditedImgArray(editedImgArray.filter((img, index)=> index !== imgIndex))

            // dapat sa ma-detect natin kung yung recently deleted na image ay nasa uploaded file
            var isPrevIncluded = editedImgArray.filter((img, index)=> index === imgIndex)[0].id
            var item = editedImgArray.filter((img, index)=> index === imgIndex)[0]

            if (isPrevIncluded) {
                setRemovedPhotos([...removedPhotos, item])

            } else {
                setAddedPhotos(addedPhotos.filter((img, index)=> img.img !== item.img))
            }
        }
    }

    const [preview, setPreview] = React.useState(null)
    const addNewPhoto = e => {
        var reader = new FileReader();
        reader.addEventListener("load", () => {
            setPreview(reader.result); // for preview only
            setEditedImgArray([...editedImgArray, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
            setAddedPhotos([...addedPhotos, {
                img: reader.result, // for previewing
                img2: e.target.files[0], // for uploading
            }])
        });
        reader.readAsDataURL(e.target.files[0])
       
    };

    React.useEffect(()=>{
    }, [editedImgArray])

    React.useEffect(()=>{
    }, [removedPhotos])

    React.useEffect(()=>{
    }, [addedPhotos])

    const handleNext = (next, active) => {
        setImgIndex(next)
    }
    const handlePrev = (prev, active) => {
        setImgIndex(prev)

    }
    const handleDeleteAttraction = () => {
        var formData = {
            "id" : selectedAttraction.id
        }
        const fetchData = async () => {
            const res = await axios.post(`${Constants.BASE_URL}/api/tourism/delete/touristattractions`, formData)
            .then((res) => {
                if (res.data) {
                    alert("Item deleted. Please refresh your webpage.")
                }
            })
        }
        var del = window.confirm("Are you sure you want to delete this attraction?")
        if (del) {
            fetchData()
        }

    }
    return (
        <Paper
            square
            elevation={0}
            style={{
                backgroundColor: themes.colors.sub3,
                maxHeight: "90vh",
                marginBottom: 20
            }}
        >
            <Grid container direction="row" alignItems="center" style={{padding:"2vh"}}>

                {/* BACK BUTTON */}
                <Grid item>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={()=>setSelectedAttraction(null)}
                        aria-label="close"
                        >
                        <ArrowBackIosIcon fontSize='large'/>
                    </IconButton>
                </Grid>

                {/* NAME OF ATTRACTION */}
                <Grid item>
                    <Typography style={styles.title}>{selectedAttraction.name}</Typography>
                </Grid>
            </Grid> 
            
            <Grid container style={{ height: '92%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>

                {/* CAROUSEL */}
                <Grid container sx={{alignItems: 'center', justifyContent: 'center', position: "relative"}}>
                
                    {/* ADD & REMOVE PHOTO OVER CAROUSEL */}
                    {isEditing?
                        <Grid container justifyContent={"space-around"}
                            style={{position:"absolute", top: "40%", zIndex:5, 
                            // backgroundColor: "red", 
                            width: "30%"
                        }}
                        >

                        {/* source: https://mui.com/material-ui/react-button/#upload-button */}
                            <Tooltip title="Add a Photo">
                                <IconButton component="label" variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    >
                                    <input
                                        type={"file"}
                                        id={"photoInput"}
                                        accept="image/x-png,image/gif,image/jpeg"
                                        onChange={addNewPhoto}
                                        hidden
                                    />
                                    <AddPhotoAlternateOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove Photo">
                                <IconButton variant="contained" aria-label="upload picture" 
                                    sx={styles.imgIconButton}
                                    onClick={handleRemovePhoto}
                                >
                                    <CloseOutlined fontSize='large'/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        :null}
                        
                        {/* CAROUSEL OF IMAGES */}
                        {isImgArrayLoading?
                            <Skeleton variant="rectangular" height={"30vh"} width={"100%"} />:
                                editedImgArray.length>0?
                                <Carousel 
                                    sx={{width:"100%", height:300}} 
                                    autoPlay={false}
                                    cycleNavigation={false}
                                    indicatorIconButtonProps={{style: { position:"relative", bottom:"2vh"}}}
                                    next={ (next, active) => {handleNext(next, active)} }
                                    prev={ (prev, active) => {handlePrev(prev, active)} }
                                    >
                                    {
                                        editedImgArray.map((img, i) => 
                                            <CardMedia
                                                component="img"
                                                image={img['img']}
                                                alt="img[0]"
                                                style={{
                                                    borderRadius:10, padding:5, height:"100%", width:"98%", height:270,
                                                    opacity: isEditing?0.8:1
                                                }}
                                            /> )
                                    }
                                </Carousel>
                            :
                            <Paper sx={styles.attractionPic} elevation={3}>
                                <Grid container style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={styles.attractionLabelText}>NO IMAGE AVAILABLE</Typography>
                                </Grid>
                            </Paper>
                        }
                   
                
                </Grid>

                {/* ATTRACTION DESCRIPTIONS */}
                <Grid container style={{width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Name:`}</Typography>
                        {isEditing?
                            <TextField 
                                focused 
                                defaultValue={selectedAttraction.name}
                                fullWidth
                                style={{maxWidth: "60%", textAlign: "right"}}
                                multiline
                                onChange={handleName}
                                error={nameValueError}
                                helperText={nameValueError? "This is a required field": ""}
                            />

                            :
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '70%', textAlign:'right', fontWeight: "bold"}}>{selectedAttraction.name}</Typography>
                        }
                    </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Description:`}</Typography>
                        {isEditing?
                            <TextField 
                                focused 
                                defaultValue={selectedAttraction.desc}
                                fullWidth
                                style={{maxWidth: "60%"}}
                                multiline
                                rows={4}
                                onChange={handleDesc}
                                error={descValueError}
                                helperText={descValueError? "This is a required field": ""}
                            />:
                            <Typography display="inline" sx={{...styles.text, fontSize:"1.75vh", whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedAttraction.desc}</Typography>
                        }
                        </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Latitude:`}</Typography>
                        {isEditing?
                            <TextField 
                                focused 
                                required
                                fullWidth
                                style={{maxWidth: "60%", textAlign: "right"}}
                                multiline
                                onChange={handleLat}
                                defaultValue={selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[1]:0}
                                error={latValueError}
                                helperText={latValueError? "Decimal only": ""}
                            />
                        :
                        <Typography display="inline" sx={styles.text}>
                            {selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[1]:null}
                        </Typography>
                        }
                    </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Longitude:`}</Typography>
                        {isEditing?
                        <TextField 
                            focused
                            required
                            fullWidth
                            style={{maxWidth: "60%", textAlign: "right"}}
                            multiline
                            onChange={handleLon}
                            defaultValue={selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[0]:0}
                            error={lonValueError}
                            helperText={lonValueError? "Decimal only": ""}
                        />
                        :
                        <Typography display="inline" sx={styles.text}>
                            {selectedAttraction.coords && selectedAttraction.coords.length>0?selectedAttraction.coords[0]:null}
                        </Typography>
                        }
                    </Grid>
                    
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Score:`}</Typography>
                        <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedAttraction.score}</Typography>
                    </Grid>
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                        <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary}}>{`Reviews:`}</Typography>
                        <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedAttraction.num_reviews}</Typography>
                    </Grid>

                    
                    {/* EDIT & SAVE/UNDO BUTTONS */}
                    <Grid container style={{ width: '100%', justifyContent:"space-between" }}>
                        {isEditing?
                            <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                <Box sx={{ position: 'relative', width:"75%" }}>
                                    
                                <Button style={{width:"100%"}}
                                    onClick={handleSave} 
                                    variant="contained"
                                    size="large"
                                    disabled={
                                        loading || 
                                        (
                                        selectedAttraction.name == nameValue && 
                                        selectedAttraction.desc == descValue &&
                                        addedPhotos.length===0 && removedPhotos.length===0 &&
                                        (selectedAttraction.coords && selectedAttraction.coords.length>0 && selectedAttraction.coords[0] == Number(lonValue) && selectedAttraction.coords[1] == Number(latValue)))
                                        || nameValueError || descValueError || lonValueError || latValueError 
                                    }
                                    >Save Changes</Button>
                                     {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: themes.colors.primary,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                                <Tooltip title="Close Edit">
                                    <IconButton 
                                    onClick={handleUndo} color="primary" aria-label="upload picture" component="span">
                                        <DoDisturbAltIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={handleDeleteAttraction} color="primary" component="span">
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> :
                            JSON.parse(localStorage.getItem('user')).user_type == "Admin" ?
                                <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                    <Button fullWidth onClick={()=>setIsEditing(true)} variant="contained">Edit</Button>
                                </Grid>
                            :
                            null
                        }
                    </Grid>

                </Grid>
                
            </Grid>
        </Paper>
    );
}

