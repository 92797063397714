import React, { useContext, useEffect, useState } from 'react';

import {
    Button, Grid, Typography, Paper, DialogContent, 
    DialogActions, FormControl, Select, Box,
    MenuItem, Autocomplete, TextField, LinearProgress, 
    InputLabel, OutlinedInput, Chip, Stepper, Step, StepLabel, IconButton
  } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { themes } from '../../../../../core/theme';
  
export function CsvPreview ({csvHeader, array, properties}) {
    var csvHeader = csvHeader
    var array = array
    var properties = properties || null 
  
    return (
      <TableContainer component={Paper} style={{height: 500}}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: themes.colors.rowColor}}>
            <TableRow style={{fontWeight:700}}>
  
                {Array.isArray(properties)
                ? properties.map((header)=>
                  header.hasOwnProperty('label')?
                  <TableCell key={header.label} style={{textTransform: "capitalize"}}>{header.label.replace(/_/g, " ") +": " + csvHeader[header.property]}</TableCell>:
                  <TableCell key={header} style={{textTransform: "capitalize"}}>{header.replace(/_/g, " ")}</TableCell>
                ):
                Object.entries(properties).map((header, index2)=> 
                  <TableCell key={header.label} style={{textTransform: "capitalize"}}>{header[0].replace(/_/g, " ")+": "+header[1]}</TableCell>
                )}
              
              
            </TableRow>
          </TableHead>
          <TableBody>
              {array?array.map((row, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
  
                  {Array.isArray(csvHeader)?
                    csvHeader.map((header, index2)=> 
                      <TableCell key={index2}>
                        {row[header]}
                      </TableCell>
                    )
                    // "array"
                    
                  :
                    // "object"
                    Object.values(csvHeader).map((header, index2)=> 
                      <TableCell key={index2}>
                        {Array.isArray(header)?
                          header.map((cat, index)=>
                          row[cat]?
                            index +1 !== header.length?
                              row[cat]+", ":
                              row[cat]
                          :null
                          )
                          // "This includes categories"
                        :row[header]}
                      </TableCell>
                    )
                  }
                </TableRow>
                
              )):null}
          </TableBody>
        </Table>
      </TableContainer>
    )
}

export function CsvPreviewMapping ({csvHeader, array, properties}) {
    var csvHeader = csvHeader
    var array = array
    var properties = properties || null 
  
    // needed so that header label is based on user input
    var headerLabel = []
    
    Object.keys(csvHeader).filter((key, index2) => 
      properties.map((prop)=> {

        if (key == prop.property) {
          headerLabel.push(prop)
        }
      })
    )
    
  
    return (
      <TableContainer component={Paper} style={{height: 500}}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: themes.colors.rowColor}}>
            <TableRow style={{fontWeight:700}}>
              {headerLabel.length>0? headerLabel.map((header)=> {
                return(
                  <TableCell key={header.label} style={{textTransform: "capitalize"}}>{header.label.replace(/_/g, " ")+ " : " +csvHeader[header.property]}</TableCell>
                )
              }):null}
            </TableRow>
          </TableHead>
          <TableBody>
              {array?array.map((row, index) => (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
  
                  {Array.isArray(csvHeader)?
                    csvHeader.map((header, index2)=> 
                      <TableCell key={index2}>
                        {row[header]}
                      </TableCell>
                    )
                    // "array"
                  :
                    // "object"
                    Object.values(csvHeader).map((header, index2)=> 
                      <TableCell key={index2}>
                        {Array.isArray(header)?
                          header.map((cat, index)=>
                          row[cat]?
                            index +1 !== header.length?
                              row[cat]+", ":
                              row[cat]
                          :null
                          )
                          // "This includes categories"
                        :row[header]}
                      </TableCell>
                    )
                  }
                </TableRow>
                
              )):null}
          </TableBody>
        </Table>
      </TableContainer>
    )
}