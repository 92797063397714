export const dataLayers = [
   {
       "title": "Storm Surge",
       "typeName": "lungsod:hazards-storm-surge"
   },
   {
        "title": "Land Cover",
        "typeName": "lungsod:land-cover"
    },
    {
        "title": "Land Use",
        "typeName": "lungsod:land-use"
    },
    {
        "title": "Population Density",
        "typeName": "lungsod:popden"
    },
];