import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Button, Paper } from '@mui/material'
import { FormControl, Select, MenuItem, DialogContent, DialogActions } from '@mui/material'
import { Tooltip, IconButton, LinearProgress } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

import { BootstrapDialog, BootstrapDialogTitle } from '../../components/dialog'
import { adminVerificationStatusFormatter } from '../../core/functions'
import { themes } from '../../core/theme'
import * as Constants from '../../core'

import axios from 'axios';
import WhatsNew from './WhatsNew';
import { Details } from './Details';
import { WindowContext } from '../../contexts/WindowContext';

const styles = {
    root: {
        height: '90vh',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: 'white',
        '& .super-app-theme--Highlight': {
            bgcolor: themes.colors.sub2,
        },
    }
}

export default function Admin() {
    const [rowsAllAdmin, setRowsAllAdmin] = React.useState([])
    const [user, setUser] = React.useState({})
    const [openDialog, setOpenDialog] = React.useState(false)
    const [dialogName, setDialogName] = React.useState('')
    const [showLP, setShowLP] = React.useState(false)
    const [imgData, setImgData] = React.useState(null);
    const {ratio, setRatio} = useContext(WindowContext)
  
    const getCommandUserById = (user_id) => {
        axios.get(`${Constants.BASE_URL}/api/command_user/get/id`, {
            params: {
                "user_id": user_id
            }
        })
        .then((res)=>{
            if (res.data.count>0) {
                setUser(res.data.values)
            } 
        })
    }
   
    const columns = [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'department_office', headerName: 'Office', width: 200 },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 400,
            valueGetter: (params) =>
                `${params.row.first_name || ''} ${params.row.middle_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 300,
        },
        {
            field: 'admin_verification_status',
            headerName: 'Verified?',
            width: 150,
            renderCell: (params) => {
                return adminVerificationStatusFormatter(params.row.admin_verification_status);
            },
        },
        {
            field: 'user_type',
            headerName: 'Type',
            width: 100,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 300,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const verifyRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Verify User")
                  
                    getCommandUserById(params.row.id)
                    const formData = {
                        "tag_id": params.row.user_id,
                        "tag": "command_user_id",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, formData)
                    .then((res)=>{
                        if (res.data.count > 0) {
                            setImgData(res.data.values[0]['img'])
                        } else {
                            setImgData(null)
                        }
                    })
                    .catch((err)=>{
                    })
                }
                const resetRow = async () => {
                    setShowLP(true)
                    const formData = {
                        "id": params.row.user_id,
                        "admin_verification_status": "unverified",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/command_user/edit`, formData)
                    .then((res) => {
                        setShowLP(false)
                        alert("formData"+JSON.stringify(formData))
                    })
                    .catch((err)=>{
                    })
                }
                const changeRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Change User Type")
                    getCommandUserById(params.row.id)
                }
                const deleteRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Delete User")
                 
                    getCommandUserById(params.row.id)
                    const formData = {
                        "tag_id": params.row.user_id,
                        "tag": "command_user_id",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, formData)
                    .then((res)=>{
                        if (res.data.count > 0) {
                            setImgData(res.data.values[0]['img'])
                        } else {
                            setImgData(null)
                        }
                    })
                    .catch((err)=>{
                    })
                }
            
                return (
                    params.row.user_id !== JSON.parse(localStorage.getItem('user')).user_id?
                    <div style={{display:"flex", flex:1, flexDirection:"row", justifyContent:"space-around"}}>
                        <strong>
                            {params.row.admin_verification_status == "unverified"?
                            <Tooltip title="Verify">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={verifyRow} variant="contained">Verify</Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Unverify">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={resetRow} color="secondary" variant="contained">Reset</Button>
                                </span>
                            </Tooltip>
                            }
                        </strong>
                        <strong>
                            <Tooltip title="Change">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={changeRow} variant="contained">Change user type</Button>
                                </span>
                            </Tooltip>
                        </strong>
                        <Tooltip title="Delete">
                            <IconButton onClick={deleteRow}  color="primary" aria-label="upload picture" component="span">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                :"Cannot change settings of own account"
                )
            }
        },
    ];
    const columns2 = [
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 400,
            valueGetter: (params) =>
                `${params.row.first_name || ''} ${params.row.middle_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: 300,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const verifyRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Verify User")
                  
                    getCommandUserById(params.row.id)
                    const formData = {
                        "tag_id": params.row.user_id,
                        "tag": "command_user_id",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, formData)
                    .then((res)=>{
                        if (res.data.count > 0) {
                            setImgData(res.data.values[0]['img'])
                        } else {
                            setImgData(null)
                        }
                    })
                    .catch((err)=>{
                    })
                }
                const resetRow = async () => {
                    setShowLP(true)
                    const formData = {
                        "id": params.row.user_id,
                        "admin_verification_status": "unverified",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/command_user/edit`, formData)
                    .then((res) => {
                        setShowLP(false)
                        alert("formData"+JSON.stringify(formData))
                    })
                    .catch((err)=>{
                    })
                }
                const changeRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Change User Type")
                    getCommandUserById(params.row.id)
                }
                const deleteRow = async (e) => {
                    setOpenDialog(true)
                    setDialogName("Delete User")
                 
                    getCommandUserById(params.row.id)
                    const formData = {
                        "tag_id": params.row.user_id,
                        "tag": "command_user_id",
                    }
                    const res = await axios.post(`${Constants.BASE_URL}/api/image/get`, formData)
                    .then((res)=>{
                        if (res.data.count > 0) {
                            setImgData(res.data.values[0]['img'])
                        } else {
                            setImgData(null)
                        }
                    })
                    .catch((err)=>{
                    })
                }
            
                return (
                    params.row.user_id !== JSON.parse(localStorage.getItem('user')).user_id?
                    <div style={{display:"flex", flex:1, flexDirection:"row", justifyContent:"space-around"}}>
                        <strong>
                            {params.row.admin_verification_status == "unverified"?
                            <Tooltip title="Verify">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={verifyRow} variant="contained">Verify</Button>
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title="Unverify">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={resetRow} color="secondary" variant="contained">Reset</Button>
                                </span>
                            </Tooltip>
                            }
                        </strong>
                        <strong>
                            <Tooltip title="Change">
                                <span>
                                    <Button style={{textTransform:'none'}} onClick={changeRow} variant="contained">Change user type</Button>
                                </span>
                            </Tooltip>
                        </strong>
                        <Tooltip title="Delete">
                            <IconButton onClick={deleteRow}  color="primary" aria-label="upload picture" component="span">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                :"Cannot change settings of own account"
                )
            }
        },
    ];
    const rows = []

    const VerifyUserDialog = () => {
        const handleVerify = async () => {
            setShowLP(true)
            const formData = {
                "id": user.user_id,
                "admin_verification_status": "verified",
            }
            const res = await axios.post(`${Constants.BASE_URL}/api/command_user/edit`, formData)
            .then((res) => {
                setShowLP(false)
                alert("formData"+JSON.stringify(formData))
                setOpenDialog(false)
            })
            .catch((err)=>{
            })
        }
        return (
            <React.Fragment>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>setOpenDialog(false)} component="h2" variant="h5" >
                    Are you sure you want to verify this user?
                </BootstrapDialogTitle>
                {
                    dialogName=="Verify User" && showLP?
                    <LinearProgress />:null
                }
                <DialogContent dividers>
                    <Details user={user} imgData={imgData}/>

                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleVerify}>Verify</Button>
                </DialogActions>
            </React.Fragment>
        )
    }
    

    const ChangeUserTypeDialog = () => {
        const [userTypeVal, setUserTypeVal] = React.useState('')

        const handleSave = async (e) => {
            const formData = {
                "id": user.user_id,
                "user_type": userTypeVal,
            }
            const res = await axios.post(`${Constants.BASE_URL}/api/command_user/edit`, formData)
            .then((res) => {
                if (res.data) {
                    var foundIndex = rowsAllAdmin.findIndex(x => x.user_id == res.data.values[0].user_id);
                    rowsAllAdmin[foundIndex].user_type = res.data.values[0].user_type
                    setOpenDialog(false)
                }
            })
            .catch((err)=>{
            })
        }
        const formValChange = (event) => {
            setUserTypeVal(event.target.value)
        }
        return (
            <React.Fragment>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>setOpenDialog(false)} component="h2" variant="h5" >
                    Are you sure you want to change the user type?
                </BootstrapDialogTitle>
                <DialogContent dividers>
                <Grid item>
                    
                    <Typography variant="subtitle2" color="text.secondary" component="div"  gutterBottom>User Type</Typography>
                    <FormControl fullWidth size="small" required>
                        <Select
                            style={{backgroundColor:"white", borderRadius:5}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            
                            value={userTypeVal?userTypeVal:user.user_type}
                            onChange={formValChange}
                            name="user_type"
                        >
                            <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                            {Constants.userTypes.map((name)=> {
                                return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleSave} disabled={!userTypeVal || userTypeVal === user.user_type}>Save</Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    const DeleteUserDialog = () => {
        const handleDelete = async () => {
            setShowLP(true)
            const formData = {
                "id": user.user_id,
            }
            const res = await axios.post(`${Constants.BASE_URL}/api/command_user/delete`, formData)
            .then((res) => {
                setShowLP(false)
                setOpenDialog(false)
            })
            .catch((err)=>{
            })
        }
        return (
            <React.Fragment>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={()=>setOpenDialog(false)} component="h2" variant="h5" >
                    Are you sure you want to delete this user?
                </BootstrapDialogTitle>
                {
                    dialogName == "Delete User" && showLP?
                    <LinearProgress />:null
                }
                <DialogContent dividers>
                    <Details user={user} imgData={imgData}/>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleDelete}>Delete</Button>
                </DialogActions>
            </React.Fragment>
        )
    }

    const fetchData = async () => {
        const res = await axios.get(`${Constants.BASE_URL}/api/command_user/all`)
        .then((res) => {
            setRowsAllAdmin(res.data.map((x)=>{ 
                x['id'] = x.user_id
                return x 
            }));
        })
    }

    React.useEffect(()=>{
        fetchData()
    }, [showLP])

    const getContent = (dialogName) => {
        switch (dialogName) {
          case 'Verify User':
                return <VerifyUserDialog/>;
            case 'Change User Type':
                return <ChangeUserTypeDialog/>;
            case 'Delete User':
                return <DeleteUserDialog/>;
            case 'Whats New':
                return <WhatsNew/>;
        }
    };

    return (
        <Grid container sx={styles.root}>
            <Paper
                elevation={0}
                style={{
                height: "100%",
                width: "100%",
                backgroundColor: "white",
                maxHeight: "90vh",
                marginBottom: 10
            }}
            >
                <Button style={{ height: '5%', width: '100%', backgroundColor: themes.colors.secondary, justifyContent: 'center', fontWeight: 'bold'}} onClick={()=>{setDialogName("Whats New"); setOpenDialog(true)}}>Click here to Set up What's New Content for Iloilo City Connect</Button>


                <Grid container style={{ flex: 4, height: '95%', width: '100%', justifyContent: 'center', overflow: "auto" }}>
                    <DataGrid
                        rows={rowsAllAdmin?rowsAllAdmin:rows}
                        loading={rowsAllAdmin.length<=0}
                        columns={ratio>1.1?columns:columns2}
                        pageSize={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        disableSelectionOnClick
                        getRowClassName={(params) => {
                            if (params.row.user_id == JSON.parse(localStorage.getItem('user')).user_id ) {
                                return `super-app-theme--Highlight`
                            }
                        }}
                    />
                </Grid>

                <BootstrapDialog
                    onClose={()=>setOpenDialog(false)}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                    fullWidth
                    maxWidth="sm"
                >
                    {getContent(dialogName)}
                </BootstrapDialog>
            </Paper>
        </Grid>
    )
}
