import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@mui/material';

import { themes } from '../../../core/theme'
import FilterDrawer from '../Dashboard/filterDrawer/index copy';
import SummaryCardsList from './SummaryCardsList';
import SummaryTable from './SummaryTable';
import { WindowContext } from '../../../contexts/WindowContext';

const styles = {
    root: {
        height: '95vh',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: themes.colors.sub3
    },
    rootMobile: {
        height: '100vh',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        backgroundColor: themes.colors.sub3
    }
}

const Summary = () => {

    return (
        <Grid container sx={styles.root}>
            
            <Grid container style={{ flex: 1.5, backgroundColor: 'white', flexDirection: 'column', paddingInline: '0.5vw' }}>
                <FilterDrawer noZoom={true}/>
            </Grid>

            <Grid container style={{ flex: 8.5, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>
              
                <Grid container style={{ flex: 5, flexDirection: 'column'}}> 
                    <SummaryCardsList/>
                </Grid> 

                <Grid container style={{ flex: 10, flexDirection: 'column' }}> 
                    <SummaryTable/>
                </Grid>
            </Grid>    
        
        </Grid>
    );
}

const SummaryMobile = () => {

    return (
        <Grid container direction="column" sx={styles.rootMobile}>
            
                <Grid item xs={4}> 
                    <SummaryCardsList/>
                </Grid> 

                <Grid item xs={7}> 
                    <SummaryTable/>
                </Grid>

        </Grid>
    );
}

export default function SosSummary() {
    const {ratio, setRatio} = useContext(WindowContext)

    if (ratio < 1.1) {
        return(<SummaryMobile/>)
    } else {
        return(<Summary/>)
    }
}
