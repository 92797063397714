import React, { useContext } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip, 
        GeoJSON, LayersControl, useMap, FeatureGroup, ScaleControl } from 'react-leaflet';
import { Typography, TextField } from '@mui/material';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { sampleDistrictGeojson } from '../../../core/data'
import { TourismContext } from '../../../contexts/TourismContext';

import RAIUnresolved from '../../../assets/RaiSos/RAI_unresolved.png'
import RAIUnresolvedSelected from '../../../assets/RaiSos/RAI_unresolved_selected.png'

import northArrowLogo from '../../../assets/Logos/northArrowIcon.svg'
import 'leaflet.browser.print/dist/leaflet.browser.print'
import 'leaflet.browser.print/src/leaflet.browser.print.sizes'
import 'leaflet.browser.print/src/leaflet.browser.print.utils'
import 'leaflet.browser.print/src/leaflet.browser.print.modes'
import 'leaflet-draw/dist/leaflet.draw.js'
import 'leaflet-draw/dist/leaflet.draw.css'

import iloiloLogo from '../../../assets/Logos/City of Iloilo Seal.png';
import dostLogo from '../../../assets/Logos/dost.png';
import upLogo from '../../../assets/Logos/up.png';
import tcagpLogo from '../../../assets/Logos/TCAGP_blue.png';
import lungsodLogo from '../../../assets/Logos/lungsod_blue.png';

const { BaseLayer } = LayersControl

const districtOptions = { fillColor: "#ffffff00", color: "#0d3c47", opacity: 0.65 }

const TourismPin = L.icon({
    iconUrl: RAIUnresolved,
    tooltipAnchor: [20, -20],
    iconAnchor: [20, 40],
    popupAnchor: [0, 0],
    iconSize: [40, 40],
});

const TourismPinSelected = L.icon({
    iconUrl: RAIUnresolvedSelected,
    tooltipAnchor: [40, -40],
    iconAnchor: [40, 80],
    popupAnchor: [0, 0],
    iconSize: [80, 80],
});

function Attractions() {
    const { attractionsShow, attractionsShow2, selectedAttraction, setSelectedAttraction } = useContext(TourismContext)

    const map = useMap();

    React.useEffect(()=>{
        if (selectedAttraction) {
            if (selectedAttraction.coords && selectedAttraction.coords.length>0) {
                map.flyTo([selectedAttraction.coords[1], selectedAttraction.coords[0]], 16, {
                    animate: true,
                    duration: 0.5 // in seconds
                })
            } else {
                map.flyTo([10.7232, 122.5621], 13, {
                    animate: true,
                    duration: 0.5 // in seconds
                })
            }
           
        } else {
            map.flyTo([10.7232, 122.5621], 13, {
                animate: true,
                duration: 0.5 // in seconds
            })
        }
    }, [selectedAttraction, map])

    return (
        <>
        {
        attractionsShow && attractionsShow.values ?
        attractionsShow.values.map((point) => {
            if (point.coords && point.coords.length>0) {
                return (
                    <Marker
                    icon={
                        selectedAttraction && selectedAttraction.id && selectedAttraction.id===point.id? TourismPinSelected:
                        attractionsShow2 && attractionsShow2.values && attractionsShow2.values.some((item)=>item.id===point.id)? TourismPinSelected:
                        TourismPin
                    }
                    key={point.id}
                    position={[
                        point.coords[1], point.coords[0]
                    ]}
                    eventHandlers={{
                        click: (e) => {
                            setSelectedAttraction(point)
                        }
                    }}
                    >
                        <Tooltip direction="top">{point.name}</Tooltip>
                    </Marker>
                )
            }
            return null
        }) : null}
        </>
    );
}

const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}

export default function Map() {

    var dateToday = new Date().toDateString().replace(/ /,', ') 
    const [mapTitlePrint, setMapTitlePrint] = React.useState("Map Title")
    const { mapRef, groupRef } = useContext(TourismContext)

    const inputMapTitle = (e) => {
        setMapTitlePrint(e.target.value)
    }

    function NorthArrowControl({ position }) {
        const positionClass =
          (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
        return (
            <div className={positionClass} style={{marginBottom:"35px"}}>
                <img className="leaflet-control" src={northArrowLogo} alt="north-arrow-logo" />
            </div>
        )
    }

    return (
        <MapContainer center={[10.7232, 122.5621]} zoom={13} scrollWheelZoom={true} style={{height:'100%', width: '100%'}} 
            whenCreated={ mapInstance => { 
                mapRef.current = mapInstance 
                L.control.browserPrint({
                    position: 'topleft', 
                    title: 'Print',
                    printModes: [
                        "Landscape",
                        "Portrait",
                    ],
                }).addTo(mapRef.current);
                
                mapRef.current.on(L.BrowserPrint.Event.Print, function(e){
                    L.control.scale({position:'topright'}).addTo(e.printMap); //scale!
                
                    var north2 = L.control({position: "topright"});
                    north2.onAdd = function(map) {
                        var div = L.DomUtil.create("div", "leaflet-control");
                        div.innerHTML = `<img src=${northArrowLogo} alt="lungsod-logo" />`;
                        return div;
                    }
                    north2.addTo(e.printMap);
                
                });
            } }
        >
            
            {/* BASEMAPS */}
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap">
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </BaseLayer>
                <BaseLayer name="CartoDB">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    />
                </BaseLayer>
                <BaseLayer name="ESRI">
                    <TileLayer
                        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    />
                </BaseLayer>
            </LayersControl>

            {/* POINTS ON THE MAP */}
            <FeatureGroup ref={groupRef}>
                <Attractions/>
            </FeatureGroup>
           
            {/* DISTRICT, NORTH ARROW, SCALE */}
            <GeoJSON style={districtOptions} data={sampleDistrictGeojson} />
            <NorthArrowControl position="bottomleft"/>
            <ScaleControl position="bottomleft"/>

            {/* MAP TITLE */}
            <div className="leaflet-bottom leaflet-right">
                <TextField className="leaflet-control" onChange={(e)=>{inputMapTitle(e)}} InputProps={{style: {fontWeight: 700, fontSize: "1rem"}}} style={{marginBottom:"3vh", backgroundColor: "rgba(255,255,255,0.8)"}} defaultValue={mapTitlePrint}></TextField>
            </div>
           
           {/* VISIBLE ONLY WHEN PRINTING */}
            <div leaflet-browser-print-pages="" className="legend-over-the-map-bottom" >
                <div className="legend-over-the-map-bottom-left">
                    <Typography variant="h3" gutterBottom component="div" style={{fontWeight:700}}>{mapTitlePrint}</Typography>
                    <small> Coordinate Reference System: WGS 84 <br/> Date Generassted: {dateToday}</small> 
                </div>

                <div className="legend-over-the-map-bottom-right">
                    <div style={{width:"100%", display:"flex", flexDirection:"row", justifyContent: "space-between", alignItems:"flex-start"}}>
                        <img src={iloiloLogo} alt="iloilo-logo" height={"40px"}></img>
                        <img src={dostLogo} alt="dost-logo" height={"40px"}></img>
                        <img src={upLogo} alt="up-logo" height={"40px"}></img>
                        <img src={tcagpLogo} alt="tcagp-logo" height={"40px"}></img>
                        <img src={lungsodLogo} alt="lungsod-logo" height={"60px"}></img>
                    </div>
                    <small>&copy; This map was made through the City Command Dashboard Created by Project LUNGSOD for the use of Iloilo City Government.</small>
                </div>
            </div> 
        </MapContainer>
    )
}


