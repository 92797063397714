import React from 'react';
import { Grid, Typography, FormHelperText, TextField, FormControl, Select, MenuItem, InputAdornment } from '@mui/material'
import { districtNames } from '../../core'

import { enGB } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

export default function ProfileInformation(props) {
    var createdUser = props.createdUser
    var date = props.date
    var dateError = props.dateError
    var currentBarangayList = props.currentBarangayList
    var handleBirthday = props.handleBirthday
    var formValChange = props.formValChange

    return (
        <Grid container direction="row" justifyContent="space-between" spacing={1} >
            <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>First Name *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    value={createdUser.first_name} 
                    onChange={(e)=>formValChange(e, "first_name")} 
                    size="small" required fullWidth id="first_name" name="first_name" autoFocus 
                    error={createdUser.isError.first_name?true:false}
                    helperText={createdUser.isError.first_name}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Middle Name *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    value={createdUser.middle_name} 
                    onChange={(e)=>formValChange(e, "middle_name")} 
                    size="small" required fullWidth id="middle_name" name="middle_name"  
                    error={createdUser.isError.middle_name?true:false}
                    helperText={createdUser.isError.middle_name}
                />
            </Grid>

            <Grid item xs={12} md={4}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Last Name *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}} 
                    value={createdUser.last_name} 
                    onChange={(e)=>formValChange(e, "last_name")} 
                    size="small" required fullWidth name="last_name" id="last_name" 
                    error={createdUser.isError.last_name?true:false}
                    helperText={createdUser.isError.last_name}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Birthday (dd/mm/yyyy) *</Typography>
                <DatePicker date={date} onDateChange={handleBirthday} locale={enGB} name="birthday">
                    {({ inputProps, focused }) => (
                        <TextField fullWidth size="small" style={{backgroundColor:"white", borderRadius:5}}
                            name="birthday"
                            required
                            autoComplete='off'
                            className={'input' + (focused ? ' -focused' : '')}
                            {...inputProps}
                            error={!dateError}
                            helperText={!dateError?"Must be at least 18 y/o.":null}
                        />
                    )}
                </DatePicker>
            </Grid>
        
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Contact No. *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                    }}
                    error={createdUser.isError.phone_number.length > 0} 
                    helperText={createdUser.isError.phone_number.length > 0? createdUser.isError.phone_number:null}
                    value={createdUser.phone_number} 
                    onChange={(e)=>formValChange(e, "phone_number")} 
                    size="small" required fullWidth name="phone_number" autoComplete="number" id="number" 
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Email *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    error={createdUser.isError.email.length > 0} 
                    helperText={createdUser.isError.email.length > 0? createdUser.isError.email:null}
                    value={createdUser.email} 
                    onChange={(e)=>formValChange(e, "email")} 
                    size="small" required fullWidth id="email" name="email" autoComplete="email"
                />
            </Grid>
            
            <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Address *</Typography>
                <TextField 
                    style={{backgroundColor:"white", borderRadius:5}}
                    value={createdUser.street_address} 
                    onChange={(e)=>formValChange(e, "street_address")} 
                    size="small" required fullWidth id="street_address" name="street_address" autoComplete="street_address"  
                    error={createdUser.isError.street_address}
                    helperText={createdUser.isError.street_address}
                />
            </Grid>
        
            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>District *</Typography>
                <FormControl fullWidth size="small" required error={createdUser.isError.district?true:false}>
                    <Select
                        style={{backgroundColor:"white", borderRadius:5}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createdUser.district}
                        onChange={(e)=>formValChange(e, "district")} 
                        name="district"
                    >
                        <MenuItem key="None" value={""} style={{display: 'none'}}></MenuItem>
                        {districtNames.map((name)=> {
                            return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                        })}
                    </Select>
                    <FormHelperText>{createdUser.isError.district}</FormHelperText>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Barangay *</Typography>
                <FormControl fullWidth size="small" required error={createdUser.isError.barangay?true:false}>
                    <Select
                        MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                        style={{backgroundColor:"white", borderRadius:5}}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={createdUser.barangay}
                        onChange={(e)=>formValChange(e, "barangay")} 
                        name="barangay"
                    >
                        <MenuItem key="None" value={""} style={{display: 'none'}}></MenuItem>
                        {currentBarangayList.map((name)=> {
                            return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                        })}
                    </Select>
                    <FormHelperText>{createdUser.isError.barangay}</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    )}