import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Grid, Checkbox, IconButton, FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { themes } from '../../../../core/theme';

const useStyles = makeStyles((theme) => ({
  openStyle: {
    backgroundColor: "rgba(218,218,218,0.3)",
    boxShadow: 3,
    borderRadius: 5,
    borderBottomLeftRadius:0, borderBottomRightRadius:0,
    borderBottomWidth:0, 
    borderWidth:1,
    borderStyle: "solid"
  },
  closedStyle: {
    backgroundColor: "rgba(218,218,218,0.3)",
    boxShadow: 3,
    borderRadius: 5,
    borderWidth:1,
    borderStyle: "solid"
  },
  
}));

const styles = {
  text: {
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
    color: themes.colors.filterText
  }
}

export default function CheckboxCategory(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  var state = props.value.Context[0]
  var setState = props.value.Context[1]
  
  // start: pang populate ng context
  React.useEffect(()=>{
    if (props.value.hasOwnProperty('Child')) {
      if (Object.keys(state).length === 0 ) {
        for (const key of props.value.Child) {
          state[key] = true;
        }
      }
    }
   
  }, [state])
  // end: pang populate ng context
  
  const onCheckboxParent = async (e, parent) => {
    if (parent!=="Others") {
      var obj = {}
      for (const key in state) {
        obj[key] = e.target.checked
      }
      setState(obj)
    } else {
      setState(e.target.checked)
    }
    
  }
  const onCheckboxChild = (e, child) => {
    setState({
      ...state, 
      [child]: e.target.checked
    })
  }

  return (
      <Grid item style={{padding:4}}>
        <Grid container direction={"row"} alignItems="center" 
          className={open?classes.openStyle:classes.closedStyle}
          >
         
            <Grid item xs={2}>
              <Checkbox
                checked={props.value.hasOwnProperty('Child')?Object.keys(state).every(function(v){ return state[v] === true }): state}
                indeterminate={Object.keys(state).some(function(v){ return state[v] === true }) && Object.keys(state).some(function(v){ return state[v] === false })}
                onChange={(e)=>onCheckboxParent(e, props.value.Parent)}
              />
            </Grid>
           <Grid item xs={8}>
            <Typography style={{ ...styles.text, whiteSpace: 'pre-line'}}>{props.value.Parent}</Typography>
           </Grid>
           <Grid item xs={2}>
            {open?
              <IconButton onClick={()=>setOpen(!open)}color="primary" aria-label="upload picture" component="span">
                <ExpandLessIcon/>
              </IconButton>
            :
            props.value.hasOwnProperty('Child')?
              <IconButton onClick={()=>setOpen(!open)}color="primary" aria-label="upload picture" component="span">
                <ExpandMoreIcon/>
              </IconButton>
            :null
            }
          </Grid>

        </Grid>

        {open?
          <div style={{borderRadius:5, 
            borderTopLeftRadius:0, borderTopRightRadius:0,
            borderTopWidth:0, borderBottomWidth:1, borderLeftWidth:1, borderRightWidth:1, 
            borderStyle: "solid"}}>
            <Grid container flexDirection={"column"} style={{paddingLeft:40}}>
              {state?props.value.hasOwnProperty('Child')? props.value.Child.map((item, index)=> {
                return (
                  <FormControlLabel
                    key={index}
                    label={<Typography gutterBottom style={{...styles.text, whiteSpace: 'pre-line'}}>{item}</Typography>}
                    control={<Checkbox checked={state[item]} value={item} onChange={(e)=>onCheckboxChild(e, item)} />}
                  />
                )
              }):null:null}
          </Grid>
          </div>
        :null}
      </Grid>
  );
}
