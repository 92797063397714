import React, { useContext, useEffect, useState } from 'react';
import { 
    Button, Grid, Typography, Paper, TextField, 
    FormControl, MenuItem, Select, 
    IconButton, Box, CircularProgress, Autocomplete } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import axios from 'axios';

import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { SosContext } from '../../../contexts/SosContext';
import { djangoToJsDate, getTokenAndNotifyResolved, getTokenAndNotifySeen, getTokenAndNotifyRemarks } from '../../../core/functions';
import { getBarangayList } from '../../../core/functions'

import { Logs } from './Logs';

import { MapConsumer, MapContainer, TileLayer, useMap, Marker, Tooltip } from 'react-leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import LCG from 'leaflet-control-geocoder';
import { EmergencyPinUnresolvedSelected, EmergencyPinResolvedSelected } from '../../../core/mapIcons'

const geocoder = LCG.nominatim();

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    title: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.primary
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        color: themes.colors.filterText
    },
    attractionPic: {
        width: '45vh', 
        height: '30vh',
        backgroundColor: themes.colors.greyTxt,
        alignItems: 'center', 
        justifyContent: 'center'
    },
    attractionLabelText: {
        color: 'white',
        fontFamily: 'Mulish',
        textAlign: 'center',
        fontSize: '4vh',
        marginInline: '1vw'
    },
    resolved: {
        backgroundColor: themes.colors.primary, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    unresolved: {
        backgroundColor: themes.colors.tertiary, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    verified: {
        backgroundColor: themes.colors.primary,
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
    unverified: {
        backgroundColor: themes.colors.greyTxt, 
        padding:'0.5vh', 
        color:"white",
        fontFamily: 'OpenSans',
        fontSize: '2vh',
        borderRadius: '0.5vh',
        textAlign:"right"
    },
}
function LeafletgeoSearch() {
    const map = useMap();
    useEffect(() => {
      const provider = new OpenStreetMapProvider();
  
      const searchControl = new GeoSearchControl({
        provider,

        showMarker: false
      });
  
      map.addControl(searchControl);
  
      return () => map.removeControl(searchControl);
    }, []);
  
    return null;
}

export default function SosDetails () {
   "function used to view and edit sos details"
    const {
        selectedRow, setSelectedRow, 
        emergencyAll, setEmergencyAll,
        newEmergency, setNewEmergency
    } = useContext(SosContext);
    const [isEditing, setIsEditing] = useState(false);
    const [statusValue, setStatusValue] = useState(selectedRow.status) 
    const [isVerifiedValue, setIsVerifiedValue] = useState(selectedRow.is_verified==="false"?"Unverified":"Verified") 
    const [remarksValue, setRemarksValue] = useState(selectedRow.remarks) 
    const [moduleName, setModuleName] = useState(selectedRow.hasOwnProperty('method')?"emergency":"report") 
    const [logRows, setLogRows] = React.useState()
    const [loading, setLoading] = useState(false)

    const [district, setDistrict] = useState(selectedRow.district)
    const [location, setLocation] = useState(selectedRow.address)
    const [coords, setCoords] = useState(selectedRow.coords)
    const [category, setCategory] = useState(selectedRow.category)
    const [currentBarangayList, setCurrentBarangayList] = React.useState([]);
    const mapRef = React.useRef(); // for map!
    const [searchedLoc, setSearchedLoc] = React.useState({})
    const [dragged, setDragged] = useState(false)
    const [contactData, setContactData] = React.useState({});


    
    const getLogRows= async (report_id) => {
        const res = await axios.get(`${Constants.BASE_URL}/api/logs/get/commanduser/model_id`, { params : {
            "model_id": report_id
        }})
        .then((res) => {
            setLogRows(res.data)
        })
    }
    const getEmergencyContactById = async (id) => {
            const res = await axios.get(`${Constants.BASE_URL}/api/emergency/contact/get`, { params: {"user_id": id}})
            .then((res)=>{
                if (res.data) {
                    setContactData(res.data)
                }
            })
    }

    const createCommandUserLog = (action, report, model_edits) => {
        var par 
        if (action.includes("edit")) {
            par = {
                "user_id": JSON.parse(localStorage.getItem('user')).user_id,
                "model_name": report.hasOwnProperty('method')?"Emergency":"Report",
                "model_id": report.id,
                "model_reference_no": report.reference_no,
                "model_edits": model_edits.length>0?model_edits:[],
                "action": action
            }
        } else {
            par = {
                "user_id": JSON.parse(localStorage.getItem('user')).user_id,
                "model_name": report.hasOwnProperty('method')?"Emergency":"Report",
                "model_id": report.id,
                "model_reference_no": report.reference_no,
                "action": action
            }
        }
       
        console.log("createCommandUserLog par", par)
    
        const res = axios.post(`${Constants.BASE_URL}/api/logs/create/commanduser`, par)
        .then((res)=> {
            console.log("createCommandUserLog", res.data.message)
            getLogRows(report.id)

        })
    }

    useEffect(() => { // needed kapag isEditing ang state tapos nagpalit ng selected row/ nagclose.
        if (selectedRow) {
          getLogRows(selectedRow.id)
          if (selectedRow.user_id) {getEmergencyContactById(selectedRow.user_id)}
          
        }
        setIsEditing(false)

    }, [selectedRow])

    useEffect(() => {

        if (logRows) {
    
            if (newEmergency.count>0) {
                var newArr = newEmergency.values.filter(val => val.id !== selectedRow.id) 
                if (newArr.length < newEmergency.count) {
                    setNewEmergency({
                        count: newArr.length,
                        values: newArr
                    })
                }
    
                if (newEmergency.values.some((val) => val.id === selectedRow.id)) {
                    createCommandUserLog("seen", selectedRow)
                    
                    if (logRows.length===0) {
                        getTokenAndNotifySeen(selectedRow.id, selectedRow.username, moduleName, selectedRow.reference_no)
                    }
                }
            } else {
            }
        }

    }, [logRows, newEmergency])

    const handleStatus = (event) => {
        setStatusValue(event.target.value); // Resolved/ Unresolved
    };

    const handleIsVerified= (event) => {
        setIsVerifiedValue(event.target.value); // Verified/ Unverified
    };

    const handleRemarks = (event) => {
        setRemarksValue(event.target.value); 
    };

    const handleUndo = () => {
        setIsEditing(false); 
        setStatusValue(selectedRow.status)
        setIsVerifiedValue(selectedRow.is_verified==="false"?"Unverified":"Verified")
        setRemarksValue(selectedRow.remarks); 
        setDistrict(selectedRow.district); 
        setCategory(selectedRow.category); 
        setLocation(selectedRow.address); 
        setSearchedLoc({})
        setCoords(selectedRow.coords)
        setDragged(false)
    }

    const handleSave = async (id, status, remarks, is_verified, moduleName, district, category) => {
        setLoading(true)
        var newCoords = [
            searchedLoc.x,
            searchedLoc.y
        ]
       
        var verification // kabaligtaran ng sa handleUndo
        if (is_verified == "Verified") {
            verification = "true"
        } else {
            verification = "false"
        }
        console.log("handleSave", {
            "id": id,
            "status": status,
            "remarks": remarks,
            "is_verified": verification,
            "district": district,
            "category": category,
            "address": dragged?searchedLoc.label:selectedRow.address,
            "coords": dragged?newCoords:selectedRow.coords
        })
        if (moduleName === "emergency") { 
            const res = await axios.post(`${Constants.BASE_URL}/api/emergency/edit/status`, {
                "id": id,
                "status": status,
                "remarks": remarks,
                "is_verified": verification,
                "district": district,
                "category": category,
                "address": dragged?searchedLoc.label:selectedRow.address,
                "coords": dragged?newCoords:selectedRow.coords
            })
            .then((res) => {
                console.log("handleSave res.data", res.data)
                if (res.data.message == "Item successfully edited") {
                    setLoading(false)
                    setIsEditing(false)

                    var newEmergencyAll= emergencyAll.values.map(obj => res.data.values.find(o => o.id === obj.id) || obj)
                    setEmergencyAll({ // // pagka-edit and save
                        count: newEmergencyAll.length,
                        values: newEmergencyAll})

                    var model_edits = []
                    if (status !== selectedRow.status) {model_edits.push(['status', status])}
                    if (remarks !== selectedRow.remarks) {
                        model_edits.push(['remarks', remarks])
                        getTokenAndNotifyRemarks(id, selectedRow.username, moduleName, selectedRow.reference_no, remarks)
                    }
                    if (verification !== selectedRow.is_verified) {model_edits.push(['verification', is_verified])}
                    if (district !== selectedRow.district) {model_edits.push(['district', district])}
                    if (category !== selectedRow.category) {model_edits.push(['category', category])}
                    if (dragged) {
                        model_edits.push(['address', searchedLoc.label])
                        model_edits.push(['coords', newCoords.toString()])
                    }
                    console.log("model_edits", model_edits)
                    createCommandUserLog("edit", res.data.values[0], model_edits)
                    setSelectedRow(res.data.values[0])
                }
            })
        } 
        
        if (status !== selectedRow.status && status === "Resolved") { // notify once, when resolved.
            getTokenAndNotifyResolved(id, selectedRow.username, moduleName, selectedRow.reference_no)
        }
    }
    
    const handleDistrict = (event) => {
        setCurrentBarangayList(getBarangayList(event.target.value))
        setDistrict(event.target.value)
    }

    const handleCategory = (event) => {
            setCategory(event.target.value); 

    };

    

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                    maxHeight: "90vh",
                    marginBottom: 20
                }}
            >
                <Grid container direction="row" alignItems="center" style={{padding:"2vh"}}>

                    <Grid item>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={()=>setSelectedRow(null)}
                            aria-label="close"
                            >
                            <ArrowBackIosIcon fontSize='large'/>
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <Typography style={styles.title}>Case ID {selectedRow.reference_no}</Typography>
                    </Grid>
                </Grid> 
                
                <Grid container style={{ height: '92%', width: '100%', paddingTop: '1vh', overflow: "auto"}}>

                    <Grid container style={{height: '60%', width: '100%', flex: 1, paddingTop: '1vh', marginInline: '5%'}}>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Name:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line'}}>{selectedRow.name}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Contact No:`}</Typography>
                            <Typography display="inline" sx={styles.text}>{selectedRow.contact_no? `+63${selectedRow.contact_no}`:"------" }</Typography>
                        </Grid>
                       
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Person to Contact:`}</Typography>
                            {Object.keys(contactData).length>1?
                            <Grid container style={{padding: "2%", borderColor: themes.colors.primary, borderStyle: "solid", borderWidth:1, borderRadius: 5}}>
                                <Grid item xs={5}><Typography>Name: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography align='right'>{contactData.contact_name}</Typography>
                                </Grid>
                               
                                <Grid item xs={5}><Typography>Relationship: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography align='right'>{contactData.contact_relationship}</Typography>
                                </Grid>
                                <Grid item xs={5}><Typography>Email: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography align='right'>{contactData.contact_email}</Typography>
                                </Grid>
                                <Grid item xs={5}><Typography>No.: </Typography></Grid>
                                <Grid item xs={7}>
                                    <Typography align='right'>{contactData.contact_number}</Typography>
                                </Grid>
                            </Grid>
                            :
                            Object.keys(contactData).length === 0?
                                <Grid container style={{margin: "2%", padding: "2%", borderColor: themes.colors.primary, borderStyle: "solid", borderWidth:1, borderRadius: 10}} spacing={1}>
                                    <Typography>Contact does not exist.</Typography>
                                </Grid>
                            :
                                <Grid container style={{margin: "2%", padding: "2%", borderColor: themes.colors.primary, borderStyle: "solid", borderWidth:1, borderRadius: 10}} spacing={1}>
                                    <Typography>{contactData.message}</Typography>
                                </Grid>
                            }
                        </Grid>
                         {isEditing?
                         <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%", borderColor:isEditing?themes.colors.primary:"white", borderStyle: "solid", borderWidth:2}}>
                            <MapContainer
                                whenCreated={ mapInstance => { 
                                    mapRef.current = mapInstance 
                                    mapRef.current.on('geosearch/showlocation', (result)=>{
                                        setSearchedLoc(result.location);
                                        setDragged(true)
                                    });
                                }}
                                center={[selectedRow.coords[1], selectedRow.coords[0]]} zoom={16} scrollWheelZoom={true} style={{height: 300, width: '100%'}}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {isEditing?<LeafletgeoSearch/>:null}

                                {Object.keys(searchedLoc).length>0?
                                    <Marker
                                        icon={selectedRow.status !== "Resolved"? EmergencyPinUnresolvedSelected:EmergencyPinResolvedSelected}
                                        key={"searched-location"}
                                        position={[searchedLoc.y, searchedLoc.x]}
                                        draggable={isEditing}
                                        eventHandlers={{
                                            dragend: (e) => {
                                                geocoder.reverse(e.target._latlng, mapRef.current.options.crs.scale(mapRef.current.getZoom()), function(results) {
                                                    var r = results[0];
                                                    setSearchedLoc({
                                                        label: r.name,
                                                        y: e.target._latlng.lat,
                                                        x: e.target._latlng.lng
                                                    })
                                                    setDragged(true)
                                                });
                                            }
                                        }}
                                    >
                                        {searchedLoc.label? <Tooltip direction="top">{searchedLoc.label}</Tooltip>
                                        : null}
                                    </Marker>
                                :
                                <Marker
                                        icon={selectedRow.status !== "Resolved"? EmergencyPinUnresolvedSelected:EmergencyPinResolvedSelected}
                                        key={"searched-location"}
                                        position={[selectedRow.coords[1], selectedRow.coords[0]]}
                                        draggable={isEditing}
                                        eventHandlers={{
                                            dragend: (e) => {
                                                geocoder.reverse(e.target._latlng, mapRef.current.options.crs.scale(mapRef.current.getZoom()), function(results) {
                                                    var r = results[0];
                                                    setSearchedLoc({
                                                        label: r.name,
                                                        y: e.target._latlng.lat,
                                                        x: e.target._latlng.lng
                                                    })
                                                    setDragged(true)

                                                });
                                            }
                                        }}
                                    >
                                        {searchedLoc.label? <Tooltip direction="top">{searchedLoc.label}</Tooltip>
                                        : null}
                                    </Marker>
                                }
                            </MapContainer>
                            {searchedLoc.y?
                                <Typography>[lat: {Math.round(searchedLoc.y*1000)/1000}, lon: {Math.round(searchedLoc.x*1000)/1000}]</Typography>
                                : 
                                <Typography>[lat: {Math.round(selectedRow.coords[1]*1000)/1000}, lon: {Math.round(selectedRow.coords[0]*1000)/1000}]</Typography>
                                
                            }
                        </Grid>
                        :null}
                         <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Location:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{searchedLoc.label?searchedLoc.label:selectedRow.address}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Date:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{djangoToJsDate(selectedRow.date)[0]}</Typography>
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Time:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{djangoToJsDate(selectedRow.date)[1]}</Typography>
                        </Grid>

                         <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`District:`}</Typography>
                            {isEditing ?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedRow.district}
                                        onChange={handleDistrict}
                                        size='small'
                                    >
                                        <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                                        {Constants.districtNames.map((name)=> {
                                            return (
                                                <MenuItem key={name} value={name}>
                                                    <Typography style={{textAlign: "right", fontSize: "2vh"}}>{name}</Typography>
                                                </MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.district}</Typography>
                            }
                        </Grid>

                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginTop: "2%" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Category:`}</Typography>
                            {isEditing ?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedRow.category}
                                        onChange={handleCategory}
                                        size='small'
                                    >
                                        <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                                        {Constants.emergencyCat.map((name)=> {
                                            return (
                                                <MenuItem key={name.label} value={name.label}>
                                                    <Typography style={{textAlign: "right", fontSize: "2vh"}}>{name.label}</Typography>
                                                </MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.category}</Typography>
                            }
                        </Grid>
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary}}>{`Description:`}</Typography>
                            <Typography display="inline" sx={{...styles.text, whiteSpace: 'pre-line', width: '60%', textAlign:'right'}}>{selectedRow.desc | selectedRow.desc != "null"?selectedRow.desc:""}</Typography>
                        </Grid>

                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Status:`}</Typography>
                            {isEditing && selectedRow.status !== "Resolved" ?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={selectedRow.status}
                                        onChange={handleStatus}
                                        size='small'
                                    >
                                        <MenuItem value={"Resolved"}>
                                            <Typography sx={{...styles.resolved, width: "95%"}}>
                                                Resolved
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={"Unresolved"}>
                                            <Typography sx={{...styles.unresolved, width: "95%"}}>
                                                Unresolved
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...selectedRow.status==="Resolved"?styles.resolved:styles.unresolved, whiteSpace: 'pre-line', width: '60%', textAlign:'right', marginTop: '0.5vh'}}>{selectedRow.status}</Typography>
                            }
                        </Grid>

                        <Grid container style={{ width: '100%', justifyContent:"space-between"}}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary}}>{`Verification:`}</Typography>
                            {isEditing && selectedRow.is_verified==="false"?
                                <FormControl style={{width:"60%"}} focused>
                                    <Select
                                        labelId="is-verified"
                                        id="is-verified-select"
                                        defaultValue={selectedRow.is_verified==="false"?"Unverified":"Verified"}
                                        onChange={handleIsVerified}
                                        size='small'
                                        >
                                        <MenuItem value={"Verified"}>
                                            <Typography sx={{...styles.verified, width: "95%"}}> 
                                                Verified
                                            </Typography>
                                        </MenuItem>
                                        <MenuItem value={"Unverified"} >
                                            <Typography sx={{...styles.unverified, width: "95%"}}>
                                                Unverified
                                            </Typography>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                :
                                <Typography display="inline" sx={{...selectedRow.is_verified==="false"? styles.unverified : styles.verified, whiteSpace: 'pre-line', width: '60%', textAlign:'right', marginTop: '0.5vh'}}>{selectedRow.is_verified==="false"? "Unverified" : "Verified"}</Typography>
                            }
                        </Grid>

                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>  
                            <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`Remarks:`}</Typography>
                            {isEditing?
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    defaultValue={selectedRow.remarks}
                                    onChange={handleRemarks}
                                    style={{width: "100%"}}
                                />:
                                <TextField
                                    id="outlined-multiline-static"
                                    multiline
                                    rows={4}
                                    value={selectedRow.remarks}
                                    style={{width: "100%"}}
                                    disabled
                                />
                            }
                            
                        </Grid>
                      
                        <Grid container style={{ width: '100%', justifyContent:"space-between" }}>
                            {isEditing?
                                <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                    <Box sx={{ position: 'relative', width:"48%" }}>
                                    <Button style={{width:"100%"}}
                                        disabled={
                                            (statusValue === selectedRow.status) && (remarksValue === selectedRow.remarks) && 
                                            (district === selectedRow.district) &&
                                            (category === selectedRow.category) &&
                                            !dragged &&
                                            (isVerifiedValue === (selectedRow.is_verified==="false"?"Unverified":"Verified")) ||
                                            loading
                                        }
                                        variant="contained"
                                        onClick={ () =>
                                            handleSave(
                                                selectedRow.id, 
                                                statusValue, 
                                                remarksValue, 
                                                isVerifiedValue, 
                                                moduleName, 
                                                district, 
                                                category,
                                                location,
                                                searchedLoc
                                            )}
                                        >
                                            Save Changes
                                            
                                    </Button>
                                    
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                            color: themes.colors.primary,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                    </Box>
                                    <Button style={{width:"50%"}} onClick={handleUndo} variant="outlined">Undo</Button>
                                </Grid> :
                                JSON.parse(localStorage.getItem('user')).user_type == "Admin" ?
                                    <Grid container direction="row" justifyContent="space-between" style={{marginTop:15}}>
                                        <Button fullWidth onClick={()=>setIsEditing(true)} variant="contained">Edit</Button>
                                    </Grid>
                                    :
                                    null
                            }
                        </Grid>
                        <Grid container style={{ width: '100%', height: '5%'}} />
                       
                        <Grid container style={{ width: '100%', justifyContent:"space-between", marginBottom: "2%" }}>
                            <Typography 
                                style={{
                                    fontFamily: "OpenSans Semibold", 
                                    color: themes.colors.primary, 
                                    marginBottom:"2%"
                                }}>
                                    Logs
                            </Typography>
                            <Logs rows={logRows} />
                            
                        </Grid>
                        <Grid container style={{ width: '100%', height: '5%'}} />
                    </Grid>
                    
                </Grid>
            </Paper>
        </Grid>
    );
}

