import React, { useEffect, useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { themes } from '../../../core/theme'

import FilterDrawer from '../Dashboard/filterDrawer';
import SummaryCardsList from './SummaryCardsList';
import SummaryTable from './SummaryTable';
import { WindowContext } from '../../../contexts/WindowContext';

const styles = {
    root: {
        height: '95vh',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: themes.colors.sub3
    },
    rootMobile: {
        height: '100vh',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        backgroundColor: themes.colors.sub3
    },
}

const Summary = () => {

    return (
        <Grid container sx={styles.root}>
            
            {/* LEFT SIDE COMPONENT: FILTER MENU */}
            <Grid container style={{ flex: 1.5, backgroundColor: 'white', flexDirection: 'column', paddingInline: '0.5vw' }}>
                <FilterDrawer noZoom={true}/>
            </Grid>

            {/* RIGHT SIDE COMPONENT: MAP */}
            <Grid container style={{ flex: 8.5, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>

                {/* SUMMARY TABLE */}
                <Grid container style={{ flex: 10, flexDirection: 'column'}}> 
                    <SummaryTable/>
                </Grid>
            </Grid>    
        
        </Grid>
    );
}

const SummaryMobile = () => {

    return (
        <Grid container direction="column" sx={styles.rootMobile}>
            
            {/* RIGHT SIDE COMPONENT: MAP */}
                {/* SUMMARY CARDS */}
                <Grid item xs={4}> 
                    <SummaryCardsList/>
                </Grid> 

                {/* SUMMARY TABLE */}
                <Grid item xs={7}> 
                    <SummaryTable/>
                </Grid>

        </Grid>
    );
}

export default function TourismSummary() {
    const {ratio, setRatio} = useContext(WindowContext)

    if (ratio < 1.1) {
        return(<SummaryMobile/>)
    } else {
        return(<Summary/>)
    }
}
