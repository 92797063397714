import React from 'react';
import { Grid, Typography, TextField } from '@mui/material'

export default function AccountInformation(props) {
    var createdUser = props.createdUser
    var formValChange = props.formValChange

    return (
        <Grid container direction="row" justifyContent="space-between" spacing={1} >
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary" component="div"  gutterBottom>Username *</Typography>
            <TextField 
                style={{backgroundColor:"white", borderRadius:5}}
                error={createdUser.isError.username.length > 0} 
                helperText={createdUser.isError.username.length > 0? createdUser.isError.username:null}
                value={createdUser.username} 
                onChange={(e)=>formValChange(e, "username")} 
                size="small" required fullWidth id="username" name="username"  />
        </Grid>
        <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="text.secondary" component="div"  gutterBottom>Password *</Typography>
            <TextField 
                style={{backgroundColor:"white", borderRadius:5}}
                error={createdUser.isError.password.length > 0} 
                helperText={createdUser.isError.password.length > 0? createdUser.isError.password:null}
                value={createdUser.password} 
                onChange={(e)=>formValChange(e, "password")} 
                size="small" required fullWidth name="password" type="password" id="password" />
        </Grid>
    </Grid>
)}
    