import React, { useContext } from 'react';
import {Button, Grid, Typography, Paper } from '@mui/material';
import {themes} from '../../../core/theme'
import { RaiContext } from '../../../contexts/RaiContext';
import { dataLayers } from './layers'

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        paddingLeft: '0.5vw'
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    },
}


export default function Tools () {

    const { showRaiPoints, setShowRaiPoints, buffer, setBuffer,
            concave, setConcave, convex, setConvex,
            voronoi } = useContext(RaiContext)

    const Title = (props) => {
        return (
            <Grid>
                <Typography sx={styles.text}> {props.text} </Typography>
            </Grid>
        )
    }

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                height: "100%",
                width: "100%",
                backgroundColor: themes.colors.sub3,
                maxHeight: "90vh",
                marginBottom: 20
                }}
            >
                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Title text="MAP CREATE TOOLS"/>
                </Grid> 
                
                <Grid container style={{ height: '5%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>
                    <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{`RAI Points`}</Typography>
                    <Button style={{height: '3vh', backgroundColor: themes.colors.primary, color: "white"}} onClick={() => setShowRaiPoints(!showRaiPoints)}>{showRaiPoints?"Hide":"Show"} Points</Button>
                </Grid>

                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Title text="Transformation"/>
                </Grid> 
                
                <Grid container style={{ height: '5%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>
                    <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>Buffer by 1km</Typography>
                    <Button style={{height: '3vh', backgroundColor: themes.colors.primary, color: "white"}} onClick={() => setBuffer(!buffer)}>{buffer?"Remove":"Add to Map"}</Button>
                </Grid>

                <Grid container style={{ height: '5%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>
                    <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>Concave Polygon</Typography>
                    <Button style={{height: '3vh', backgroundColor: themes.colors.primary, color: "white"}} onClick={() => setConcave(!concave)}>{concave?"Remove":"Add to Map"}</Button>
                </Grid>

                <Grid container style={{ height: '5%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>
                    <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>Convex Polygon</Typography>
                    <Button style={{height: '3vh', backgroundColor: themes.colors.primary, color: "white"}} onClick={() => setConvex(!convex)}>{convex?"Remove":"Add to Map"}</Button>
                </Grid>

                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Title text="Add Layers"/>
                </Grid>
                {
                    dataLayers.map( (layer) => {
                        return(
                            <Grid container style={{ height: '5%', width: '100%', paddingTop: '1vh', overflow: "auto" }}>
                                <Typography display="inline" sx={{...styles.text, fontFamily: "OpenSans Semibold", color: themes.colors.primary, marginRight: 5}}>{layer.title}</Typography>
                                <Button style={{height: '3vh', backgroundColor: themes.colors.primary, color: "white"}} onClick={() => console.log("Clicked!")}>{voronoi?"Remove":"Add to Map"}</Button>
                            </Grid>
                        )
                        }
                    )
                }
                
            </Paper>
        </Grid>
    );
}
