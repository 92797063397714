import React, { useEffect, useContext, useState } from 'react';
import { Dialog, Grid } from '@mui/material';

import { themes } from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';

import AttractionList from './AttractionList';
import Map from './DashboardMap'
import FilterDrawer from './filterDrawer';
import AttractionDetails from './AttractionDetails';
import AttractionDetailsNew from './AttractionDetailsNew';
import { WindowContext } from '../../../contexts/WindowContext';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: themes.colors.sub3,
        minHeight: "93vh",
    },
    menuButton: {
        color: themes.colors.poloBlue,
        height: "6vh",
        paddingInline: "2vw",
        fontSize: '2vh',
        fontFamily : "Mulish",
        textTransform: "capitalize",
        borderRadius: 0,
        borderRight: 2, 
        borderColor: "white",
        "&:hover" : {
            color: 'white'
        }
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    }
}

const Dashboard = () => {

    const { selectedAttraction, newAttraction, setNewAttraction } = useContext(TourismContext)

    return (
        <Grid container sx={styles.root}>
            
            {/* LEFT SIDE COMPONENT: FILTER MENU */}
            <Grid container style={{ flex: 1.5, backgroundColor: 'white', flexDirection: 'column', paddingInline: '0.5vw' }}>
                <FilterDrawer/>
            </Grid>

            {/* MIDDLE SIDE COMPONENT: LIST */}
            <Grid container style={{ flex: 2.5, flexDirection: 'column', paddingInline: '0.5vw', paddingTop: '1vh' }}>
                { selectedAttraction ? <AttractionDetails/> : <AttractionList/> }
            </Grid>  

            {/* RIGHT SIDE COMPONENT: MAP */}
            <Grid container style={{ flex: 6, flexDirection: 'column', paddingRight: '1vw', paddingTop: '1vh', paddingBottom: '2vh' }}>
                <Map/>
            </Grid>    
            <Dialog
                onClose={() => setNewAttraction(false)}
                aria-labelledby="customized-dialog-title"
                open={newAttraction}
                fullWidth
                maxWidth="sm"
            >
                <AttractionDetailsNew onClose={() => setNewAttraction(false)}/>
            </Dialog>
        
        </Grid>
    );
}

const DashboardMobile = () => {
   
    return (
        <Grid container sx={{width:"100%", height:"93vh"}}>
            <Map/>
        </Grid>
    );
}

export default function TourismDashboard() {
    const {ratio, setRatio} = useContext(WindowContext)

    if (ratio < 1.1) {
        return(<DashboardMobile/>)
    } else {
        return(<Dashboard/>)
    }
}
