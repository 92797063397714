import { 
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    gridClasses,
    GridToolbar
  } from '@mui/x-data-grid';

import { GridCsvExportOptions } from '@mui/x-data-grid';
import { GridPrintExportOptions } from '@mui/x-data-grid';
import { Grid } from '@mui/material';

export function CustomToolbar() {
    return (
      <GridToolbarContainer style={{padding: 10}}>
        <Grid container>
          <Grid item><GridToolbarColumnsButton /></Grid>
          <Grid item><GridToolbarFilterButton /></Grid>
          <Grid item style={{flexGrow:1}}><GridToolbarDensitySelector /></Grid>
          <Grid item>
            <GridToolbarExport 
              variant='contained'
              printOptions={{ hideToolbar:true, hideFooter:true }} 
            />
          </Grid>
        </Grid>
        
      </GridToolbarContainer>
    );
  }