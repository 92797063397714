import React, { useContext, useState, useEffect } from 'react';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { themes } from '../../../../core/theme';
import { TourismContext } from '../../../../contexts/TourismContext';
import { getBarangayList } from '../../../../core/functions';

const styles = {
  textField: {
    "& .MuiOutlinedInput-input" : { // text style
        fontFamily: 'OpenSans',
        fontSize: '1vh', 
    },
    "& .MuiOutlinedInput-notchedOutline" : { // border of textField
        backgroundColor: "rgba(218,218,218,0.3)",
        boxShadow: 3, 
    },
    "& .MuiInputLabel-outlined, .MuiInputLabel-root": { // label style
        color: themes.colors.filterText,
        fontFamily: 'OpenSans',
        fontSize: '1vh',
    }
  },
  inputLabel: { // label style
    color: themes.colors.filterText,
    fontFamily: 'OpenSans',
    fontSize: '1.5vh',
  },
  selectChoices: { // select choices style
    color: 'white',
    fontFamily: 'OpenSans',
    fontSize: '1vh',
    backgroundColor: themes.colors.primary
  }
}

// start: for multiple select 1
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

// end: for multiple select 1

export default function MultipleSelectDistrict() {
  const { 
    districtName, 
    checkedAllBrgys, setCheckedAllBrgys, 
    listOfBarangays, setListOfBarangays, 
    barangayName, setBarangayName 
  } = useContext(TourismContext)


  // start: for multiple select 2
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
  
    if (value[value.length-1] !== 'Select all') {
      setBarangayName(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );

      // if isa lang, set List Barangays to yung function kuha barangays

    } else {
      if (checkedAllBrgys) {
        setBarangayName([]);
      } else {
        setBarangayName(getBarangayList(districtName[0]))
      } 
      setCheckedAllBrgys(!checkedAllBrgys)
    }
  };

  const handleDelete = (e, chipToDelete) => {
    setBarangayName((barangayName) => barangayName.filter((chip) => chip !== chipToDelete));
    // source: delete an item in an array using states: https://mui.com/components/chips/#basic-chip
    // source: delete an item in an array using states: https://stackoverflow.com/questions/57341541/removing-object-from-array-using-hooks-usestate
  };

  const selectAll = () => {
    if (checkedAllBrgys) {
      setBarangayName([]);
    } else {
      setBarangayName(getBarangayList(districtName[0]));
    } 
    setCheckedAllBrgys(!checkedAllBrgys)
  }

  useEffect(()=>{ // useEffect use to update "Select All/ Deselect all state"
    if (barangayName.length<7) {
      setCheckedAllBrgys(false)
    }
  }, [barangayName])

  // end: for multiple select 2

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-multiple-chip-label" sx={styles.inputLabel}>Select by Barangay</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple
        value={barangayName}
        onChange={handleChange}
        input={
          <OutlinedInput 
          id="select-multiple-chip" 
          label="Select by District"
          sx={styles.textField} 
          />
        }
        renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
                <Chip key={value} label={value} 
                deleteIcon={
                    <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                    style={{color: 'white'}}
                    />
                }
                onDelete={(e) => handleDelete(e, value)}
                sx={styles.selectChoices}
                />
            ))}
            </Box>
        )}
        MenuProps={MenuProps}
      >
        <MenuItem value={"Select all"} onClick={selectAll}>{checkedAllBrgys?"Deselect all":"Select all"}</MenuItem>
          {listOfBarangays.map((name) => (
            <MenuItem key={name} value={name}> {name} </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
