import * as React from 'react';
import { 
    Grid, 
    Typography, 
    Box, 
    Container, 
    TextField, 
    Paper, 
    CardContent, 
    FormControl, 
    Select, 
    MenuItem, 
    InputAdornment
} from '@mui/material'

import { enGB } from 'date-fns/locale'
import { DatePicker } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

import { themes } from '../../core/theme'
import * as Constants from '../../core'

export function Details({user, imgData}) {
    const [date, setDate] = React.useState()
    
    React.useEffect(()=>{
        if (user.birthday) {
            var birthday = user.birthday
            var birthdayMillisec = Date.parse(birthday) // convert to millisec
            setDate(new Date(birthdayMillisec))
        }
    }, [])

    return (
        <Grid item>
            <Container maxWidth={"sm"} style={{paddingTop:10, paddingBottom:10}} >
                <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                    <Grid container direction={"row"} >
                        <Typography component="h2" variant="h6" style={{flexGrow:1}}>
                            <strong>Profile </strong>
                        </Typography>
                        
                    </Grid>
                    <CardContent>
                        <Grid container direction="row" justifyContent="space-between" spacing={1}>
                            <Grid item xs={12} lg={4}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>First Name</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={user.first_name} size="small" fullWidth id="first_name"  name="first_name"  />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Middle Name</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={user.middle_name} size="small" fullWidth name="middle_name" id="middle_name" />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Last Name</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={user.last_name} size="small" fullWidth name="last_name" id="last_name" />
                            </Grid>
                            
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Birthdays (dd/mm/yyyy)</Typography>
                                <DatePicker date={date} locale={enGB} name="birthday">
                                    {({ inputProps, focused }) => (
                                        <TextField 
                                            style={{backgroundColor:"white", borderRadius:5}}
                                            fullWidth 
                                            size="small" 
                                            name="birthday"
                                            disabled
                                            required
                                            autoComplete='off'
                                            className={'input' + (focused ? ' -focused' : '')}
                                            {...inputProps}
                                        />
                                    )}
                                </DatePicker>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Contact No.</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}}
                                    disabled
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">+63</InputAdornment>,
                                    }}
                                    value={user.phone_number} size="small" required fullWidth name="phone_number" autoComplete="phone_number" id="phone_number"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Email</Typography>
                                <TextField 
                                    style={{backgroundColor:"white", borderRadius:5}} 
                                    disabled 
                                    value={user.email} size="small" fullWidth id="email" name="email"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Address</Typography>
                                <TextField 
                                    style={{backgroundColor:"white", borderRadius:5}} 
                                    disabled 
                                    value={user.street_address} size="small" fullWidth name="street_address" id="street_address" 
                                />
                            </Grid>
                            
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>District</Typography>
                                <FormControl fullWidth size="small" required>
                                    <Select
                                        style={{backgroundColor:"white", borderRadius:5}}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={user.district?user.district:""}
                                        name="district"
                                        disabled
                                    >
                                        <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                                        {Constants.districtNames.map((name)=> {
                                            return (<MenuItem key={name} value={name}>{name}</MenuItem>)
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Barangay</Typography>
                                <TextField 
                                    style={{backgroundColor:"white", borderRadius:5}}
                                    disabled 
                                    value={user.barangay} size="small" fullWidth name="barangay" id="barangay" 
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Zip Code</Typography>
                                <TextField 
                                    style={{backgroundColor:"white", borderRadius:5}}
                                    disabled 
                                    value={user.zip_code} 
                                    size="small" fullWidth name="zip_code" id="zip_code" 
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Country</Typography>
                                <TextField 
                                    style={{backgroundColor:"white", borderRadius:5}}
                                    disabled 
                                    value={user.country} size="small" fullWidth name="country" id="country" 
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Paper>
                <br/>

                <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                    <Typography component="h2" variant="h6">
                        <strong>Organization Information </strong>
                    </Typography>
                    <CardContent>
                        <Grid container direction="row" justifyContent="space-between" spacing={1}>
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Department Office</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}}  disabled value={user.department_office} size="small" fullWidth name="department_office" id="department_office" />
                            </Grid>
                            
                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>Position</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={user.position} size="small" fullWidth name="position" type="position" id="position" />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID Type</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={user.gov_id_type} size="small" fullWidth id="gov_id_type" name="gov_id_type" />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID No.</Typography>
                                <TextField style={{backgroundColor:"white", borderRadius:5}}  disabled value={user.gov_id_no} size="small" fullWidth name="gov_id_no" type="gov_id_no" id="gov_id_no" />
                            </Grid>
                            
                            <Grid item xs={12} lg={6}>
                            <Typography variant="subtitle2" color="text.secondary" component="div" gutterBottom>ID Picture</Typography>
                                {imgData?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: "auto" }}>
                                        <img style={{height:200}} src={imgData}/>
                                    </Box>
                                :null}
                            </Grid>
                        
                        </Grid>
                    </CardContent>
                </Paper>
            </Container>
        </Grid>
    )
}