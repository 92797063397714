import React, { useContext, useEffect, useState } from 'react';
import {  Grid, Typography, Paper, TextField, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { FixedSizeList } from 'react-window';

import axios from 'axios';

import * as Constants from '../../../core'
import { themes } from '../../../core/theme'
import { TourismContext } from '../../../contexts/TourismContext';
import AttractionCard from './AttractionCard';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
    },
    text: {
        fontFamily: 'OpenSans',
        fontSize: '1.5vh',
        color: themes.colors.filterText
    },
}


export default function AttractionList () {

    var dateToday = new Date().toDateString().replace(/ /,', ') 
    const [searchVal, setSearchVal] = useState('')
    const { 
        setToursAll,
        toursShow,  setToursShow,
        toursShow2, setToursShow2,
        setNewTour
    } = useContext(TourismContext);

    // start: for getting Attractions (Tourism)
    const fetchAttractions = async () => { 
    }
    // end: for getting Attractions (Tourism)

    useEffect(()=>{
       fetchAttractions()
      }, [])

    const Summary = () => {
        return (
            <Grid>
                <Typography sx={styles.text}>
                {'Showing: '}{ 
                    toursShow2?
                    toursShow2.count:
                    toursShow?
                    toursShow.count:
                        " . . ."} results as of {dateToday}
                </Typography>
            </Grid>
        )
    }
    
    useEffect(()=>{
        if (toursShow && toursShow.values && searchVal != "") {
            var filtered2 = toursShow.values.filter(item => 
                item.tour_name.toLowerCase().includes(searchVal.toLowerCase())
                );
            setToursShow2({
                count: filtered2.length,
                values: filtered2
            })
        } 
        else {
            setToursShow2(null)
        }
    }, [toursShow, searchVal])

    const searchFilter = (event) => {
        var text = event.target.value
        setSearchVal(text)
    }

    return (
        <Grid container sx={styles.root}>
            <Paper
                square
                elevation={0}
                style={{
                height: "100%",
                width: "100%",
                backgroundColor: themes.colors.sub3,
                maxHeight: "90vh",
                marginBottom: 20
                }}
            >
                <Grid container style={{height: '2%', width: '100%', paddingBottom: '1vh', paddingRight: '1vw', justifyContent: 'space-between'}}>
                    <Grid item style={{flexGrow: 0.8}}><Summary/></Grid>
                    <Tooltip title="Add a tour">
                        <PostAddOutlinedIcon onClick={()=>setNewTour(true)} 
                            sx={{
                                color: themes.colors.filterText,
                                '&:hover': {
                                    color: themes.colors.primary,
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Refresh Attractions">
                        <RefreshIcon onClick={()=>fetchAttractions()} 
                            sx={{
                                color: themes.colors.filterText,
                                '&:hover': {
                                    color: themes.colors.primary,
                                    cursor: 'pointer'
                                }
                            }}
                        />
                    </Tooltip>
                </Grid> 
                <TextField id="outlined-basic" label="Search by Name" variant="outlined" fullWidth style={{marginTop:"2vh"}}
                    onChange = {text => searchFilter(text)}/>

                <Grid container style={{  width: '100%', paddingTop: '1vh', overflow: "clip" }}>
                    
                    {
                        toursShow2 && toursShow2.values?
                            <FixedSizeList
                                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                                itemSize={200}
                                width={"100%"}
                                itemCount={toursShow2.count}
                                itemData={toursShow2.values}
                            >
                                {AttractionCard}
                                
                            </FixedSizeList>
                        :
                        toursShow && toursShow.values?
                            <FixedSizeList
                                height={window.outerHeight>1000?window.outerHeight*0.7: window.innerHeight> 800 ? window.innerHeight*0.775: window.outerHeight*0.675}
                                itemSize={200}
                                width={"100%"}
                                itemCount={toursShow.count}
                                itemData={toursShow.values}
                            >
                                {AttractionCard}
                                
                            </FixedSizeList>
                        :
                        null
                    }

                </Grid>
            </Paper>
        </Grid>
    );
}

