import React from 'react';
import { Grid, Typography, CardContent, TextField, FormControl, Select, MenuItem, Box } from '@mui/material'
import { listOfOffices } from '../../core/data';

export default function OrganizationInformation(props) {
    var isEditing = props.isEditing
    var editedUser = props.editedUser
    var formValChange = props.formValChange
    var imgData = props.imgData

    return (
        <CardContent>
        <Grid container direction="row" justifyContent="space-between" spacing={1}>
            <Grid item xs={12} md={6}>
                <Typography component="div" variant="subtitle2" color="text.secondary" gutterBottom>Department Office</Typography>
                <FormControl fullWidth size="small" required>
                    <Select
                    style={{backgroundColor:"white", borderRadius:5}}
                        labelId="demo-simple-select-label-department_office"
                        id="demo-simple-select-department_office"
                        value={editedUser.department_office.replace(/\n/g, '')}
                        onChange={formValChange}
                        name="department_office"
                        disabled={!isEditing}
                    >
                        <MenuItem value={""} style={{display: 'none'}}></MenuItem>
                        {listOfOffices.map((office)=> {
                            return (<MenuItem key={office} value={office}>{office}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
                <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>Position</Typography>
                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled={!isEditing} value={editedUser.position} onChange={formValChange} size="small" fullWidth name="position" type="position" id="position" />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography component="div" variant="subtitle2" color="text.secondary" gutterBottom>ID Type</Typography>
                <TextField style={{backgroundColor:"white", borderRadius:5}} disabled value={editedUser.gov_id_type} onChange={formValChange} size="small" fullWidth id="gov_id_type" name="gov_id_type" />
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>ID No.</Typography>
                <TextField style={{backgroundColor:"white", borderRadius:5}}  disabled value={editedUser.gov_id_no} onChange={formValChange} size="small" fullWidth name="gov_id_no" type="gov_id_no" id="gov_id_no" />
            </Grid>
            
            <Grid item xs={12} md={6}>
            <Typography component="div" variant="subtitle2" color="text.secondary"  gutterBottom>ID Picture</Typography>
            {imgData?
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: "auto" }}>
                    <img style={{height:200, maxWidth:"100%"}} src={imgData} alt="gov_id_img"/>
                </Box>
                :null}
            </Grid>
        
        </Grid>
    </CardContent>
)}
    