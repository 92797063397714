import React, { useEffect, useState } from 'react';
import { Grid, Typography, Container, Button, Paper } from '@mui/material'
import { isDate18orMoreYearsOld, getBarangayList } from '../../core/functions'
import { regExp, regExp_phoneNum } from '../../core'
import { themes } from '../../core/theme'
import * as Constants from '../../core'

import Scroll from './scroll';
import axios from 'axios';
import ProfilePicture from './ProfilePicture';
import ProfileInformation from './ProfileInformation';
import OrganizationInformation from './OrganizationInformation';
import AccountInformation from './AccountInformation';

const styles = {
    root: {
        height: '100%',
        width: '100%',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: 'white'
    }
}

export default function Profile() {
    const [user, setUser] = useState({})
    const [editedUser, setEditedUser] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        birthday: "",
        phone_number: "",
        email: "",
        street_address: "",
        barangay: "",
        district: "",

        department_office: "",
        position: "",
        gov_id_type: "",
        gov_id_no: "",
        gov_id_img: "",

        username: "",
        password: "",
        isError: {
            phone_number: '',
            email: '',
            username: '',
            password: ''
        }
    })
    
    const [date, setDate] = useState()
    const [dateError, setDateError] = useState(true) // for birthday
    const [isEditing, setIsEditing] = useState(false)
    const [imgData, setImgData] = useState(null);
    const [currentBarangayList, setCurrentBarangayList] = useState([]);
    
    useEffect(() => {
        async function fetchData() {
            var birthday = JSON.parse(localStorage.getItem('user')).birthday
            var birthdayMillisec = Date.parse(birthday) // convert to millisec
            setDate(new Date(birthdayMillisec)) // newDate
            var U2 = JSON.parse(localStorage.getItem('user'))
            U2.isError = {
                phone_number: '',
                email: '',
                username: '',
                password: ''
            }
            setUser(U2)
            setEditedUser(U2) // will update this
            setCurrentBarangayList(getBarangayList(U2.district))

            const formData = {
                "tag_id": JSON.parse(localStorage.getItem('user')).user_id,
                "tag": "command_user_id",
            }
            await axios.post(`${Constants.BASE_URL}/api/image/get`, formData)
            .then((res)=>{
                if (res.data.count>0) {
                    setImgData(res.data.values[0]['img'])
                }
            })
        }
        fetchData()
    
    }, [localStorage.getItem('user')])

    const formValChange = e => {
        
        e.preventDefault();
        const { name, value } = e.target;
        let isError = editedUser.isError;
        switch (name) {
            case "username":
                isError.username =
                    value== ""?"This is a required field.":
                    value.length < 4 ? "At least 4 characters required":"";
                break;
            case "email":
                isError.email = regExp.test(value)
                    ? ""
                    : "Email address is invalid";
                break;
            case "password":
                isError.password =
                    value != user.password ? "Old password incorrect":
                    value.length < 6 ? "At least 6 characters required" : "";
                break;
            case "phone_number":
                isError.phone_number = regExp_phoneNum.test(value)
                    ? "" 
                    :"Input an ten-digit contact number.";
                break;
            case "department_office":
                setEditedUser({
                    [name]: value.value
                })
                break;
            case "district":
                setEditedUser({
                    [name]: value.value
                })
                setCurrentBarangayList(getBarangayList(value))
                
                break;

            default:
                break;
        }
        setEditedUser({
            ...editedUser,
            isError,
            [name]: value
        })
    };

    useEffect(()=>{
        if (currentBarangayList.length>0) {
            if (!currentBarangayList.includes(editedUser.barangay)) { // needed para pagnagbago yung list of district, magnunull yung barangay
                setEditedUser({
                    ...editedUser,
                    barangay:""
                })
            }
        }
    }, [currentBarangayList])

    const handleBirthday = event => {
        const offset = event.getTimezoneOffset()
        var event2 = new Date(event.getTime() - (offset*60*1000)) // nababawasan ng 1 day kapag normal iso string lang
        var dateNew = event2.toISOString('en-GB').split('T')[0] 

        var year = event2.getFullYear()
        var month = event2.getMonth()
        var day = event2.getDate()
        var is18 = isDate18orMoreYearsOld(day, month, year)
        setDateError(isDate18orMoreYearsOld(day, month, year))

        setDate(event) // for display
        setEditedUser({ ...editedUser, birthday: dateNew}) // for BE
    } 
    
    const handleUndo = () => {
        var U2 = JSON.parse(localStorage.getItem('user'))
        U2.isError = {
            phone_number: '',
            email: '',
            username: '',
            password: ''
        }
        setIsEditing(!isEditing)
        setEditedUser(U2)  
        setCurrentBarangayList(getBarangayList(U2.district))
        
        var birthday = JSON.parse(localStorage.getItem('user')).birthday
        var birthdayMillisec = Date.parse(birthday) // convert to millisec
        setDate(new Date(birthdayMillisec)) // newDate
        setDateError(true)
    }
    
    const handleSave = () => {
        setIsEditing(!isEditing)
        var changes = {} // store fields that have been changed
        for (const key in editedUser) { // loop IN object
            if (editedUser[key] !== user[key]) {
                changes[key] = editedUser[key]
            }
        }
        if (Object.keys(changes).length>0) {
            changes.id = user.user_id
            axios.post(`${Constants.BASE_URL}/api/command_user/edit`, changes)
            .then((res)=> {
                alert(res.data.message)
                localStorage.setItem('user', JSON.stringify(res.data.values[0]))
            })
        }
    }
    
    return (
        <Grid container sx={styles.root}>
            <Paper elevation={0}
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "white",
                    maxHeight: "90vh",
                    marginBottom: 10
                }}
            >
                <Scroll showBelow={250}/>
                <Grid container style={{ flex: 4, height: '100%', width: '100%', justifyContent: 'center', paddingTop: '1vh', overflow: "auto" }}>
                    <Grid item>
                        <ProfilePicture user={user}/>
                    </Grid>
                    <Grid item>
                        <Container maxWidth={"sm"} style={{paddingTop:20, paddingBottom:20}} >
                            <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                                <Grid container direction={"row"} >
                                    <Typography component="h2" variant="h6" style={{flexGrow:1}}>
                                        <strong>Profile </strong>
                                    </Typography>
                                    {isEditing?
                                        <div style={{minWidth: 175, display:"flex", justifyContent:"space-around"}}>
                                            <Button variant="contained" onClick={()=>handleSave()}
                                                disabled={
                                                    (Object.values(editedUser).some(function(v){ return v === "" }) || // kung may empty
                                                    Object.values(editedUser.isError).some(function(v){ return v !== "" })  // kung may error
                                                    || date == null || !dateError) || // kung walang gov_id_pic, birthday, kung may error sa date
                                                    (JSON.stringify(user) === JSON.stringify(editedUser)) // kung same lang at walang details na nabago
                                                }  
                                            >Save</Button>
                                            <Button variant="outlined" onClick={()=>handleUndo()}>Undo</Button>
                                        </div>
                                        :
                                        <Button variant="outlined" onClick={()=>setIsEditing(!isEditing)}>Edit Information</Button>
                                    }
                                </Grid>
                                <ProfileInformation 
                                    editedUser={editedUser} 
                                    formValChange={formValChange} 
                                    handleBirthday={handleBirthday}
                                    isEditing={isEditing}
                                    date={date}
                                    dateError={dateError}
                                    currentBarangayList={currentBarangayList}
                                />
                            </Paper>
                            <br/>

                            <Paper style={{padding:20, backgroundColor:themes.colors.sub2}}>
                                <Typography component="h2" variant="h6">
                                    <strong>Organization Information </strong>
                                </Typography>
                               <OrganizationInformation 
                                    editedUser={editedUser} 
                                    formValChange={formValChange} 
                                    isEditing={isEditing} 
                                    imgData={imgData}
                                />
                            </Paper>
                            <br/>

                            <Paper style={{padding:20, backgroundColor:themes.colors.sub2, }}>
                                <Typography component="h2" variant="h6">
                                    <strong>Account Information </strong>
                                </Typography>
                                <AccountInformation user={user}/>
                            </Paper>
                            <br/>
                        </Container>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
