import React, { useState, useContext } from 'react';
import { Grid, Typography, Link, TextField, Button, Paper, Tooltip, IconButton, InputAdornment } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { themes } from '../../core/theme'
import { useHistory } from "react-router-dom";

import ForgotPassword from './ForgotPassword';
import Signup from './Signup';
import axios from 'axios';
import logo from '../../assets/lungsod_logo.png';
import * as Constants from '../../core'
import { WindowContext } from '../../contexts/WindowContext';

const styles = {
    root: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'row',
        backgroundColor: 'white',
        overflow: "clip"
    },
    rootMobile: {
        height: '100vh',
        width: '100vw',
        flex: 1,
        paddingVertical: 16,
        flexDirection: 'column',
        backgroundColor: 'white'
    },
    textField: {
        borderRadius: 2,
        "& .MuiOutlinedInput-input" : { // text style
            color: 'white',
            fontFamily: "Mulish",
            fontSize: '2vh'
        },
        "& .MuiOutlinedInput-root:hover" : { // if you want to color when hovered
            "& > fieldset" : {
                borderColor: "white"
            }
        },
        "& .MuiOutlinedInput-notchedOutline" : { // border of textField
            borderColor: "white",
            borderWidth: 3
        },
        "& .MuiInputLabel-outlined, .MuiInputLabel-root": { // label style
            color: "white",
            fontFamily: "Mulish",
            fontSize: '2vh'
        },
        color: "white",

    },
    login: {
        backgroundColor: 'white',
        color: themes.colors.primary,
        fontFamily: "MulishBold",
        fontSize: '2vh',
        "&:hover" : {
            backgroundColor: "#80A1C1",
            color: 'white'
        },
        textTransform: "none"
    },
    error: {
        color: themes.colors.sub1,
        fontFamily: "MulishBold",
        fontSize: '1.5vh'
    },

    link: {
        fontSize: '2vh',
        color: "white",
        fontFamily : "Fontatica",
        lineHeight: 1
    }    
}

const LoginPage = ({ratio}) => {
    const history = useHistory();
    const [user, setUser] = useState({
        email: "",
        password: "",
        phone_number: "",
        user_type: "",
        username: "",
    });
    const [errorText, setErrorText] = useState('')
    const handleUsername = event => {setUser({ ...user, username: event.target.value})} 
    const handlePassword = event => {setUser({ ...user, password: event.target.value})} 

    const onLogin = (user) => {
        const fetchData = async () => {
            await axios.post(`${Constants.BASE_URL}/api/command_user/login`, {
                "password": user.password,
                "username": user.username
            })
            .then((res)=>{
                if (res.data.message !== "Log-in failed") {
                    localStorage.setItem('user', JSON.stringify(res.data))
                    history.replace('/main')
                } else {
                    setErrorText("Log in failed")
                }
            })
        }
        fetchData()
    }

    const [showPassword, setShowPassword] = useState(false)
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleKeypressEnter = e => {
        if (e.keyCode === 13) {
            onLogin(user);
        }
    };

    return(
        <Grid container sx={ratio<1.1? styles.rootMobile: styles.root}>

            {ratio<1.1?
                <Grid container style={{ flex: 1, backgroundColor: themes.colors.primary, alignItems: 'center', }}>
                    <img src={logo} alt="LUNGSOD" style={{width: undefined, height: '5vh', aspectRatio: 1, maxHeight: '10vh'}}/>
                </Grid>
            :null}

            <Grid container style={{ flex:ratio<1.1?9:7, flexDirection: 'column' }}>

                <Grid container style={{flex: 1}} />
                <Grid container style={{flex: 1}}>
                    <Grid container style={{flex: 1}} />
                        <Grid container style={{flex: ratio<1.1?4:1}}>
                            <Paper 
                                elevation={5} 
                                style={{ 
                                    backgroundColor: themes.colors.primary, 
                                    width: '90%', 
                                    height: '90%',
                                    borderRadius: 10, 
                                    padding: '5%', 
                                    paddingTop: 64
                                }}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{style:{color:"white"}}}
                                        id="username" 
                                        name="username" 
                                        label="Username"
                                        variant="outlined"
                                        fullWidth
                                        size="medium" required
                                        sx={styles.textField}
                                        value={user.username}
                                        onChange={e =>handleUsername(e)}
                                        onKeyDown={handleKeypressEnter}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField  
                                        InputLabelProps={{style:{color:"white"}}}
                                        value={user.password}
                                        onChange={e =>handlePassword(e)} margin="normal" required fullWidth name="password"
                                        onKeyDown={handleKeypressEnter}
                                        type={showPassword?"text":"password"}
                                        sx={styles.textField}
                                        InputProps={{
                                            endAdornment: 
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={()=>setShowPassword(!showPassword)}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? 
                                                        <Tooltip title="Hide Password">
                                                            <VisibilityOff sx={{ color: "white" }}/> 
                                                        </Tooltip>
                                                        : 
                                                        <Tooltip title="Show Password">
                                                            <Visibility sx={{ color: "white" }}/>
                                                        </Tooltip>
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                        id="password" label="Password" autoComplete="current-password"
                                    />
                                </Grid>

                                <Grid container style={{ alignItems: 'center', marginBottom: "4%" }}>
                                    {errorText? <Typography sx={styles.error}>{errorText}</Typography>: null}
                                </Grid>
                                
                                <Grid container direction="row" justifyContent="space-between" spacing={1}>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            onClick={()=>onLogin(user)}
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ ...styles.login }}
                                        >
                                            Log in
                                        </Button>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Signup/>
                                    </Grid>
                                    
                                </Grid>

                                <Grid container style={{ alignItems: 'center', justifyContent: 'center', marginTop: "4%" }}>
                                    <ForgotPassword variant="text"/>
                                </Grid>

                            </Paper>
                        </Grid>
                    <Grid container style={{flex: 1}} />
                </Grid>
                <Grid container style={{flex: 1}} />

            </Grid>
            {ratio<1.1?null:
                <Grid container style={{ flex: ratio>1.1?2.5:7, backgroundColor: themes.colors.primary, alignItems: 'center', justifyContent: 'center' }}>
                    <img src={logo} alt="LUNGSOD" style={{width: undefined, height: '10vh', aspectRatio: 1, maxHeight: '10vh'}}/>
                </Grid>
            }
            
            <Grid 
                container 
                style={{
                    position: 'absolute', 
                    height: 50, width: ratio<1.1?200:100, 
                    bottom: 10, right: 10, 
                    alignItems: 'center', 
                    justifyContent: 'center'
                }}
            >
                <Typography>
                    <Link
                        href={"http://lungsod.tcagp.upd.edu.ph/digitaltwin/"}
                        target="_blank"
                        sx={styles.link}
                    >
                        Digital Twin
                    </Link>
                </Typography>
            </Grid>     
        </Grid>
    )
}

export default function Login() {
    const { ratio } = useContext(WindowContext)
    return(<LoginPage ratio={ratio}/>)
}